import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { Dialog } from "@mui/material";

export const StyledConfirmationDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        color: ${colors.black};
    }

    .MuiDialogContentText-root {
        color: ${colors.black};
    }

    .MuiDialogActions-root {
        justify-content: flex-start;
        padding: 8px 8px 16px 24px;
    }
`;
