import { Table } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import { ProductIdToNameMap } from "src/constants/ProductIds";
import { useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import ProductsModal from "../../modals/ProductsModal/ProductsModal";
import "./Products.scss";
import { FrameCard } from "src/uikit";

const Products: FC = () => {
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountGroup,
    );

    const [productsModalOpen, setProductsModalOpen] = useState<boolean>(false);

    const noDataFoundMessage: string = selectedAccountGroup
        ? "This group is not associated with any products."
        : "Please select a group.";

    return (
        <>
            <div className="group-products-container">
                <FrameCard
                    title="GROUP PRODUCTS"
                    actions={
                        selectedAccountGroup
                            ? [
                                  {
                                      text: "Edit",
                                      onClick: () => setProductsModalOpen(true),
                                  },
                              ]
                            : []
                    }
                >
                    {selectedAccountGroup?.products?.length ? (
                        <StyledTableContainer $maxHeight={"300px"}>
                            <Table>
                                {selectedAccountGroup?.products?.map(
                                    (product: number) => (
                                        <StyledTableRow
                                            key={product}
                                            $isSelected={false}
                                        >
                                            <StyledTableCell>
                                                {ProductIdToNameMap.get(
                                                    product,
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ),
                                )}
                            </Table>
                        </StyledTableContainer>
                    ) : (
                        <StyledNoDataFound>
                            {noDataFoundMessage}
                        </StyledNoDataFound>
                    )}
                </FrameCard>
            </div>
            <ProductsModal
                open={productsModalOpen}
                onClose={() => setProductsModalOpen(false)}
                handleSuccess={() => setSnackbarOpen(true)}
            />
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Assignment updated successfully!"
                severity="success"
            />
        </>
    );
};

export default Products;
