import { Button, CardContent, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import { useAppSelector } from "src/hooks";
import {
    StyledResult,
    StyledResultGroup,
    StyledResultSubheader,
} from "src/products/Agent/Agent.styled";
import { FixMeLater } from "src/types";
import "./AllocationResults.scss";

const AllocationResults: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const { allocateAddressResult } = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.allocateAddressResult || {}
    );

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>(
        "Results are copied to the clipboard successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCopyResults = () => {
        navigator.clipboard
            .writeText(JSON.stringify(allocateAddressResult, null, 2))
            .then(() => {
                console.log(
                    "JSON copied to clipboard: ",
                    allocateAddressResult
                );
                handleSnackbar(
                    "Results are copied to the clipboard successfully!",
                    "success"
                );
            })
            .catch((err) => {
                console.error("Unable to copy JSON to clipboard", err);
                handleSnackbar(
                    "Unable to copy results to the clipboard!",
                    "error"
                );
            });
    };

    return (
        <CardContent>
            <div className="allocation-results-container">
                <Typography variant="h5">Allocation Results</Typography>
                <StyledResultGroup>
                    <StyledResultSubheader>Jurisdiction</StyledResultSubheader>
                    <StyledResult>
                        Jurisdiction Assigned: {allocateAddressResult?.cityName}
                    </StyledResult>
                    <StyledResult>
                        Jurisdiction Code: {allocateAddressResult?.cityCode}
                    </StyledResult>
                </StyledResultGroup>

                <StyledResultGroup>
                    <StyledResultSubheader>Match Quality</StyledResultSubheader>
                    <StyledResult>
                        Match Code:{" "}
                        {allocateAddressResult?.matchQuality?.matchCode}
                    </StyledResult>
                    <StyledResult>
                        Match Description:{" "}
                        {allocateAddressResult?.matchQuality?.matchDescription}
                    </StyledResult>
                    <StyledResult>
                        Match Identifier:{" "}
                        {allocateAddressResult?.matchQuality?.matchIdentifier}
                    </StyledResult>
                </StyledResultGroup>

                <StyledResultGroup>
                    <StyledResultSubheader>
                        Match Information
                    </StyledResultSubheader>
                    <StyledResult>
                        Match Street:{" "}
                        {allocateAddressResult?.matchedAddress?.street}
                    </StyledResult>
                    <StyledResult>
                        Match City: {allocateAddressResult?.matchedAddress.city}
                    </StyledResult>
                    <StyledResult>
                        Match State:{" "}
                        {allocateAddressResult?.matchedAddress?.state}
                    </StyledResult>
                    <StyledResult>
                        Match Zip Code:{" "}
                        {allocateAddressResult?.matchedAddress?.zipCode}
                    </StyledResult>
                    <StyledResult>
                        Census County: {allocateAddressResult?.censusCountyName}
                    </StyledResult>
                </StyledResultGroup>

                <div className="copy-results-container">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCopyResults}
                        disabled={!allocateAddressResult}
                    >
                        Copy Results
                    </Button>
                </div>
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </CardContent>
    );
};

export default AllocationResults;
