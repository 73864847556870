import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Tooltip,
} from "@mui/material";

import React, { useState, DragEvent, ChangeEvent, useEffect } from "react";
import Loader from "../Loader/Loader";
import { StyledDataGrid } from "../DataTable/DataTable.styled";
import { GridToolbarExport } from "@mui/x-data-grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from '@mui/icons-material/Publish';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import "./Migration.scss";
import { StyledMigrationContainer } from "./Migration.styled";
import { Company, DataGridRow, Product } from "src/types";
import { MigrationService } from "src/services/MigrationService";
import {
    ImportStatusResponse,
    MIGRATION_FILE_STATUS,
    MigrationFile,
    MigrationFileKey,
    MigrationFileResponse,
    UPLOAD_FILE_STATUS,
} from "src/types/Migration.types";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";

interface MigrationProps {
    open: boolean;
    onClose: () => void;
    adminView?: boolean;
    products: Product[];
    companies: Company[];
    accountId?: string;
    accountName?: string;
    uploadedFiles?: MigrationFile[];
    refreshUploadedFiles?:(accountId:string)=>void;
}

const Migration: React.FC<MigrationProps> = ({ open, onClose, products, companies, adminView, accountId, accountName, uploadedFiles, refreshUploadedFiles}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<DataGridRow[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [operationType, setOperationType] = useState<string>();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [selectedMigrationFile, setSelectedMigrationFile] =
        useState<MigrationFileKey>();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("info");
    const [loadingRows, setLoadingRows] = useState<Set<number>>(new Set());
    const migrationService = MigrationService.getInstance();
    const importTypes = ["Estimate", "Annual", "FormsPlus"];
    const enabledYears = [2023];

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const calculatedRows: DataGridRow[] = [];
            let index = 0;

            const _uploadedFiles: MigrationFile[] = (uploadedFiles)? uploadedFiles:
                (await migrationService.getImportMetadata()) as unknown as MigrationFile[];
            const migrationFilesMap: Map<string, MigrationFile> =
                _uploadedFiles.reduce((acc, file) => {
                    const key = `${file.companyId}_${file.productId}_${file.taxYear}_${file.estimateAnnual}`.toLowerCase();

                    acc.set(key, file);

                    return acc;
                }, new Map());

                companies.forEach((company) => {
                products
                    .filter(
                        (product) =>
                            product.productName === "FormsPlus" ||
                            product.productName === "Premium"
                    )
                    .forEach((product) => {
                        importTypes.forEach((type) => {
                            if (
                                (product.productName !== "FormsPlus" ||
                                    type === "FormsPlus") &&
                                (product.productName !== "Premium" ||
                                    type !== "FormsPlus") &&
                                enabledYears.indexOf(product.taxYear)!=-1
                            ) {
                                const uploadedFile = migrationFilesMap.get(
                                    `${company.id}_${product.productId}_${product.taxYear}_${type}`.toLowerCase()
                                );
                                const row: DataGridRow = {
                                    id: index,
                                };
                                const uploadDate = uploadedFile? new Date (uploadedFile.uploadDate):undefined;
                                row["product"] = product.productName;
                                row["year"] = product.taxYear;
                                row["company"] = company.name;
                                row["fileName"] = uploadedFile?.fileName;
                                row["uploadDate"] = uploadDate ? uploadDate.toLocaleDateString()+uploadDate.toLocaleTimeString():"";
                                row["importStatus"] = uploadedFile?.importStatus;
                                row["uploadStatusCode"] = uploadedFile?.uploadStatusCode;
                                row["uploadStatusMessage"] = uploadedFile?.uploadStatusMessage;
                                row["successfulReturns"] = uploadedFile?.successfulReturns;
                                row["failedReturns"] = uploadedFile?.failedReturns;
                                row["fein"] = company.federalId;
                                row["naic"] = company.naic;
                                row["type"] = type;
                                row["fileIdentifier"] = uploadedFile?.fileIdentifier;
                                row["companyId"] = company.id;
                                row["productId"] = product.productId;
                                calculatedRows.push(row);
                                index++;
                            }
                        });
                    });
            });

            setRows(calculatedRows);
            setIsLoading(false);
        };
        if(open){
            fetchData();
        }
    }, [open, uploadedFiles]);

    const columns = [
        {
            field: "product",
            headerName: "Product",
            editable: false,
        },
        {
            field: "year",
            headerName: "Tax Year",
            editable: false,
        },
        {
            field: "type",
            headerName: "Type",
            editable: false,
        },
        {
            field: "company",
            headerName: "Company",
            editable: false,
            width: 200
        },
        {
            field: "fileName",
            headerName: "File Name",
            editable: false,
            width: 200
        },
        {
            field: "uploadDate",
            headerName: "Upload Date",
            editable: false,
        },
        {
            field: "naic",
            headerName: "NAIC",
            editable: false,
            renderCell: (params) => {
                return (
                    (params.row.uploadStatusCode === UPLOAD_FILE_STATUS.NAIC_NOT_MATCH ||
                    params.row.uploadStatusCode === UPLOAD_FILE_STATUS.NAIC_FEIN_NOT_MATCH)
                    ?<label style={{color: "red"}}>{params.row.naic}</label>
                    :<label>{params.row.naic}</label>
                )
            }
        },
        {
            field: "fein",
            headerName: "FEIN",
            editable: false,
            renderCell: (params) => {
                return (
                    (params.row.uploadStatusCode === UPLOAD_FILE_STATUS.NAIC_NOT_MATCH ||
                    params.row.uploadStatusCode === UPLOAD_FILE_STATUS.NAIC_FEIN_NOT_MATCH)
                    ?<label style={{color: "red"}}>{params.row.fein}</label>
                    :<label>{params.row.fein}</label>
                )
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            editable: false,
            renderCell: (params) => {
                return (
                    <div className="actions-container">
                        {!params.row.fileIdentifier ? (
                            <>
                                <label
                                    className="actions-container"
                                    htmlFor={`dbxpFileInput-${params.row.id}`}
                                >
                                    <div
                                        className="actions-container"
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={(event) =>
                                            handleFileDrop(event, params.row)
                                        }
                                    >
                                        <CloudUploadIcon
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id={`dbxpFileInput-${params.row.id}`}
                                    className="file-picker-input"
                                    onChange={(event) =>
                                        handleFilePick(event, params.row)
                                    }
                                />
                            </>
                        ) : (
                            <div className="actions-container">
                                <div
                                    onClick={() => {
                                        downloadMigrationFile(
                                            params.row
                                        );
                                    }}
                                >
                                    <CloudDownloadIcon
                                        sx={{ cursor: "pointer" }}
                                    />
                                </div>
                                {(params.row["importStatus"]!==MIGRATION_FILE_STATUS.DONE) || adminView?
                                <div
                                    onClick={() =>
                                        handleDeleteFile(params.row)
                                    }
                                >
                                    <DeleteIcon sx={{ cursor: "pointer" }} />
                                </div>:<></>
                                }
                                {adminView?
                                <div
                                    onClick={() =>
                                        handleImportFile(params.row)
                                    }
                                >
                                    {
                                        <Tooltip title={
                                            <>
                                                { params.row.uploadStatusCode !== UPLOAD_FILE_STATUS.OK?
                                                    <div>{params.row.uploadStatusMessage}</div>: ""
                                                }
                                                { params.row.importStatus !== MIGRATION_FILE_STATUS.NO?
                                                    <div>
                                                        Failed Returns: {params.row.failedReturns}<br/>
                                                        Successful Returns: {params.row.successfulReturns}
                                                    </div>:""
                                                }
                                            </>                                            
                                            }>
                                            {   
                                                (loadingRows.has(params.row.id))?
                                                <CircularProgress color="inherit" size={20} />:
                                                params.row.importStatus === MIGRATION_FILE_STATUS.DONE ? 
                                                <DoneAllIcon sx={{ cursor: "pointer"}}/> :  
                                                params.row.importStatus === MIGRATION_FILE_STATUS.ERROR ?
                                                    <ErrorIcon sx={{ cursor: "pointer"}}/>:
                                                    <PublishIcon sx={{ cursor: "pointer"}} />
                                            }
                                        </Tooltip>
                                    }
                                </div>:<></>
                                }
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    const downloadMigrationFile = async (row) => {
        try{
            const migrationFile: MigrationFileKey = {
                companyId: row.companyId,
                productId: row.productId,
                taxYear: row.year,
                estimateAnnual: row.type,
                fileName: row["fileName"],
                fileIdentifier: row["fileIdentifier"],
                rowId: row.id,
            };
            const file = await migrationService.downloadMigrationFile(migrationFile);

            const blob = new Blob([await file.blob()], {
                type: `application/dbxp`,
            });
            const blobUrl = URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = blobUrl;
            anchor.download = migrationFile.fileName;
            anchor.click();
            URL.revokeObjectURL(blobUrl);
        } catch (error){
            setSnackbarOpen(true);
            setSnackbarMessage(`There was an error trying to download the file`);
            setSnackbarSeverity("error");
        }
    };

    const handleDeleteFile = (
        row: any
    ) => {
        const migrationFile: MigrationFileKey = {
            companyId: row.companyId,
            productId: row.productId,
            taxYear: row.year,
            estimateAnnual: row.type,
            fileName: row["fileName"],
            rowId: row.id,
        };
        setErrorMessage("You are about to delete this file.");
        setShowConfirmation(true);
        setOperationType("DELETE");
        setSelectedMigrationFile(migrationFile);
    }

    const handleImportFile = (
        row: any
    ) => {
        const migrationFile: MigrationFileKey = {
            companyId: row.companyId,
            productId: row.productId,
            taxYear: row.year,
            estimateAnnual: row.type,
            fileName: row["fileName"],
            fileIdentifier: row.fileIdentifier,
            rowId: row.id,
        };
        setErrorMessage("You are about trigger the importing migration process.");
        setShowConfirmation(true);
        setOperationType("IMPORT");
        setSelectedMigrationFile(migrationFile);
    }
    const uploadMigrationFile = async (
        file: File,
        rowId: number,
        companyId: number,
        productId: number,
        taxYear: number,
        estimateAnnual: string
    ) => {
        if (file.size < 3072) {
            setSnackbarMessage(`The file size must be larger than 3KB.`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
    
        const formData = new FormData();
        formData.append("file", file);

        const migrationFile: MigrationFileKey = {
            companyId,
            productId,
            taxYear,
            estimateAnnual,
            fileName: file.name,
            rowId,
            accountId
        };
        const importMetadata = new Blob([JSON.stringify(migrationFile)], {
            type: "application/json",
        });

        formData.append("importMetadata", importMetadata);

        try {
            const response: MigrationFileResponse =
                await migrationService.uploadMigrationFile(formData);
            if (response.first.statusCode !== UPLOAD_FILE_STATUS.OK) {
                if(response.first.statusCode === UPLOAD_FILE_STATUS.PRODUCT_YEAR_NOT_MATCH ||
                    response.first.statusCode === UPLOAD_FILE_STATUS.INVALID_FILE_EXTENSION ||
                    response.first.statusCode === UPLOAD_FILE_STATUS.INVALID_FILE_SIZE
                    ){
                    throw new Error(response.first.errorMessage);
                } else {
                    setOperationType("UPLOAD");
                    setErrorMessage(response.first.errorMessage);
                    setShowConfirmation(true);
                    migrationFile.fileIdentifier = response.second.id;
                    setSelectedMigrationFile(migrationFile);
                }
            } else {
                rows[rowId]["importStatus"] = MIGRATION_FILE_STATUS.NO;
                rows[rowId]["fileName"] = file.name;
                rows[rowId]["fileIdentifier"] = response.second.id;
            }
        } catch (error: any) {
            setSnackbarOpen(true);
            setSnackbarMessage(error.toString());
            setSnackbarSeverity("error");
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";

        const dropArea = event.currentTarget as HTMLDivElement;
        dropArea.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        const dropArea = event.currentTarget as HTMLDivElement;
        dropArea.style.backgroundColor = "transparent";
    };

    const handleFilePick = (event: ChangeEvent<HTMLInputElement>, row) => {
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            uploadMigrationFile(
                file,
                row.id,
                row.companyId,
                row.productId,
                row.year,
                row.type
            );
        }
    };

    const handleFileDrop = (event: DragEvent<HTMLDivElement>, row) => {
        event.preventDefault();

        const droppedFiles = event.dataTransfer.files;
        const dropArea = event.currentTarget as HTMLDivElement;
        dropArea.style.backgroundColor = "transparent";

        if (droppedFiles && droppedFiles.length > 0) {
            const file = droppedFiles[0];
            uploadMigrationFile(
                file,
                row.id,
                row.companyId,
                row.productId,
                row.year,
                row.type
            );
        }
    };

    const handleConfirmationClose = () => {
        setShowConfirmation(false);
        setErrorMessage("");
        if (operationType==="UPLOAD") {
            migrationService.cancelMigrationFile(selectedMigrationFile!!);
        }
        setSelectedMigrationFile(undefined);
    };

    const handleConfirmationConfirm = async () => {
        setShowConfirmation(false);
        setErrorMessage("");
        if (selectedMigrationFile) {
            switch(operationType){
                case "DELETE":
                    try{
                        await migrationService.cancelMigrationFile(selectedMigrationFile);
                        rows[selectedMigrationFile.rowId]["fileName"] = "";
                        rows[selectedMigrationFile.rowId]["fileIdentifier"] = "";
                        rows[selectedMigrationFile.rowId]["uploadDate"] = "";
                        rows[selectedMigrationFile.rowId]["uploadStatusCode"] = 0;
                        rows[selectedMigrationFile.rowId]["uploadStatusMessage"] = "";
            } catch (error){
                        setSnackbarOpen(true);
                        setSnackbarMessage(`There was an error trying to upload the file`);
                        setSnackbarSeverity("error");
                    }
                    break;
                case "UPLOAD":
                    try{
                        await migrationService.confirmMigrationFile(selectedMigrationFile);
                        rows[selectedMigrationFile.rowId]["fileName"] = selectedMigrationFile.fileName;
                        rows[selectedMigrationFile.rowId]["importStatus"] = MIGRATION_FILE_STATUS.NO;
                        rows[selectedMigrationFile.rowId]["fileIdentifier"] = selectedMigrationFile.fileIdentifier;
                        rows[selectedMigrationFile.rowId]["uploadDate"] = (new Date()).toLocaleDateString() + (new Date()).toLocaleTimeString();
                    } catch (error){
                        setSnackbarOpen(true);
                        setSnackbarMessage(`There was an error trying to upload the file`);
                        setSnackbarSeverity("error");
                    }
                    break;
                case "IMPORT": {
                    try{
                        loadingRows.add(selectedMigrationFile.rowId);
                        setLoadingRows(loadingRows);
                        const response: ImportStatusResponse = await migrationService.importMigrationFile(selectedMigrationFile);
                        rows[selectedMigrationFile.rowId]["importStatus"] = response.status;

                        setSnackbarOpen(true);
                        if(response.status === MIGRATION_FILE_STATUS.DONE){
                            setSnackbarMessage("Import finished successfully.");
                            setSnackbarSeverity("success");
                        } else {
                            setSnackbarMessage(`There was an error. ${response.error}`);
                            setSnackbarSeverity("error");
                        }
                        loadingRows.delete(selectedMigrationFile.rowId);
                        setLoadingRows(loadingRows);
                    } catch (error){
                        setSnackbarMessage(`There was an error trying to import the file`);
                        setSnackbarSeverity("error");
                    }
                    break;
                }
            }
            setSelectedMigrationFile(undefined);
            if(refreshUploadedFiles)
                refreshUploadedFiles(accountId!!);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className="migration-import-container">
                <StyledMigrationContainer style={{width: "1150px"}}>
                    <div className="modal-header">
                        {(adminView)?`${accountName}(${accountId})`:"Migration"}
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="grid-container">
                                <StyledDataGrid
                                    style={{flexDirection: "column-reverse"}}
                                    density="compact"
                                    columns={columns}
                                    rows={rows}
                                    slots={{ toolbar: GridToolbarExport }}
                                    isCellEditable={() => false}
                                    hideFooter = {true}
                                />
                            </div>
                        </>
                    )}
                </StyledMigrationContainer>
                <Dialog
                    open={showConfirmation}
                    onClose={handleConfirmationClose}
                >
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {errorMessage} Are you sure you want to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={handleConfirmationClose}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleConfirmationConfirm}
                                color="primary"
                            >
                                Confirm
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <CustomSnackbar
                                open={snackbarOpen}
                                setOpen={setSnackbarOpen}
                                message={snackbarMessage}
                                severity={snackbarSeverity}
                            />
            </div>
        </Modal>
    );
};

export default Migration;
