import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { FC } from "react";
import { FixMeLater } from "src/types";
import "./GroupListItemMenu.scss";

interface GroupListItemMenuProps {
    group: FixMeLater;
    anchorEl: HTMLElement | null;
    handleCloseGroupListItemMenu: () => void;
    onDelete: (group: FixMeLater) => void;
}

const GroupListItemMenu: FC<GroupListItemMenuProps> = ({
    group,
    anchorEl,
    handleCloseGroupListItemMenu,
    onDelete,
}) => {
    const onGroupDelete = () => {
        onDelete(group);
        handleCloseGroupListItemMenu();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseGroupListItemMenu}
            TransitionProps={{
                timeout: 0,
            }}
        >
            <MenuItem onClick={onGroupDelete}>Delete</MenuItem>
        </Menu>
    );
};

export default GroupListItemMenu;
