import styled from "styled-components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SCSS_VARIABLES from "src/styles/component.scss";

export const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: ${SCSS_VARIABLES.spacing8 } !important;
`;

export const StyledCardHeader = styled(CardHeader)`
        color: $primary;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(${SCSS_VARIABLES.primary }, 0.2);
        padding: ${SCSS_VARIABLES.spacing8 };
`;

export const StyledCardContent = styled(CardContent)`
    background-color: ${SCSS_VARIABLES.light };
    overflow-y: scroll;
    scrollbar-color: ${SCSS_VARIABLES.primary} ${SCSS_VARIABLES.light};
`;

export const StyledCardActions = styled(CardActions)`
        margin-top: auto;
        border-top: 1px solid rgba(${SCSS_VARIABLES.primary }, 0.2);
        justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
    color: ${SCSS_VARIABLES.primary };
    font-weight: bold;
`
