export interface MigrationFile {
    id: number,
    fileIdentifier: string,
    fileName: string,
    description: string,
    isConfirmed: boolean,
    estimateAnnual: string,
    accountId: string,
    companyId: number,
    productId: number,
    taxYear: number,
    uploadDate: Date,
    importDate: Date,
    importStatus: MIGRATION_FILE_STATUS,
    uploadStatusCode: UPLOAD_FILE_STATUS,
    uploadStatusMessage: string,
    failedReturns: number,
    successfulReturns: number,
}

export enum UPLOAD_FILE_STATUS {
    OK = 0,
    NAIC_NOT_MATCH = 409006,
    FEIN_NOT_MATCH = 409007,
    NAIC_FEIN_NOT_MATCH = 409005,
    PRODUCT_YEAR_NOT_MATCH = 409004,
    INVALID_FILE_SIZE = 409002,
    INVALID_FILE_EXTENSION = 409001
}

export enum MIGRATION_FILE_STATUS {
    DONE = "Done",
    NO = "NO",
    ERROR = "Error",
}

export interface MigrationFileKey{
    accountId?: string,
    companyId: number,
    productId: number,
    taxYear: number,
    estimateAnnual: string,
    fileName: string,
    fileIdentifier?: string,
    rowId: number,
}

export interface MigrationFileResponse {
    first: {
        statusCode: number,
        errorMessage: string
    },
    second: {
        id: string,
        fileName: string,
        fileExtension: string
    }
}

export interface ImportStatusResponse {
    status: string,
    error?: string,
    numberOfReturns: number;
}