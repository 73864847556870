import styled from "styled-components";

export const StyledModalContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    height: 600px;
    width: 800px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledFlexContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0px 10px;
`;

export const FileUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
`;

export const FileUploadRectangle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    border: 2px dashed black;
    border-radius: 8px;
    cursor: pointer;
`;
