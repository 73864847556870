import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { emailRegex, phoneRegex } from "src/constants/Regex";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { UserService } from "src/services";
import { CreateUserResponse, FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./UserListModal.scss";

interface UserListModalProps {
    open: boolean;
    onClose: () => void;
}

const UserListModal: FC<UserListModalProps> = ({ open, onClose }) => {
    const userService = UserService.getInstance();
    const dispatch = useAppDispatch();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountUsers = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountUsers,
    );

    const initialFormData = {
        firstName: "",
        middleInitial: "",
        lastName: "",
        preferredName: "",
        title: "",
        phone: "",
        email: "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const [validationErrors, setValidationErrors] = useState({
        email: "",
        phone: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "phone" && !phoneRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                phone: "Phone Number must be 10 digits and contain no special characters",
            });
        } else if (name === "email" && !emailRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                email: "Email address is invalid.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const newUser = {
                accountId: selectedAccount?.id,
                email: formData.email,
                personalInfo: {
                    firstName: formData.firstName,
                    middleInitial: formData.middleInitial,
                    lastName: formData.lastName,
                    preferredName: formData.preferredName,
                    title: formData.title,
                    officePhone: formData.phone,
                    otherPhone: "",
                    otherPhoneDesc: "",
                    photoMetadata: {
                        id: "",
                        store: "",
                    },
                    recognitions: [],
                },
                accessControlGroups: [],
            };

            const newUserResponse: CreateUserResponse =
                await userService.createUser(newUser);

            if (newUserResponse) {
                const updatedAccountUsers = [
                    ...selectedAccountUsers,
                    newUserResponse.data,
                ];

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUsers(updatedAccountUsers),
                );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUser(newUserResponse.data),
                );
            }

            onClose();
            setFormData(initialFormData);
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Add User</Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="middleInitial"
                                        label="Middle Initial"
                                        name="middleInitial"
                                        value={formData.middleInitial}
                                        onChange={handleInputChange}
                                        inputProps={{ maxLength: 1 }}
                                    />
                                </StyledFlexContainer>

                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="preferredName"
                                        label="Preferred Name"
                                        name="preferredName"
                                        value={formData.preferredName}
                                        onChange={handleInputChange}
                                    />
                                </StyledFlexContainer>

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.email}
                                    helperText={validationErrors.email}
                                />

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.phone}
                                    helperText={validationErrors.phone}
                                />

                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.email ||
                                            !!validationErrors.phone
                                        }
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="New user created successfully!"
                severity="success"
            />
        </>
    );
};

export default UserListModal;
