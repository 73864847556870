import React, { FC } from "react";
import { useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";
import MenuItem from "../MenuItem/MenuItem";
import "./Menu.scss";

const Menu: FC = () => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );

    return (
        <div className="menu-container">
            <MenuItem>General</MenuItem>
            <MenuItem>Options</MenuItem>
            <MenuItem>Select Fields</MenuItem>
            {paymentRequestExport?.payload?.exportFormat === "Fixes" && (
                <MenuItem>Fixed Width</MenuItem>
            )}
        </div>
    );
};

export default Menu;
