import { CreateReturnPDFPayload, MimeType, QueryParams } from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { getParamsString } from "src/utils";

export class ReturnPDFService {
    private static _instance: ReturnPDFService;
    private readonly BASE_PATH: string = "/api/return-pdf";
    private readonly BASE_PATH_INSTRUCTIONS: string = "/api/return-instructions-pdf";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    private getPathInstructions(path: string): string {
        return `${this.BASE_PATH_INSTRUCTIONS + path}`;
    }

    async getReturnPDF(params: QueryParams): Promise<Blob> {
        const response = await this.api.request(
            this.getPath(`?${getParamsString(params)}`),
            "GET",
            undefined,
            {
                headers: {
                    Accept: MimeType.PDF,
                    "Content-Type": MimeType.PDF,
                },
            },
            true
        );
        return response.blob();
    }

    async getReturnPDFInstruccions(params: QueryParams): Promise<Blob> {
        const response = await this.api.request(
            this.getPathInstructions(`?${getParamsString(params)}`),
            "GET",
            undefined,
            {
                headers: {
                    Accept: MimeType.PDF,
                    "Content-Type": MimeType.PDF,
                },
            },
            true
        );
        return response.blob();
    }

    async generateDownloadableReturnPDF(data: CreateReturnPDFPayload): Promise<Blob> {
        const response = await this.api.post(
            this.getPath(""),
            data,
            undefined,
            true,
        );
        return response.blob();
    }

    public static getInstance(): ReturnPDFService {
        if (!ReturnPDFService._instance) {
            ReturnPDFService._instance = new ReturnPDFService();
        }
        return ReturnPDFService._instance;
    }
}
