import React from "react";

const getImagePath = (scheduleType, isLocked) => {
    const lockedSuffix = isLocked ? "-locked" : "";

    switch (scheduleType) {
        case "RETURN":
            return `regular-form${lockedSuffix}.ico`;
        case "NOT_UPDATED":
        case "PLACE":
            return `grey-circle${lockedSuffix}.ico`;
        case "REPORT":
            return `report${lockedSuffix}.ico`;
        default:
            return `schedule${lockedSuffix}.ico`;
    }
};

export const getReturnNodeIcon = (scheduleType, isLocked) => {
    const imagePath = getImagePath(scheduleType, isLocked);
    return (
        <img
            className="return-icon"
            src={require(`../../assets/images/return-icons/${imagePath}`)}
            alt={`${scheduleType?.toLowerCase()} icon`}
        />
    );
};
