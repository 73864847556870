import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "src/types";

interface SelfState {
    value?: User;
}

const initialState: SelfState = {
    value: undefined,
};

export const SelfSlice = createSlice({
    name: "Self",
    initialState,
    reducers: {
        setSelf: (state, action: PayloadAction<User>) => {
            state.value = action.payload;
        },
        setPendingChange: (state, action: PayloadAction<string>) => {
            const index = state.value?.pendingChanges.findIndex(
                (tabKey : string) => tabKey === action.payload
            );
            if (index === undefined || index < 0) {
                if (!state.value?.pendingChanges) {
                    state.value!!.pendingChanges = [];
                }
                state.value?.pendingChanges.push(action.payload);
            }
        },
        removePendingChange: (state, action: PayloadAction<string>) => {
            const index = state.value?.pendingChanges?.findIndex(
                (tabKey : string) => tabKey === action.payload
            );
            if (index != undefined && index != -1) {
                state.value!!.pendingChanges.splice(index, 1);
            }
        }
    },
});

export const { setSelf, setPendingChange, removePendingChange } =
    SelfSlice.actions;
export default SelfSlice.reducer;
