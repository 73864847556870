// move this file to src/utils/ folder
import dayjs from "dayjs";

export const getModuleId = (product, company, state) => {
    if (product?.productName === "FormsPlus") {
        switch (company?.companySubType) {
            case "LIFE":
            case "HMO":
            case "HEALTH":
                return "1";
            case "PC":
            case "RRG":
            case "TITLE":
                return "2";
            default:
                return "-1";
        }
    }

    if (product?.productName === "Premium") {
        switch (company?.companySubType) {
            case "LIFE":
                return "7";
            case "PC":
                return "6";
            case "HMO":
                return "10";
            case "RRG":
                return "14";
            case "TITLE":
                return "16";
            case "HEALTH":
                return "36";
            default:
                return "-1";
        }
    }

    if (product?.productName === "Municipal") {
        switch (state) {
            case "AL":
                return "29";
            case "FL":
                return "30";
            case "GA":
                return "31";
            case "IL":
                return "32";
            case "KY":
                return "33";
            case "LA":
                return "34";
            case "SC":
                return "35";
            case "NY":
                return "43";
            case "ND":
                return "44";
            case "NJ":
                return "45";
            default:
                return "-1";
        }
    }

    return "-1";
};

export const formatIsoDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getDateOrDefault = (date) => {
    return date !== undefined &&
        date !== null &&
        typeof date === "string" &&
        date !== "null" &&
        date !== ""
        ? formatIsoDate(date)
        : "N/A";
};

export const formatDateTime = (date) => {
    return dayjs(date).format("MM/DD/YYYY hh:mm a");
};

export const getStringValueOrDefault = (value) => {
    return value !== null &&
        value !== "null" &&
        value !== undefined &&
        value !== ""
        ? value
        : "N/A";
};

export const replaceUrlParam = (paramName, paramValue) => {
    var queryParams = new URLSearchParams(window.location.search);

    if (!paramValue) queryParams.delete(paramName);
    else queryParams.set(paramName, paramValue);

    history.replaceState(null, "", "?" + queryParams.toString());
};

export const getFirstPathSegment = () => {
    // Get the path from the URL
    var pathArray = window?.location?.pathname?.split("/");

    // Filter out any empty elements or the base path
    var filteredPathArray = pathArray?.filter(function (element) {
        return element !== "";
    });

    // Return the first path segment
    return filteredPathArray?.length > 0 ? filteredPathArray?.[0] : "";
};
