import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { TRITECH_ROLE } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { UserService } from "src/services";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import AccountCsrModal from "../../modals/AccountCsrModal/AccountCsrModal";
import "./AccountCsr.scss";
import { FrameCard } from "src/uikit";

const AccountCsr: FC = () => {
    const userService = UserService.getInstance();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const usersWithCsrRoleId = useAppSelector(
        (state) => state?.[product?.productName].value?.usersWithCsrRoleId
    );
    const [accountCsrModalOpen, setAccountCsrModalOpen] =
        useState<boolean>(false);

    const selectedAccountCsr = usersWithCsrRoleId?.find(
        (user: FixMeLater) => user.id === selectedAccount?.csrId
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchCsrUsers = async () => {
            setIsLoading(true);
            try {
                const allCsrUsers = await userService.getUsersWithRole(
                    TRITECH_ROLE.CSR
                );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setUsersWithCsrRoleId(allCsrUsers)
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCsrUsers();
    }, []);

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching account CSR: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="account-csr-container">
            <FrameCard
                title="ACCOUNT CSR"
                actions={[
                    {
                        text: "Edit",
                        onClick: () => setAccountCsrModalOpen(true),
                    },
                ]}
            >
                {selectedAccountCsr ? (
                    <>
                        {renderField(
                            "First Name: ",
                            getStringValueOrDefault(
                                selectedAccountCsr?.firstName
                            )
                        )}
                        {renderField(
                            "Last Name: ",
                            getStringValueOrDefault(
                                selectedAccountCsr?.lastName
                            )
                        )}
                        {renderField(
                            "Email: ",
                            getStringValueOrDefault(selectedAccountCsr?.email)
                        )}
                    </>
                ) : (
                    <StyledNoDataFound>
                        This account is not currently associated with any
                        Customer Support Representatives.
                    </StyledNoDataFound>
                )}
                <AccountCsrModal
                    open={accountCsrModalOpen}
                    onClose={() => setAccountCsrModalOpen(false)}
                />
            </FrameCard>
        </div>
    );
};

export default AccountCsr;
