import React, { FC } from "react";
import "./ModalError.scss";

interface ModalErrorProps {
    error: Error | string | null;
}

const ModalError: FC<ModalErrorProps> = ({ error }) => {
    return (
        <div className="modal-error-container">
            {typeof error === "string" ? error : error?.message}
        </div>
    );
};

export default ModalError;
