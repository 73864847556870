interface Item {
    id: string;
    name: string;
}

export const not = (a: readonly Item[], b: readonly Item[]) => {
    return a.filter(
        (value) => b.findIndex((item) => item.id === value.id) === -1
    );
};

export const intersection = (a: readonly Item[], b: readonly Item[]) => {
    return a.filter(
        (value) => b.findIndex((item) => item.id === value.id) !== -1
    );
};
