import React from "react";
import DashboardCard from "../../DashboardCard/DashboardCard";
import { BarChart } from "@mui/x-charts/BarChart";

export const ReturnStatusBarChart: React.FC = () => {
    // Component logic goes here

    const chartSetting = {
        xAxis: [
            {
                label: "# Returns",
            },
        ],
        width: 500,
        height: 400,
    };
    const dataset = [
        {
            amount: 3,
            status: "Efiled",
        },
        {
            amount: 10,
            status: "Mailed",
        },
        {
            amount: 7,
            status: "Approved",
        },
        {
            amount: 6,
            status: "Ready for Review",
        },
        {
            amount: 15,
            status: "Being Prepared",
        },
    ];


    const valueFormatter = (value: number | null) =>
        `${value} Return${value && value > 1 ? "s" : ""}`;

    return (
        <DashboardCard
            title={"Return Status"}
            subheader={"by Amount"}
            actions={[]}
        >
            <BarChart
                margin={{ top: 50, right: 50, bottom: 50, left: 150 }}
                dataset={dataset}
                yAxis={[{ scaleType: "band", dataKey: "status" }]}
                series={[
                    {
                        dataKey: "amount",
                        label: "Amount of returns",
                        valueFormatter,
                    },
                ]}
                layout="horizontal"
                {...chartSetting}
            />
        </DashboardCard>
    );
};
