import React, { FC } from "react";
import "./Instructions.scss";

const Instructions: FC = () => {
    return (
        <div className="instructions-container">
            <div className="instruction">
                You are creating an Excel file. To complete the filing process,
                you will need to upload the file to the NAIC OPTins system along
                with any supporting files.
            </div>
        </div>
    );
};

export default Instructions;
