import React, { FC } from "react";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";
import AllocatorLanding from "./components/AllocatorLanding/AllocatorLanding";

const Allocator: FC = () => {
    return (
        <SnackbarProvider
            maxSnack={5}
            disableWindowBlurListener
            autoHideDuration={10000}
            iconVariant={{
                error: <InfoOutlined sx={{ marginRight: 1 }}/>,
            }}
            style={{ flexWrap: "nowrap"}}
            action={(snackbarId) => (
                <IconButton
                    color="inherit"
                    sx={{ p: 0.5, marginLeft: 1 }}
                    onClick={() => closeSnackbar(snackbarId)}
                >
                    <Close />
                </IconButton>
            )}
        >
            <AllocatorLanding />
        </SnackbarProvider>
    );
};

export default Allocator;
