import { Table } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccessControlGroupService } from "src/services";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import UsersModal from "../../modals/UsersModal/UsersModal";
import "./Users.scss";
import { FrameCard } from "src/uikit";

const Users: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const [usersModalOpen, setUsersModalOpen] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup,
    );
    const selectedAccountGroupUsers = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountGroupUsers,
    );

    const dispatch = useAppDispatch();
    const accessControlGroupService = AccessControlGroupService.getInstance();

    useEffect(() => {
        const fetchSelectedAccountGroupUsers = async () => {
            if (selectedAccountGroup) {
                setIsLoading(true);
                try {
                    const selectedAccountGroupCompanies =
                        await accessControlGroupService.getAccountGroupUsers(
                            selectedAccountGroup?.id,
                        );
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountGroupUsers(
                            selectedAccountGroupCompanies,
                        ),
                    );
                } catch (error) {
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountGroupUsers([]),
                );
            }
        };

        fetchSelectedAccountGroupUsers();
    }, [selectedAccountGroup]);

    const noDataFoundMessage = selectedAccountGroup
        ? "This group is not associated with any users."
        : "Please select a group.";

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching groups: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="group-users-container">
                <FrameCard
                    title="GROUP USERS"
                    actions={
                        selectedAccountGroup
                            ? [
                                  {
                                      text: "Edit",
                                      onClick: () => setUsersModalOpen(true),
                                  },
                              ]
                            : []
                    }
                >
                    {selectedAccountGroupUsers?.length ? (
                        <StyledTableContainer $maxHeight={"280px"}>
                            <Table>
                                {selectedAccountGroupUsers?.map((user) => (
                                    <StyledTableRow
                                        key={user}
                                        $isSelected={false}
                                    >
                                        <StyledTableCell align="left">
                                            {renderField(
                                                "Name",
                                                getStringValueOrDefault(
                                                    user?.firstName,
                                                ),
                                            )}
                                            {renderField(
                                                "Email",
                                                getStringValueOrDefault(
                                                    user?.email,
                                                ),
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </Table>
                        </StyledTableContainer>
                    ) : (
                        <StyledNoDataFound>
                            {noDataFoundMessage}
                        </StyledNoDataFound>
                    )}
                </FrameCard>
            </div>
            <UsersModal
                open={usersModalOpen}
                onClose={() => setUsersModalOpen(false)}
                handleSuccess={() => setSnackbarOpen(true)}
            />
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Assignment updated successfully!"
                severity="success"
            />
        </>
    );
};

export default Users;
