import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { getDateOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import "./UserRoleList.scss";
import { FrameCard } from "src/uikit";

const UserRoleList: FC = () => {
    const accountService = AccountService.getInstance();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountRole = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountRole
    );
    const selectedAccountRoles = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountRoles
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountRoles = async () => {
            setIsLoading(true);
            try {
                const selectedAccountRoles: FixMeLater =
                    await accountService.getAccountRoles(selectedAccount.id);
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountRoles(selectedAccountRoles)
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountRoles();
    }, [selectedAccount]);

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching user roles: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="user-role-list-container">

            <FrameCard title="USER ROLES">
            <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ROLE</StyledTableCell>
                                <StyledTableCell>LAST MODIFIED</StyledTableCell>
                                <StyledTableCell>DATE CREATED</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedAccountRoles?.map((role: FixMeLater) => (
                                <StyledTableRow
                                    $isSelected={
                                        role?.id === selectedAccountRole?.id
                                    }
                                    key={role.id}
                                    onClick={() => {
                                        dispatch(
                                            GlobalStateActions[
                                                product?.productName
                                            ].setSelectedAccountRole(role)
                                        );
                                    }}
                                >
                                    <StyledTableCell>
                                        {role?.name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {getDateOrDefault(role?.lastModified)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {getDateOrDefault(role?.dateCreated)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
            </FrameCard>

            {/* <StyledPaper height={"wrap-content"}>
                
                <StyledHeader>USER ROLES</StyledHeader>
                <StyledTableContainer $maxHeight={"480px"}>
                    
                </StyledTableContainer>
            </StyledPaper> */}
        </div>
    );
};

export default UserRoleList;
