import { BaseAPIService } from "./BaseApiService";
import {
    ReturnDocumentNoteId,
    ReturnDocumentNoteType,
    ReturnDocumentNoteWithReturnIdentifierDTO,
} from "../types";
import { getParamsString, getQueryParams } from "../utils/QueryParams";

export class ReturnDocumentNoteService {
    private static _instance: ReturnDocumentNoteService;
    private readonly BASE_PATH: string = "/api/return-document/notes";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getReturnNotes(
        returnDocumentNoteId: ReturnDocumentNoteId,
    ): Promise<ReturnDocumentNoteType[]> {
        return this.api.get<ReturnDocumentNoteType[]>(
            this.getPath(
                `${getParamsString(
                    getQueryParams({
                        createdReturnId: returnDocumentNoteId.createdReturnId,
                        fieldType: returnDocumentNoteId.fieldType,
                        fieldId: returnDocumentNoteId.fieldId,
                    }),
                )}`,
            ),
        );
    }

    createReturnNote(
        returnDocumentNoteWithReturnIdentifierDTO: ReturnDocumentNoteWithReturnIdentifierDTO,
    ): Promise<ReturnDocumentNoteType> {
        return this.api.post<ReturnDocumentNoteType>(
            this.getPath(""),
            returnDocumentNoteWithReturnIdentifierDTO,
        );
    }

    createReturnFieldNote(
        returnDocumentNoteWithReturnIdentifierDTO: ReturnDocumentNoteWithReturnIdentifierDTO,
    ): Promise<ReturnDocumentNoteType> {
        return this.api.post<ReturnDocumentNoteType>(
            this.getPath(""),
            returnDocumentNoteWithReturnIdentifierDTO,
        );
    }

    editReturnFieldNote(
        returnDocumentNoteType: ReturnDocumentNoteType,
    ): Promise<ReturnDocumentNoteType> {
        return this.api.put<ReturnDocumentNoteType>(
            this.getPath(`/${returnDocumentNoteType.id}`),
            returnDocumentNoteType,
        );
    }

    deleteReturnFieldNote(
        returnDocumentNoteType: ReturnDocumentNoteType,
    ): Promise<void> {
        return this.api.delete(this.getPath(`/${returnDocumentNoteType.id}`));
    }

    public static getInstance(): ReturnDocumentNoteService {
        if (!ReturnDocumentNoteService._instance) {
            ReturnDocumentNoteService._instance =
                new ReturnDocumentNoteService();
        }
        return ReturnDocumentNoteService._instance;
    }
}
