import React from "react";
import DashboardCard from "../../DashboardCard/DashboardCard";
import { BarChart } from "@mui/x-charts/BarChart";
import { DatasetType } from "@mui/x-charts/models/seriesType/config";

export const AgentUsageReport: React.FC = () => {
    const dataset: DatasetType = [
        {
            label: "Total Clicks Purchased",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Total Used Clicks",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Good Clicks",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Bad Clicks",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Q1",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Q2",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Q3",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        },
        {
            label: "Q4",
            clicks2023: Math.floor(Math.random() * 10000),
            clicks2024: Math.floor(Math.random() * 10000),
        }
    ];



    return (
        <DashboardCard
            title={"Agent Usage Report"}
            subheader={"Clicks - Quarterly "}
            actions={[
                {
                    text: "View All",
                    cb: () => console.log("View All clicked"),
                },
            ]}
        >
            <BarChart
                margin={{ top: 50, right: 50, bottom: 150, left: 50 }}
                xAxis={[{
                    scaleType: "band",
                    dataKey: "label",
                }]}
                series={[
                    {
                        dataKey: "clicks2023",
                        label: "2023",
                        color: "blue",
                    },
                    {
                        dataKey: "clicks2024",
                        label: "2024",
                        color: "purple",
                    }
                ]}
                height={300}
                dataset={dataset}
            />
        </DashboardCard>
    );
};
