import styled from "styled-components";
import { Chip } from "@mui/material";
import colors from "src/styles/colors.scss";

export const StyledChipTab = styled(Chip)`
    color: ${colors.primaryColor};
    background-color: ${colors.allocatorComponentBackgroundLightColor};
    margin: 0 2px;

    &: hover {
        background-color: ${colors.allocatorComponentBackgroundColor};
    }
`;

export const SelectedStyledChipTab = styled(Chip)`
    color: ${colors.white};
    background-color: ${colors.primaryColor};
    margin: 0 2px;
`;
