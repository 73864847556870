import styled from "styled-components";
import colors from "src/styles/colors.scss";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import { Autocomplete } from "@mui/material";

export const StyledFormControl = styled(FormControl)<FormControlProps>`
    width: 100%;
    align-items: center;
    .MuiSelect-select {
        border: 1px solid ${colors.white};
        color: ${colors.textColor};
        width: 140px;
    }

    .MuiInputLabel-root {
        color: ${colors.textColor};
        background-color: ${colors.primaryColor};

        &.Mui-focused {
            color: ${colors.textColor};
        }
    }

    .MuiSelect-icon {
        color: ${colors.iconColor};
    }
    .MuiFormLabel-root {
        top: -4px;
    }
    .MuiInputLabel-shrink {
        top: 0;
    }
`;

export const StyledAutoComplete = styled(Autocomplete)`
    width: 100%;
    .MuiInputBase-root {
        color: ${colors.textColor};

        .MuiSvgIcon-root {
            color: ${colors.iconColor};
        }
    }

    fieldset {
        border-color: ${colors.white} !important;
        &:hover {
            border: 2px solid ${colors.white} !important;
        }
    }
    .MuiOutlinedInput-root {
        padding: 1px;
    }
`;
