import styled from "styled-components";
import SCSS_VARIABLES from "src/styles/component.scss";
import { Checkbox, ListItem, ListItemText, TextField } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

type StyledListItemProps = {
    $isSelected: boolean;
};

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
    && {
        background-color: ${(props) =>
            props.$isSelected ? "rgba(224, 224, 224, 0.3)" : "transparent"};
    }
`;

export const StyledListItemText = styled(ListItemText)`
    && {
        cursor: pointer;
        display: flex;
        flex-direction: column;
    }

    .MuiListItemText-secondary {
        color: rgba(224, 224, 224, 0.8);
        text-align: left;
    }
`;

export const StyledFolderIcon = styled(FolderIcon)`
    color: ${SCSS_VARIABLES.$light};
`;

export const StyledTextField = styled(TextField)`
    && {
        margin: 8px 0px;

        & label {
            color: ${SCSS_VARIABLES.light};
        }

        & input {
            color: ${SCSS_VARIABLES.light};
        }

        fieldset {
            border-color: ${SCSS_VARIABLES.light};
        }
        .MuiOutlinedInput-input {
            padding: 8px;
        }
        .MuiInputLabel-root {
            top: -6px;
        }
        .MuiInputLabel-shrink {
            top: 0px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: ${SCSS_VARIABLES.light};
        }
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    && {
        color: ${SCSS_VARIABLES.light};
    }
`;
