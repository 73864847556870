import styled from "styled-components";
import { Paper } from "@mui/material";

interface StyledPaperProps {
    isSelected: boolean;
}

export const StyledPaper = styled(Paper)<StyledPaperProps>`
    background-color: ${(props) => (props.isSelected ? "#e0e0e0" : "#fff")};
    padding: 10px;
    text-align: center;

    ${(props) =>
        !props.isSelected &&
        `
    &:hover {
        cursor: pointer;
        background-color: #e0e0e0;
    }
    `}
`;
