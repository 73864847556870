import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CompanyIcon from "src/assets/images/Company.png";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { Company, Product, UserOptions } from "src/types";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import "./CompanySelector.scss";
import { useAppSelector } from "src/hooks";

interface CompanySelectorProps {
    companyChangeFn: (changes: { selectedCompany: Company }) => void;
}

const CompanySelector: React.FC<CompanySelectorProps> = ({
    companyChangeFn,
}) => {
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    const userOptions: UserOptions | undefined = useAppSelector((state) => state.UserOptions.value);

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    let lastCompanyIdFromPreferences;

    if(product) {
        lastCompanyIdFromPreferences = userOptions.productPreferences?.[product?.productName]?.filters?.company;
    }

    if (!product) return null;

    const selectedCompany: Company = useSelector(
        (state) => state?.[product?.productName]?.value?.company
    );
    const companies = useAppSelector((state) => state?.Companies?.value);

    const companyOptions = companies?.map((company: Company) => ({
        value: company,
        label: company.coabbr ?? company.name,
    }));

    // Check if company param exists in url
    useEffect(() => {
        const companyParam = searchParams.get("companyId") || lastCompanyIdFromPreferences?.toString(); 

        if (companyParam) {
            let selectedCompany = companies?.find(
                (company: Company) => company.id === parseInt(companyParam)
            );
            // If company exists, set it
            if (selectedCompany)
                dispatch(
                    GlobalStateActions[product?.productName].setCompany(
                        selectedCompany
                    )
                );
        }
    }, [companies, userOptions]);

    const handleChange = (selectedCompany: Company) => {
        companyChangeFn({
            selectedCompany,
        });
        dispatch(
            GlobalStateActions[product?.productName].setCompany(selectedCompany)
        );
    };

    return (
        <div className="tts-company-selector">
            <SimpleAutoComplete
                icon={<img src={CompanyIcon} width={25} alt="company-icon" />}
                size="small"
                label="Company"
                data={companyOptions}
                onSelect={handleChange}
                selected={selectedCompany || ""}
                data-testid="tts-company-selector"
            />
        </div>
    );
};

export default CompanySelector;
