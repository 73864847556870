import {
    Checkbox,
    FormControlLabel,
    MenuItem,
    Radio,
    Select,
    TextField,
} from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./Options.scss";
import {
    actualToDelimeterMap,
    checkBoxes,
    delimiterNameToActualMap,
} from "./Options.util";

const Options: FC = () => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );

    const dispatch = useAppDispatch();

    const handleDelimiterChange = (delimiter: string) => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    delimiter: delimiter,
                    exportFormat: "Delimited",
                },
            })
        );
    };

    const handleDelimiterOptionChange = () => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    exportFormat: "Delimited",
                },
            })
        );
    };

    const handleFixedWidthOptionChange = () => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    exportFormat: "Fixes",
                },
            })
        );
    };

    const handleMinPaymentAmountChange = (minPaymentAmount: number) => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    minPaymentAmount: minPaymentAmount,
                },
            })
        );
    };

    const handleCheckboxChange = (name: string) => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    [name]: !paymentRequestExport?.payload?.[name],
                },
            })
        );
    };

    return (
        <div className="options-container">
            <div className="header">Export Format</div>
            <hr />

            <div>
                <FormControlLabel
                    control={
                        <Radio
                            checked={
                                paymentRequestExport?.payload?.exportFormat ===
                                "Delimited"
                            }
                            onChange={handleDelimiterOptionChange}
                        />
                    }
                    label="Delimiter"
                />

                <Select
                    value={
                        actualToDelimeterMap.get(
                            paymentRequestExport?.payload?.delimiter
                        ) || ","
                    }
                    onChange={(e) =>
                        handleDelimiterChange(
                            delimiterNameToActualMap.get(e.target.value) || ","
                        )
                    }
                >
                    {Array.from(delimiterNameToActualMap.keys()).map((key) => (
                        <MenuItem key={key} value={key}>
                            {key}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <FormControlLabel
                control={
                    <Radio
                        checked={
                            paymentRequestExport?.payload?.exportFormat ===
                            "Fixes"
                        }
                        onChange={handleFixedWidthOptionChange}
                    />
                }
                label="Fixed Width"
            />

            <div className="header">Other Options</div>
            <hr />

            <div className="amount-container">
                Minimum dollar limit on payment amounts: $0.00
                <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    onChange={(e) =>
                        handleMinPaymentAmountChange(parseInt(e.target.value))
                    }
                    type="number"
                    value={paymentRequestExport?.payload?.minPaymentAmount}
                />
            </div>

            <div>
                {Array.from(checkBoxes).map((option) => (
                    <FormControlLabel
                        key={option[0]}
                        control={
                            <Checkbox
                                checked={
                                    paymentRequestExport?.payload?.[option[1]]
                                }
                                onChange={() => handleCheckboxChange(option[1])}
                                name={option[0]}
                            />
                        }
                        label={option[0]}
                    />
                ))}
            </div>
        </div>
    );
};

export default Options;
