import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import colors from "src/styles/colors.scss";
import { StyledDataGrid } from "src/products/Allocator/Allocator.styled";

export const StyledFieldMappingGrid = styled(StyledDataGrid)`
    .MuiDataGrid-footerContainer {
        display: none;
    }

    .MuiDataGrid-columnHeader {
        .MuiDataGrid-columnSeparator {
            display: none;
        }
    }

    .MuiDataGrid-row:hover {
        background-color: ${colors.allocatorGridRowBackgroundColor};
    }
`;

export const StyledGridButton = styled(LoadingButton)`
    color: ${colors.primaryColor};
    background-color: transparent;
    font-size: 13px;
    font-weight: 600;
    padding: 2px 8px;

    &: hover {
        background-color: transparent;
    }

    .MuiButton-startIcon {
        margin-right: 6px;
    }

    .MuiSvgIcon-root {
        font-size: 16px;
    }
`;