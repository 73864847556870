import {
    Button,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { FixMeLater } from "src/types";

export const StyledPaper:FixMeLater = styled(Paper)`
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${(props:FixMeLater) => (props.height ? props.height : "200px")};
    margin: 8px;
    max-height: 648px;
    min-height: 200px;
    overflow: auto;
    padding: 16px;
`;

export const StyledHeader = styled.div`
    color: ${colors.accountAdminStyledHeaderTextColor};
    display: flex;
    justify-content: center;
    margin: 8px;
`;

export const StyledLabel = styled.span`
    color: ${colors.accountAdminStyledLabelTextColor};
    font-weight: bold;
`;

export const StyledOpenModalButton = styled.div`
    align-items: center;
    display: flex;
    justify-content: right;
    margin: 16px 0px 0px 0px;
    width: 100%;
`;

export const StyledOpenModalButtonText = styled(Button)`
    color: ${colors.accountAdminStyledOpenModalButtonTextColor};
    cursor: pointer;
    font-weight: bold;
`;

export const StyledModalContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    max-height: 600px;
    max-width: 600px;
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledDataGrid = styled(DataGridPremium)`
    margin: 8px;
    max-height: 400px;
    min-height: 240px;
    padding: 16px;
`;

export const StyledFlexContainer:FixMeLater = styled.div`
    align-items: center;
    display: flex;
    justify-content: ${(props:FixMeLater) => (props.justify ? props.justify : "normal")};
    gap: 16px;
    width: 100%;
`;

export const StyledFormContainer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledForm = styled.form`
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

type StyledTableContainerProps = {
    $maxHeight: string;
};

export const StyledTableContainer:FixMeLater = styled(TableContainer)<StyledTableContainerProps>`
    max-height: ${(props) => (props.$maxHeight ? props.$maxHeight : "600px")};
`;

export const StyledTable = styled(Table)`
    max-height: 300px;
    overflow-y: auto;
`;

interface StyledTableRowProps {
    $isSelected: boolean;
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
    background-color: ${(props) => (props.$isSelected ? "#f5f5f5" : "#fff")};
    cursor: pointer;
`;

export const StyledTableCell = styled(TableCell)`
    text-align: ${(props) => (props.align ? "left" : "center")};
`;

export const StyledNoDataFound = styled.div`
    margin: 10px 0px;
    text-align: center;
    width: 100%;
`;
