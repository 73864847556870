import React, { FC, useMemo, useEffect, useState } from "react";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BusinessIcon from "@mui/icons-material/Business";

import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater, SubAccountWithCompanies } from "src/types";
import { StyledNoDataFound } from "../../../../AccountAdmin.styled";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import SubAccountModal from "../../modals/SubAccountModal/SubAccountModal";
import { FrameCard } from "src/uikit";

import "./SubAccounts.scss";

const SubAccounts: FC = () => {
    const accountService = AccountService.getInstance();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const dispatch = useAppDispatch();
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const selectedAccountSubWithCompanies: SubAccountWithCompanies[] =
        useAppSelector(
            (state) =>
                state?.[product?.productName]?.value
                    ?.selectedAccountSubWithCompanies
        );

    const [subAccountsModalOpen, setSubAccountsModalOpen] =
        useState<boolean>(false);

    useEffect(() => {
        const fetchSelectedAccountSub = async () => {
            if (selectedAccount) {
                setIsLoading(true);
                try {
                    const selectedAccountSubaccountWithCompanies: SubAccountWithCompanies[] =
                        await accountService.getAccountSubAccountsWithCompanies(
                            selectedAccount?.id
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountSubWithCompanies(
                            selectedAccountSubaccountWithCompanies
                        )
                    );
                } catch (error) {
                    console.error(error);
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSelectedAccountSub();
    }, [dispatch, selectedAccount, product]);

    const SubAccountsTreeView = useMemo(
        () => (
            <SimpleTreeView
                aria-label="sub-accounts navigator"
                slots={{
                    expandIcon: ExpandMoreIcon,
                    collapseIcon: ChevronRightIcon,
                }}
                className="tree"
            >
                {selectedAccountSubWithCompanies &&
                    selectedAccountSubWithCompanies.map((subAccount, index) => {
                        const nodeId = `subaccount-${index}`;
                        return (
                            <TreeItem
                                className="tree__item"
                                itemId={nodeId}
                                label={subAccount.name}
                                key={subAccount.id}
                            >
                                {subAccount.companies &&
                                    subAccount.companies.map(
                                        (company, companyIndex) => (
                                            <TreeItem
                                                itemId={`${nodeId}-${companyIndex}`}
                                                label={
                                                    <span className="label">
                                                        <BusinessIcon className="label__icon" />
                                                        {company.name}
                                                    </span>
                                                }
                                                key={company.id}
                                            />
                                        )
                                    )}
                            </TreeItem>
                        );
                    })}
            </SimpleTreeView>
        ),
        [selectedAccountSubWithCompanies]
    );

    const noDataFoundMessage = selectedAccount
        ? "There are no sub-accounts associated with this account."
        : "Please select a group.";

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching sub accounts: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="sub-accounts-container">
            <FrameCard
                title="SUB-ACCOUNTS"
                actions={[
                    {
                        text: "Add Sub-Account",
                        onClick: () => setSubAccountsModalOpen(true),
                    },
                ]}
            >
                {selectedAccountSubWithCompanies?.length === 0 ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <>{SubAccountsTreeView}</>
                )}
            </FrameCard>
            <SubAccountModal
                open={subAccountsModalOpen}
                onClose={() => setSubAccountsModalOpen(false)}
            />
        </div>
    );
};

export default SubAccounts;
