import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { Paper } from "@mui/material";

export const StyledPaper = styled(Paper)`
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
    color: ${colors.black};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    max-height: 648px;
    min-height: 200px;
    overflow: auto;
    padding: 16px;
    text-align: center;
`;
