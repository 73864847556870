import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { FixMeLater } from "src/types";
import { StyledTableCell } from "../../../../../../AccountAdmin.styled";
import EditProductModal from "../../../../modals/EditProductModal/EditProductModal";

interface ProductsTableRowProps {
    data: FixMeLater[];
}

const ProductsTableRow: React.FC<ProductsTableRowProps> = ({ data }) => {
    const [editProductModalOpen, setEditProductModalOpen] = useState(false);
    // Transform the data into a map for faster access
    const productsByYear = new Map(data?.map((item) => [item.year, item]));

    const years = [...productsByYear.keys()].sort((a, b) => b - a);
    const [selectedYear, setSelectedYear] = useState(years[0]);

    const handleYearChange = (event: FixMeLater) => {
        setSelectedYear(event.target.value as number);
    };

    const selectedProduct = productsByYear.get(selectedYear);

    useEffect(() => {
        if (!selectedProduct) {
            setSelectedYear(years[0]);
        }
    }, [data]);

    return (
        <>
            <TableRow>
                <StyledTableCell>
                    {selectedProduct?.productName}
                </StyledTableCell>
                <StyledTableCell>
                    <Select value={selectedYear} onChange={handleYearChange}>
                        {years?.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell>
                    {selectedProduct?.jurisdictionDTOs?.length}
                </StyledTableCell>
                <StyledTableCell>
                    <EditIcon
                        onClick={() => setEditProductModalOpen(true)}
                        style={{ color: "black", cursor: "pointer" }}
                    />
                </StyledTableCell>
            </TableRow>
            <EditProductModal
                open={editProductModalOpen}
                onClose={() => setEditProductModalOpen(false)}
                productToEdit={selectedProduct}
            />
        </>
    );
};

export default ProductsTableRow;
