import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React, { FC } from "react";
import {
    StyledContainedButton,
    StyledTextButton,
} from "src/products/Allocator/Allocator.styled";
import { StyledConfirmationDialog } from "./ConfirmationDialog.styled";
import { ConfirmationDialogProps } from "src/types";

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    open,
    title,
    content,
    actionPrimary,
    actionSecondary,
    handleClose,
}) => {
    return (
        <StyledConfirmationDialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{whiteSpace: "pre-wrap"}}>
                <DialogContentText>
                    {Array.isArray(content) ? content?.join("\n") : content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledContainedButton
                    variant="contained"
                    onClick={actionPrimary?.action}
                >
                    {actionPrimary?.text}
                </StyledContainedButton>
                <StyledTextButton onClick={actionSecondary?.action}>
                    {actionSecondary?.text}
                </StyledTextButton>
            </DialogActions>
        </StyledConfirmationDialog>
    );
};

export default ConfirmationDialog;
