import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./GroupListModal.scss";

interface GroupListModalProps {
    open: boolean;
    onClose: () => void;
}

const GroupListModal: FC<GroupListModalProps> = ({ open, onClose }) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountGroups = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountGroups
    );

    const dispatch = useAppDispatch();

    const initialFormData: FixMeLater = {
        // this should be type CreateAccessControlGroupPayload
        name: "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const newGroup = await accountService.createAccountGroup(
                selectedAccount?.id,
                {
                    ...formData,
                }
            );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroup(newGroup)
            );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroups([...selectedAccountGroups, newGroup])
            );

            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Add Group</Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="User Group Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />

                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="User Group created successfully!"
                severity="success"
            />
        </>
    );
};

export default GroupListModal;
