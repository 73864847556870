import { ListItemIcon } from "@mui/material";
import List from "@mui/material/List";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import colors from "src/styles/colors.scss";
import { Account, FixMeLater } from "src/types";
import { StyledFlexContainer } from "../../AccountAdmin.styled";
import "./AccountList.scss";
import {
    StyledCheckbox,
    StyledFolderIcon,
    StyledListItem,
    StyledListItemText,
    StyledTextField,
} from "./AccountList.styled";
import AccountListItemMenu from "./components/AccountListItemMenu/AccountListItemMenu";

const AccountList: FC = () => {
    const accountService = AccountService.getInstance();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarStatus, setSnackbarStatus] = useState<"success" | "error">(
        "success"
    );
    const [searchInput, setSearchInput] = useState<string>("");
    const [activeFilter, setActiveFilter] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts || []
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount || null
    );

    const dispatch = useAppDispatch();

    const [accountListItemMenuAnchorEl, setAccountListItemMenuAnchorEl] =
        useState<null | HTMLElement>(null);
    const [rightClickedAccount, setRightClickedAccount] =
        useState<Account | null>(null);

    const openAccountListItemMenu = (
        event: React.MouseEvent<HTMLDivElement>,
        account: Account
    ) => {
        event.stopPropagation();
        setAccountListItemMenuAnchorEl(event.currentTarget);
        setRightClickedAccount(account);
    };

    const handleCloseAccountListItemMenu = () => {
        setAccountListItemMenuAnchorEl(null);
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            setIsLoading(true);

            try {
                const accounts: Account[] =
                    await accountService.getAllAccounts(); // add Account[] type when migrating to TS

                dispatch(
                    GlobalStateActions[product?.productName].setAccounts(
                        accounts
                    )
                );
                if (!selectedAccount) {
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccount(accounts[0])
                    );
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error as Error | null);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAccounts();
    }, []);

    const updateAccountActive = async (account: Account, activate: boolean) => {
        try {
            const updatedAccount = { ...account, active: activate };
            await accountService.updateAccount(updatedAccount);
            const updatedAccounts = accounts?.map((a) =>
                a.accountNumber === account?.accountNumber ? updatedAccount : a
            );
            dispatch(
                GlobalStateActions[product?.productName].setAccounts(
                    updatedAccounts
                )
            );
            setSnackbarMessage(
                `Account "${account?.name}" successfully ${
                    activate ? "activated" : "deactivated"
                }.`
            );
            setSnackbarStatus("success");
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage(
                `Error ${activate ? "activating" : "deactivating"} account "${
                    account?.name
                }".`
            );
            setSnackbarStatus("error");
            setSnackbarOpen(true);
            console.error("Error in updateAccountActive:", error);
        }
    };

    const filteredAccounts = accounts?.filter((account) => {
        if (activeFilter && !account?.active) return false;
        return (
            account?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            account?.accountNumber
                .toLowerCase()
                .includes(searchInput.toLowerCase())
        );
    });

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching accounts: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="account-list-container">
            <div className="account-list-search-bar-container">
                <StyledTextField
                    label="Search Accounts"
                    variant="outlined"
                    fullWidth
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            </div>

            <div className="account-list-active-filter-container">
                <StyledFlexContainer>
                    <StyledCheckbox
                        checked={activeFilter}
                        onChange={(e) => setActiveFilter(e.target.checked)}
                    />
                    <label>Show Active Only</label>
                </StyledFlexContainer>
            </div>

            <div className="scrollable-list-container">
                <List>
                    {filteredAccounts?.map((account, index) => (
                        <StyledListItem
                            key={index}
                            onContextMenu={(e: FixMeLater) => {
                                e.preventDefault();
                                openAccountListItemMenu(e, account);
                            }}
                            $isSelected={
                                selectedAccount?.accountNumber ===
                                account?.accountNumber
                            }
                        >
                            <ListItemIcon>
                                <StyledFolderIcon
                                    style={{
                                        color: account?.active
                                            ? colors.accountAdminActiveAccountIconColor
                                            : colors.accountAdminInactiveAccountIconColor,
                                    }}
                                />
                            </ListItemIcon>
                            <StyledListItemText
                                onClick={() => {
                                    dispatch(
                                        GlobalStateActions[
                                            product?.productName
                                        ].setSelectedAccount(account)
                                    );
                                    setSearchInput("");
                                }}
                                primary={account?.name}
                                secondary={account?.accountNumber}
                            />
                        </StyledListItem>
                    ))}
                </List>
            </div>
            <AccountListItemMenu
                account={rightClickedAccount}
                anchorEl={accountListItemMenuAnchorEl}
                handleCloseAccountListItemMenu={handleCloseAccountListItemMenu}
                updateAccountActive={updateAccountActive}
            />
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarStatus}
            />
        </div>
    );
};

export default AccountList;
