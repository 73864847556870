import React, { FC } from "react";
import colors from "src/styles/colors.scss";
import "./Toolbar.scss";
import { Tooltip } from "@mui/material";
import { FixMeLater } from "src/types";

interface ToolbarProps {
    items: FixMeLater[];
}

const Toolbar: FC<ToolbarProps> = ({ items }) => {
    // Filter enabled icons
    const enabledIcons = items.filter((data) => data.isActive);

    return (
        <div className="toolbar-container" data-testid="toolbar-container">
            {enabledIcons.map((data, index) => (
                <Tooltip key={index} title={data.title} placement="left">
                    <div
                        className="toolbar-icon-container"
                        onClick={data.isEnabled ? data.onClick : () => {}}
                        data-testid={data.title}
                        data-isenabled={data.isEnabled}
                    >
                        {typeof data.icon === "string" ? ( // Check if icon is a string (image URL)
                            <img
                                src={data.icon}
                                alt={data.title}
                                style={{
                                    filter: data.isEnabled
                                        ? data.isValid
                                            ? "none"
                                            : "hue-rotate(90deg) saturate(3)"
                                        : "opacity(0.4)",
                                    height: "24px",
                                    width: "24px",
                                }}
                            />
                        ) : (
                            React.createElement(data.icon, {
                                style: {
                                    color: data.isEnabled
                                        ? data.isValid
                                            ? colors.iconColor
                                            : "red"
                                        : "rgba(255, 255, 255, 0.4)",
                                    height: "24px",
                                    width: "24px",
                                },
                            })
                        )}
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default Toolbar;
