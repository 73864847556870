import React, { FC, useCallback, useEffect } from "react";
import ContainerWithResizableSideBar from "../../components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import ReturnTreeViewer from "../../components/ReturnTreeViewer/ReturnTreeViewer";
import "./FormsPlus.scss";
import { User } from "src/types";
import { useAppSelector } from "src/hooks";
import MultiTabContainer from "src/components/MultiTabContainer/MultiTabContainer";


const FormsPlus: FC = () => {

    const self: User|undefined = useAppSelector((state) => state?.Self)?.value;

    const beforeunloadHandler = useCallback(function (e) {
        e.preventDefault();
        e.returnValue = "";
    }, []);

    useEffect(() => {
        const syncData = async () => {
            if (self?.pendingChanges?.length) {
                window.addEventListener(
                    "beforeunload",
                    beforeunloadHandler
                );
            } else {
                window.removeEventListener(
                    "beforeunload",
                    beforeunloadHandler
                );
            }
        };
        syncData();
        return () => {
            window.removeEventListener("beforeunload", beforeunloadHandler);
        };
    }, [self?.pendingChanges]);

    const getDocument = () => {
        return <MultiTabContainer />;
    };

    const getContent = () => (
        <div className="formsplus-document-container">
            <div className="formsplus-document">{getDocument()}</div>
        </div>
    );

    return (
        <div className="formsplus-container">
            <ContainerWithResizableSideBar
                sideBarContent={<ReturnTreeViewer />}
                content={getContent()}
            />
        </div>
    );
};

export default FormsPlus;
