import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { RoleService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./UserRoleModal.scss";

interface UserRoleModalProps {
    open: boolean;
    onClose: () => void;
}

const UserRoleModal: FC<UserRoleModalProps> = ({ open, onClose }) => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const roleService = RoleService.getInstance();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountRole = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccountRole
    );
    const selectedAccountRoles = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccountRoles
    );

    const initialFormData = {
        name: selectedAccountRole?.name || "",
        description: selectedAccountRole?.description || "",
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
    }, [selectedAccountRole]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedUserRole = {
            // this should be Role type
            ...selectedAccountRole,
            accountId: selectedAccount?.id,
            name: formData.name,
            description: formData.description,
            lastModified: new Date().toISOString(),
        };

        try {
            setIsLoading(true);

            await roleService.updateAccountUserRole(updatedUserRole);

            const updatedSelectedAccountRoles = [...selectedAccountRoles];

            updatedSelectedAccountRoles.forEach((role, index) => {
                if (role.id === updatedUserRole.id) {
                    updatedSelectedAccountRoles[index] = {
                        ...updatedUserRole,
                    };
                }
            });

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountRoles(updatedSelectedAccountRoles)
            );

            onClose();
        } catch (error) {
            setError(error as Error);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <Modal open={open} onClose={handleCancel}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">
                            User Role Information
                        </Typography>
                        <StyledForm onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                            />

                            <StyledFlexContainer justify="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </StyledFlexContainer>
                        </StyledForm>
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default UserRoleModal;
