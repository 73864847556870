import { Tooltip } from "@mui/material";
import React from "react";
import { ReturnPage } from "src/types";
import "./PdfViewerPages.scss";

const PdfViewerPages = ({
    returnTemplate,
    selectedReturnPage,
    setSelectedReturnPage,
}) => {
    if (!returnTemplate) {
        return null;
    }

    const isSelectedPage = (page: ReturnPage): boolean => {
        if (
            selectedReturnPage?.attributes.templateId ===
            page.attributes.templateId
        ) {
            return true;
        }
        return false;
    };

    const handleClickPage = (page: ReturnPage) => () => {
        setSelectedReturnPage(page);
    };

    const visiblePages = returnTemplate.returnPages.filter((page) => {
        return page.attributes.isVisible;
    });

    return (
        <div className="pdf-viewer-pages-container">
            <div className="pdf-viewer-pages-main">
                {visiblePages.length > 1 ? (
                    <>
                        <span className="page-text">Pages » </span>
                        {visiblePages.map((page, index) => {
                            return (
                                <Tooltip
                                    key={index}
                                    title={page.attributes.caption}
                                    arrow
                                >
                                    <div
                                        key={index}
                                        className={`pdf-page ${
                                            isSelectedPage(page)
                                                ? "pdf-page--selected"
                                                : ""
                                        }`}
                                        onClick={handleClickPage(page)}
                                    >
                                        <span className="pdf-page-text">
                                            {page.attributes.caption}
                                        </span>
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </>
                ) : null}
                {visiblePages.length === 1 ? (
                    <span className="page-text">
                        {visiblePages[0].attributes.caption}
                    </span>
                ) : null}
            </div>
        </div>
    );
};

export default PdfViewerPages;
