import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
} from "../../../../AccountAdmin.styled";
import UserRoleModal from "../../modals/UserRoleModal/UserRoleModal";
import "./UserRole.scss";
import { FrameCard } from "src/uikit";

const UserRole: FC = () => {
    const [userRoleModalOpen, setUserRoleModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountRoles = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountRoles
    );
    const selectedAccountRole = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountRole
    );

    useEffect(() => {
        if (selectedAccountRoles?.length > 0) {
            const selectedAccountRole: FixMeLater = selectedAccountRoles[0];
            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccountRole(
                    selectedAccountRole
                )
            );
        }
    }, [selectedAccountRoles]);

    return (
        <>
            <div className="user-role-user-role-container">
                {/* <FrameCard title="ROLE" actions={
                    ( selectedAccountRole ? [{
                        text: "Edit",
                        onClick: () => setUserRoleModalOpen(true), // Disabled for now
                    }] : [])
                }> */}

                <FrameCard title="ROLE" actions={
                    ( selectedAccountRole ? [{
                        text: "Edit",
                        onClick: () => setUserRoleModalOpen(true),
                    }] : [])
                }>
                    {selectedAccountRole ? (
                        <div className="items-container">
                            <span className="title">Name</span>
                            <span className="data">
                                {selectedAccountRole?.name}
                            </span>
                            <span className="title">Description</span>
                            <span className="data">
                                {selectedAccountRole?.description}</span>
                        </div>
                    ) : (
                        <StyledNoDataFound>No role selected</StyledNoDataFound>
                    )}
                </FrameCard>
            </div>
            <UserRoleModal
                open={userRoleModalOpen}
                onClose={() => setUserRoleModalOpen(false)}
            />
        </>
    );
};

export default UserRole;
