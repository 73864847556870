import styled from 'styled-components';
import SCSS_VARIABLES from "src/styles/component.scss";

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .title {
        color: ${SCSS_VARIABLES.primary};
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 20px;
    }

`;