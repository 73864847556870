import { BaseAPIService } from "src/services/BaseApiService";
import {
    OptinsCompareResponse,
    OptinsPayload,
} from "src/types/OptinsService.types";

export class OptinsService {
    private static _instance: OptinsService;
    private readonly BASE_PATH: string = "/api/return-optins";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public compare(payload: OptinsPayload): Promise<OptinsCompareResponse[]> {
        return this.api.post<OptinsCompareResponse[]>(
            this.getPath("/compare"),
            payload
        );
    }

    public async export(payload: OptinsPayload): Promise<Response> {
        const response = await this.api.post<any>(
            this.BASE_PATH,
            payload,
            {}, true
        );
        return response;
    }

    public static getInstance(): OptinsService {
        if (!OptinsService._instance) {
            OptinsService._instance = new OptinsService();
        }
        return OptinsService._instance;
    }
}
