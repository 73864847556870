import { QueryParams } from "src/types";

export function getQueryParams(
    params: Record<string, string | number | boolean | undefined | null>,
    excludeEmpty: boolean = true
): QueryParams {
    const queryParams: QueryParams = {};

    Object.entries(params).forEach(([key, value]) => {
        const isEmpty = value === "" || value === null || value === undefined;
        if (excludeEmpty && isEmpty) {
            return;
        } 
        queryParams[key] = String(value);
    });

    return queryParams;
}

export function getParamsString(params: QueryParams): string {
    return new URLSearchParams(params).toString();
}
