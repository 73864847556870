import { Table, TableBody } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { UserService } from "src/services/UserService";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import UserListModal from "../../modals/UserListModal/UserListModal";
import "./UserList.scss";
import { text } from "stream/consumers";
import { FrameCard } from "src/uikit";

const UserList: FC = () => {
    const userService = UserService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountUser = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountUser
    );
    const selectedAccountUsers = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountUsers
    );

    const [userListModalOpen, setUserListModalOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountUsers = async () => {
            setIsLoading(true);
            try {
                const selectedAccountUsers = await userService.getAccountUsers(
                    selectedAccount.id
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUsers(selectedAccountUsers)
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUser(selectedAccountUsers?.[0])
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountUsers();
    }, [selectedAccount, dispatch, product]);

    const users = selectedAccountUsers?.map((user) => {
        return (
            <StyledTableRow
                key={user?.id}
                $isSelected={user?.id === selectedAccountUser?.id}
            >
                <StyledTableCell
                    align="left"
                    onClick={() => {
                        dispatch(
                            GlobalStateActions[
                                product?.productName
                            ].setSelectedAccountUser(user)
                        );
                    }}
                >
                    {renderField(
                        "Name",
                        getStringValueOrDefault(user?.firstName)
                    )}
                    {renderField("Email", getStringValueOrDefault(user?.email))}
                </StyledTableCell>
            </StyledTableRow>
        );
    });

    if (error) return <ErrorMessage error={error} />;

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="user-list-container">
                <FrameCard
                    title="USERS"
                    actions={[
                        {
                            text: "Add User",
                            onClick: () => setUserListModalOpen(true),
                        },
                    ]}
                >
                    {selectedAccountUsers?.length === 0 ? (
                        <StyledNoDataFound>
                            This account is not currently associated with any
                            users.
                        </StyledNoDataFound>
                    ) : (
                        <StyledTableContainer $maxHeight={"480px"}>
                            <Table>
                                <TableBody>{users}</TableBody>
                            </Table>
                        </StyledTableContainer>
                    )}
                </FrameCard>
            </div>
            <UserListModal
                open={userListModalOpen}
                onClose={() => setUserListModalOpen(false)}
            />
        </>
    );
};

export default UserList;
