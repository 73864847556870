import React, { useState, useEffect } from "react";
import {
    Box,
    IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, ArrowRight } from "@mui/icons-material";

interface Item {
    name: string;
    description: string;
    img: string;
}

export type CarouselProps = {
    items: React.ReactNode[];
    delay?: number;
};

export const Carousel: React.FC<CarouselProps> = ({ items, delay = 5000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, delay);

        return () => clearInterval(interval);
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + items.length) % items.length,
        );
    };

    return (
        <Box
            sx={{
                position: "relative",
                margin: "auto",
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        transition: "transform 0.5s ease-in-out",
                        transform: `translateX(-${currentIndex * 100}%)`,
                        width: `${items.length * 100}%`,
                        maxWidth: "100%",
                    }}
                >
                    {items.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: "100%",
                                padding: "20px",
                                flexShrink: 0,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                boxSizing: "border-box",
                            }}
                        >
                            {item}
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <IconButton onClick={handlePrev}>
                        <ArrowBackIos />
                    </IconButton>
                    <IconButton onClick={handleNext}>
                        <ArrowForwardIos />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};
