import { Button, Modal, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CheckboxTree from "src/components/CheckboxTree/CheckboxTree";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccessControlGroupService, AccountService } from "src/services";
import {
    AccessControlGroup,
    CheckboxTreeData,
    Company,
    SubAccount,
    SubAccountsCompanies,
} from "src/types";
import {
    StyledFlexContainer,
    StyledFormContainer,
    StyledModalContainer,
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import "./CompaniesModal.scss";

interface CompaniesModalProps {
    open: boolean;
    onClose: () => void;
    handleSuccess: () => void;
}

const CompaniesModal: FC<CompaniesModalProps> = ({
    open,
    onClose,
    handleSuccess,
}) => {
    const accountService = AccountService.getInstance();
    const accessControlGroupService = AccessControlGroupService.getInstance();

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    let [tree, setTree] = useState<CheckboxTreeData[]>([]);

    const product = useAppSelector((state) => state?.Product?.value);

    if (!product) {
        return null;
    }

    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountGroup: AccessControlGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup,
    );
    const selectedAccountGroups = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroups,
    );
    const selectedAccountCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountCompanies,
    );
    const selectedAccountGroupCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountGroupCompanies,
    );

    const [selectedAccountGroupCompanyIds, setSelectedAccountGroupCompanyIds] =
        useState(selectedAccountGroupCompanies?.map((company) => company.id));

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchSelectedAccountCompanies = async () => {
            if (selectedAccount && selectedAccountGroup) {
                setIsLoading(true);
                try {
                    const selectedAccountCompanies =
                        await accountService.getAccountCompanies(
                            selectedAccount?.id,
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompanies(selectedAccountCompanies),
                    );

                    const subaccountCompanies: SubAccountsCompanies =
                        await accessControlGroupService.getSubAccountsCompanies(
                            selectedAccountGroup.id,
                        );

                    setTree(
                        generateInitialCheckboxTreeData(
                            subaccountCompanies,
                            selectedAccountCompanies,
                        ),
                    );
                } catch (error) {
                    console.error(error);
                    setError("Error retrieving account companies.");
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSelectedAccountCompanies();
    }, [selectedAccount]);

    function hasChildren(data: CheckboxTreeData) {
        return Array.isArray(data.children) && data.children.length > 0;
    }

    function updateCheckStatus(data: CheckboxTreeData[]) {
        data.forEach((item: CheckboxTreeData) => {
            if (hasChildren(item) && item.children) {
                updateCheckStatus(item.children);

                const allChildrenChecked = item.children.every(
                    (child) => child.checked,
                );
                const allChildrenUnchecked = item.children.every(
                    (child) => !child.checked,
                );
                if (allChildrenChecked) {
                    item.checked = true;
                } else if (allChildrenUnchecked) {
                    item.checked = false;
                }
            }
        });
    }

    function generateInitialCheckboxTreeData(
        rawData: SubAccountsCompanies,
        selectedAccountCompanies: Company[],
    ): CheckboxTreeData[] {
        let subAccountTrees: CheckboxTreeData[] = [];

        rawData.subaccounts.forEach((subAccountWithCompanies) => {
            let subAccount: SubAccount = subAccountWithCompanies.subaccount;
            let companies: Company[] = subAccountWithCompanies.companies;
            let subAccountTree: CheckboxTreeData = {
                id: subAccount.id.toString(),
                label: subAccount.name,
                checked: false,
                children: [
                    ...companies.map((company) => {
                        return {
                            id: company.id.toString(),
                            label: company.name,
                            checked:
                                selectedAccountGroup.id ===
                                company.accessControlGroupId,
                            children: [],
                        };
                    }),
                ],
            };
            subAccountTrees.push(subAccountTree);
        });

        let treeForCompaniesWithNoSubAccount: CheckboxTreeData = {
            id: "0",
            label: "Companies (not assigned to a subaccount)",
            checked: false,
            children: [
                ...rawData.companies.map((company) => {
                    return {
                        id: company.id.toString(),
                        label: company.name,
                        checked:
                            selectedAccountGroup.id ===
                            company.accessControlGroupId,
                        children: [],
                    };
                }),
            ],
        };

        subAccountTrees.push(treeForCompaniesWithNoSubAccount);

        updateCheckStatus(subAccountTrees);

        return subAccountTrees;
    }

    function getSelectedCompanyIds(tree: CheckboxTreeData[]): number[] {
        let selectedCompanyIds: number[] = [];
        tree.forEach((subAccount) => {
            subAccount.children?.forEach((company) => {
                if (company.checked) {
                    selectedCompanyIds.push(Number(company.id));
                }
            });
        });
        return selectedCompanyIds;
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const companyIdsToUpdate = getSelectedCompanyIds(tree);

            const updatedSelectedAccountGroup = {
                ...selectedAccountGroup,
                companies: companyIdsToUpdate,
            };

            const updatedSelectedAccountGroupResponse =
                await accountService.updateAccountGroupContent(
                    updatedSelectedAccountGroup,
                    "company",
                );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroup(updatedSelectedAccountGroupResponse),
            );

            const updatedSelectedAccountGroups = selectedAccountGroups?.map(
                (group) =>
                    group.id === updatedSelectedAccountGroupResponse.id
                        ? { ...updatedSelectedAccountGroupResponse }
                        : group,
            );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroups(updatedSelectedAccountGroups),
            );

            handleSuccess();
            onClose();
        } catch (error) {
            setError("Failed to assign selected companies.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setSelectedAccountGroupCompanyIds(
            selectedAccountGroupCompanies?.map((company) => company.id),
        );
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">
                            Edit Group Companies
                        </Typography>

                        {selectedAccountCompanies?.length === 0 ? (
                            <StyledNoDataFound>
                                No companies found for this account.
                            </StyledNoDataFound>
                        ) : (
                            <>
                                <div className="checkboxtree">
                                    <CheckboxTree
                                        data={tree}
                                        onDataChange={setTree}
                                    />
                                </div>

                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </>
                        )}
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default CompaniesModal;
