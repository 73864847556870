import {
    Button,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyProductService, ProductService } from "src/services";
import { FixMeLater, Jurisdiction } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import { processCompanyProducts } from "../../components/Products/Products.util";
import "./AddProductModal.scss";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";

interface AddProductModalProps {
    open: boolean;
    onClose: () => void;
}

const AddProductModal: FC<AddProductModalProps> = ({ open, onClose }) => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const product: FixMeLater = useAppSelector((state) => state.Product.value);
    const selectedAccountProducts = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccountProducts
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountCompany
    );

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const dispatch = useAppDispatch();
    const productService = ProductService.getInstance();
    const companyProductService = CompanyProductService.getInstance();

    const groupProductsByName = (products: FixMeLater) => {
        const productMap = new Map<string, FixMeLater>();
        products?.forEach((product) => {
            if (!productMap.has(product?.productName)) {
                productMap.set(product?.productName, [product]);
            } else {
                productMap.get(product?.productName)?.push(product);
            }
        });

        productMap.forEach((value, key) => {
            productMap.set(
                key,
                value.sort((a, b) => b.taxYear - a.taxYear)
            );
        });
        return productMap;
    };

    const groupedProducts = groupProductsByName(selectedAccountProducts);

    // Agent is not assignable to a company.
    const availableProductNames = Array.from(groupedProducts.keys()).filter(
        (productName) => productName !== "Agent"
    );

    const [selectedProduct, setSelectedProduct] = useState(
        groupedProducts.get(availableProductNames?.[0])?.[0]
    );
    const [productJurisdictions, setProductJurisdictions] = useState<
        Jurisdiction[]
    >([]);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>();

    useEffect(() => {
        const fetchData = async () => {
            if (selectedProduct?.productName === "Municipal") {
                const productJurisdictions =
                    await productService.getProductJurisdictions(
                        selectedProduct?.productId
                    );
                setProductJurisdictions(productJurisdictions);
                setSelectedJurisdiction(productJurisdictions[0]?.longName);
            }
        };

        fetchData();
    }, [selectedProduct?.productId]);

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const availableTaxYears = groupedProducts
        .get(selectedProduct?.productName)
        ?.map((product) => product.taxYear)
        .sort((a, b) => b - a);

    const handleInputChange = (e: FixMeLater) => {
        const { name, value } = e.target;

        if (name === "productName") {
            setSelectedProduct(groupedProducts.get(value)?.[0]);
        } else if (name === "taxYear") {
            setSelectedProduct(
                groupedProducts
                    .get(selectedProduct?.productName)
                    ?.find((product) => product?.taxYear === Number(value))
            );
        } else if (name == "jurisdiction") {
            setSelectedJurisdiction(value);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            // Get all jurisdictions for the selected product
            const productJurisdictions =
                await productService.getProductJurisdictions(
                    selectedProduct?.productId
                );

            const newJurisdictions = productJurisdictions?.map(
                (jurisdiction) => {
                    return {
                        companyId: selectedAccountCompany?.id,
                        productId: selectedProduct?.productId,
                        year: selectedProduct?.taxYear,
                        jurisdictionDTO: jurisdiction,
                        isSelected: true,
                    };
                }
            );

            if (selectedProduct?.productName === "Municipal") {
                const filteredJurisdictions = newJurisdictions.filter(
                    (jurisdiction) =>
                        jurisdiction.jurisdictionDTO.longName ===
                        selectedJurisdiction // Filter by jurisdiction
                );
                await companyProductService.createCompanyProducts(
                    selectedAccountCompany?.id,
                    {
                        companyProductDTOs: filteredJurisdictions,
                    }
                );
            } else {
                await companyProductService.createCompanyProducts(
                    selectedAccountCompany?.id,
                    {
                        companyProductDTOs: newJurisdictions,
                    }
                );
            }

            const updatedSelectedAccountCompanyProducts =
                await companyProductService.getCompanyProducts(
                    selectedAccountCompany?.id
                );

            const processedUpdatedSelectedAccountCompanyProducts =
                processCompanyProducts(updatedSelectedAccountCompanyProducts);

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountCompanyProducts(
                    processedUpdatedSelectedAccountCompanyProducts
                )
            );
            onClose();
        } catch (error) {
            setError(error as Error);
            console.log(error);
            handleSnackbar(
                `Error cannot assign product to company : ` + error,
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <Modal open={open} onClose={handleCancel}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">
                            Assign Product To Company
                        </Typography>
                        <StyledForm onSubmit={handleSubmit}>
                            <Select
                                variant="outlined"
                                required
                                fullWidth
                                id="productName"
                                name="productName"
                                value={selectedProduct?.productName}
                                onChange={handleInputChange}
                            >
                                {availableProductNames?.map((productName) => (
                                    <MenuItem
                                        key={productName}
                                        value={productName}
                                    >
                                        {productName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                variant="outlined"
                                required
                                fullWidth
                                id="taxYear"
                                name="taxYear"
                                value={selectedProduct?.taxYear}
                                onChange={handleInputChange}
                            >
                                {availableTaxYears?.map((taxYear) => (
                                    <MenuItem key={taxYear} value={taxYear}>
                                        {taxYear}
                                    </MenuItem>
                                ))}
                            </Select>
                            {selectedProduct?.productName === "Municipal" && (
                                <Select
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="jurisdiction"
                                    name="jurisdiction"
                                    value={selectedJurisdiction}
                                    onChange={handleInputChange}
                                >
                                    {productJurisdictions?.map(
                                        (jurisdiction) => (
                                            <MenuItem
                                                key={jurisdiction?.id}
                                                value={jurisdiction?.longName}
                                            >
                                                {jurisdiction?.longName}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            )}

                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="companyOrClickQuantity"
                                label="Company Or Click Quantity"
                                name="companyOrClickQuantity"
                                disabled
                                type="number"
                                value={selectedProduct?.companyOrClickQuantity}
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="userQuantity"
                                label="User Quantity"
                                name="userQuantity"
                                disabled
                                type="number"
                                value={selectedProduct?.userQuantity}
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="jurisdictionQuantity"
                                label="Jurisdiction Quantity"
                                name="jurisdictionQuantity"
                                disabled
                                type="number"
                                value={selectedProduct?.jurisdictionQuantity}
                            />

                            <StyledFlexContainer justify="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </StyledFlexContainer>
                        </StyledForm>
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
                <CustomSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </div>
        </Modal>
    );
};

export default AddProductModal;
