import { Company, SubAccountsCompanies, User } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class AccessControlGroupService {
    private static _instance: AccessControlGroupService;
    private readonly BASE_PATH: string = "/api/accesscontrolgroup";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getAccountGroupCompanies(groupId: number): Promise<Company[]> {
        return this.api.get<Company[]>(this.getPath(`/${groupId}/companies`));
    }

    getAccountGroupUsers(groupId: number): Promise<User[]> {
        return this.api.get<User[]>(this.getPath(`/${groupId}/users`));
    }

    getSubAccountsCompanies(accessControlGroupId: number): Promise<SubAccountsCompanies> {
        return this.api.get<SubAccountsCompanies>(this.getPath(`/${accessControlGroupId}/subaccountscompanies`));
    }

    public static getInstance(): AccessControlGroupService {
        if (!AccessControlGroupService._instance) {
            AccessControlGroupService._instance =
                new AccessControlGroupService();
        }
        return AccessControlGroupService._instance;
    }
}
