import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
} from "src/products/AccountAdmin/AccountAdmin.styled";
import AddProductModal from "src/products/AccountAdmin/components/Overview/modals/AddProductModal/AddProductModal";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import "./Products.scss";
import ProductsTableRow from "./components/ProductsTableRow/ProductsTableRow";
import { FrameCard } from "src/uikit";

const Products: FC = () => {
    const accountService = AccountService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product.productName].value?.selectedAccount
    );
    const selectedAccountProducts = useAppSelector(
        (state) => state?.[product.productName].value?.selectedAccountProducts
    );
    const [productsModalOpen, setProductsModalOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountProducts = async () => {
            if (selectedAccount) {
                setIsLoading(true);
                try {
                    const selectedAccountProducts =
                        await accountService.getAccountProducts(
                            selectedAccount.id
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountProducts(selectedAccountProducts)
                    );
                } catch (error) {
                    console.error("Could not retrieve account products.");
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchSelectedAccountProducts();
    }, [selectedAccount]);

    const groupProductsByName = (products: FixMeLater) => {
        const productMap = new Map();
        products?.forEach((product: FixMeLater) => {
            if (!productMap.has(product?.productName)) {
                productMap.set(product?.productName, [product]);
            } else {
                productMap.get(product?.productName).push(product);
            }
        });
        return productMap;
    };

    const getTableRows: FixMeLater = (
        groupedProducts: Map<FixMeLater, FixMeLater>
    ) => {
        const rows = Array.from(groupedProducts)?.map(
            ([productName, products]) => {
                return <ProductsTableRow key={productName} data={products} />;
            }
        );

        return rows;
    };

    const noDataFoundMessage = selectedAccount
        ? "This account is not currently associated with any product."
        : "Please select a group.";

    if (error) return <ErrorMessage error={error} />;

    if (!error && isLoading) return <Loader />;

    return (
        <div className="products-container">
            <FrameCard
                title="PRODUCTS"
                actions={[
                    {
                        text: "Add Product",
                        onClick: () => setProductsModalOpen(true),
                    },
                ]}
            >
                {selectedAccountProducts?.length === 0 ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Product</StyledTableCell>
                                    <StyledTableCell>Tax Year</StyledTableCell>
                                    <StyledTableCell>
                                        Company/Click
                                    </StyledTableCell>
                                    <StyledTableCell>User</StyledTableCell>
                                    <StyledTableCell>
                                        Jurisdiction
                                    </StyledTableCell>
                                    <StyledTableCell>Edit</StyledTableCell>
                                    <StyledTableCell>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getTableRows(
                                    groupProductsByName(selectedAccountProducts)
                                )}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                )}
            </FrameCard>
            <AddProductModal
                open={productsModalOpen}
                onClose={() => {
                    setProductsModalOpen(false);
                }}
            />
        </div>
    );
};

export default Products;
