import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import React, { FC } from "react";
import { DEFAULT_ZOOM_SCALE } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import colors from "src/styles/colors.scss";
import { FixMeLater, UserOptions } from "src/types";
import "./PdfControls.scss";
import { StyledPdfController, StyledSelect } from "./PdfControls.styled";

interface PdfControlsProps {}

const PdfControls: FC<PdfControlsProps> = () => {
    const dispatch = useAppDispatch();

    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value,
    );

    let SCALE = Number(
        userOptions?.globalPreferences?.zoom || DEFAULT_ZOOM_SCALE,
    );

    const updateScale = async (scale: number) => {
        dispatch(GlobalStateActions.UserOptions.setZoomScale(scale));
    };

    return (
        <div className="pdf-controls-container" data-testid="pdf-controls">
            <StyledPdfController>
                <RemoveCircleOutline
                    {...{ disabled: SCALE <= 1 }} // FIXME: Dirty hack to avoid TS error (disabled is not a valid prop for ZoomOutIcon)
                    onClick={() => {
                        if (SCALE > 1) {
                            updateScale(SCALE - 0.25);
                        }
                    }}
                    style={{
                        color: SCALE > 1 ? colors.iconColor : "grey",
                    }}
                />
                <AddCircleOutline
                    {...{ disabled: SCALE >= 2 }} // FIXME: Dirty hack to avoid TS error (disabled is not a valid prop for ZoomInIcon)
                    onClick={() => {
                        if (SCALE < 2) {
                            updateScale(SCALE + 0.25);
                        }
                    }}
                    style={{
                        color: SCALE < 2 ? colors.iconColor : "grey",
                    }}
                />
                <StyledSelect
                    onChange={(e: SelectChangeEvent<FixMeLater>) => {
                        // localStorage.setItem("pdf-zoom-scale", e.target.value);
                        updateScale(e.target.value);
                    }}
                    value={SCALE}
                >
                    {[1.0, 1.25, 1.5, 1.75, 2.0].map((value, index) => (
                        <MenuItem key={index + 1} value={value}>
                            {value * 100}%
                        </MenuItem>
                    ))}
                </StyledSelect>
            </StyledPdfController>
        </div>
    );
};

export default PdfControls;
