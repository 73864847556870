import React, { FC, useState } from "react";
import { useAppSelector } from "src/hooks";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import { FrameCard } from "src/uikit";
import {
    StyledFlexContainer,
    StyledNoDataFound
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import CompanyInformationModal from "../../modals/CompanyInformationModal/CompanyInformationModal";
import "./CompanyInformation.scss";

const CompanyInformation: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountCompany
    );

    const [companyInformationModalOpen, setCompanyInformationModalOpen] =
        useState<boolean>(false);

    const getCompanyFields = () => (
        <>
            {renderField(
                "Name",
                getStringValueOrDefault(selectedAccountCompany?.name)
            )}
            {renderField(
                "Abbreviation",
                getStringValueOrDefault(selectedAccountCompany?.coabbr)
            )}
            <StyledFlexContainer>
                {renderField(
                    "FEIN",
                    getStringValueOrDefault(selectedAccountCompany?.federalId)
                )}
                {renderField(
                    "NAIC",
                    getStringValueOrDefault(selectedAccountCompany?.naic)
                )}
                {renderField(
                    "NAIC Group",
                    getStringValueOrDefault(selectedAccountCompany?.naicGroup)
                )}
            </StyledFlexContainer>
            {renderField(
                "Sub-Account",
                getStringValueOrDefault(
                    selectedAccountCompany?.subAccount?.name
                )
            )}
            {renderField(
                "State of Domicile",
                getStringValueOrDefault(selectedAccountCompany?.stateOfDomicile)
            )}
            <StyledFlexContainer>
                {renderField(
                    "Company Type",
                    getStringValueOrDefault(selectedAccountCompany?.companyType)
                )}
                {renderField(
                    "Sub-Type",
                    getStringValueOrDefault(
                        selectedAccountCompany?.companySubType
                    )
                )}
            </StyledFlexContainer>
            {renderField(
                "Address1",
                getStringValueOrDefault(selectedAccountCompany?.address1)
            )}
            {renderField(
                "Address2",
                getStringValueOrDefault(selectedAccountCompany?.address2)
            )}
            <StyledFlexContainer>
                {renderField(
                    "City",
                    getStringValueOrDefault(selectedAccountCompany?.addressCity)
                )}
                {renderField(
                    "State",
                    getStringValueOrDefault(
                        selectedAccountCompany?.addressState
                    )
                )}
                {renderField(
                    "Zip Code",
                    getStringValueOrDefault(selectedAccountCompany?.addressZip)
                )}
            </StyledFlexContainer>
        </>
    );

    return (
        <>
            <div className="company-information-container">
                <FrameCard
                    title="COMPANY INFORMATION"
                    actions={
                        selectedAccountCompany
                            ? [
                                  {
                                      text: "Edit",
                                      onClick: () =>
                                          setCompanyInformationModalOpen(true),
                                  },
                              ]
                            : []
                    }
                >
                    {selectedAccountCompany ? (
                        getCompanyFields()
                    ) : (
                        <StyledNoDataFound>
                            Please select a company.
                        </StyledNoDataFound>
                    )}
                </FrameCard>
            </div>
            <CompanyInformationModal
                open={companyInformationModalOpen}
                onClose={() => setCompanyInformationModalOpen(false)}
            />
        </>
    );
};

export default CompanyInformation;
