import styled from "styled-components";

export const StyledMigrationContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    height: 600px;
    width: 900px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;