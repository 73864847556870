import styled from "styled-components";
import SCSS_VARIABLES from "src/styles/component.scss";

export const ApplicationFooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${SCSS_VARIABLES.light};
    padding: 5px;
    font-size: 12px;
    border-top: 1px solid #E0E0E0;

    .copyright {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .version {
        margin-left: auto;
        white-space: nowrap;
    }

`;