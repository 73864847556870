import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services/";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./EditProductModal.scss";

interface EditProductModalProps {
    open: boolean;
    onClose: () => void;
    productToEdit: FixMeLater;
}

const EditProductModal: FC<EditProductModalProps> = ({
    open,
    onClose,
    productToEdit,
}) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountProducts = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccountProducts
    );

    const initialFormData = {
        companyOrClickQuantity: productToEdit?.companyOrClickQuantity || 0,
        userQuantity: productToEdit?.userQuantity || 0,
        jurisdictionQuantity: productToEdit?.jurisdictionQuantity || 0,
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
    }, [productToEdit]);

    const [validationErrors, setValidationErrors] = useState({
        companyOrClickQuantity: "",
        userQuantity: "",
        jurisdictionQuantity: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numericValue = parseFloat(value);
        setFormData({ ...formData, [name]: numericValue });

        if (numericValue < 0) {
            setValidationErrors({
                ...validationErrors,
                [name]: `${
                    name.charAt(0).toUpperCase() + name.slice(1)
                } cannot be negative.`,
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedProduct = {
            ...formData,
            productName: productToEdit?.productName,
            taxYear: productToEdit?.taxYear,
            accountId: selectedAccount.id,
            productId: ProductNameToIdMap.get(productToEdit?.productName),
            expirationDate: [productToEdit?.taxYear, 12, 31].join("-"),
            isTrial: false,
            productTypeId: "BASIC",
        };

        try {
            setIsLoading(true);
            await accountService.setProductsToAccount([updatedProduct]);

            const updatedSelectedAccountProducts = [...selectedAccountProducts];

            updatedSelectedAccountProducts.forEach((product, index) => {
                if (
                    product.taxYear === updatedProduct.taxYear &&
                    product?.productName === updatedProduct?.productName
                ) {
                    updatedSelectedAccountProducts[index] = {
                        ...updatedProduct,
                    };
                }
            });

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountProducts(updatedSelectedAccountProducts)
            );

            onClose();
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <Modal open={open} onClose={handleCancel}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">
                            Update Account Product
                        </Typography>
                        <StyledForm onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="productName"
                                label="Product Name"
                                name="productName"
                                value={productToEdit?.productName}
                                disabled
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="taxYear"
                                label="Tax Year"
                                name="taxYear"
                                type="number"
                                value={productToEdit?.taxYear}
                                disabled
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="companyOrClickQuantity"
                                label="Company Or Click Quantity"
                                name="companyOrClickQuantity"
                                type="number"
                                value={formData.companyOrClickQuantity}
                                onChange={handleInputChange}
                                error={
                                    !!validationErrors.companyOrClickQuantity
                                }
                                helperText={
                                    validationErrors.companyOrClickQuantity
                                }
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="userQuantity"
                                label="User Quantity"
                                name="userQuantity"
                                type="number"
                                value={formData.userQuantity}
                                onChange={handleInputChange}
                                error={!!validationErrors.userQuantity}
                                helperText={validationErrors.userQuantity}
                            />
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="jurisdictionQuantity"
                                label="Jurisdiction Quantity"
                                name="jurisdictionQuantity"
                                type="number"
                                value={formData.jurisdictionQuantity}
                                onChange={handleInputChange}
                                error={!!validationErrors.jurisdictionQuantity}
                                helperText={
                                    validationErrors.jurisdictionQuantity
                                }
                            />
                            <StyledFlexContainer justify={"center"}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !!validationErrors.companyOrClickQuantity ||
                                        !!validationErrors.userQuantity ||
                                        !!validationErrors.jurisdictionQuantity
                                    }
                                >
                                    Update
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </StyledFlexContainer>
                        </StyledForm>
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default EditProductModal;
