import React, { FC, useEffect, useState } from "react";
import MultiTab from "src/components/MultiTab/MultiTab";
import { Sync, SyncDisabled } from "@mui/icons-material";
import "./MultiTabContainer.scss";
import { Tooltip } from "@mui/material";

const MultiTabContainer: FC = () => {
    const [splitScreen, setSplitScreen] = useState<boolean>(false);
    const [synchronized, setSynchronized] = useState<boolean>(false);

    const [activeReturn, setActiveReturn] = useState<string>("");
    const [activeReturnSplitScreen, setActiveReturnSplitScreen] =
        useState<string>("");

    const [tabs, setTabs] = useState<Map<string, Tab>>(new Map());

    const [tabsSplitScreen, setTabsSplitScreen] = useState<Map<string, Tab>>(
        new Map(),
    );

    const addTabToSplitScreen = (mapKey: string) => {
        const tab = tabs.get(mapKey);
        if (tab) {
            setTabsSplitScreen(new Map(tabsSplitScreen).set(mapKey, tab));
        }
    };

    useEffect(() => {
        if (tabsSplitScreen.size === 0 || tabs.size === 0) {
            setSplitScreen(false);
        }
    }, [tabsSplitScreen, tabs]);

    return (
        <div
            data-testid="multi-tab-container"
            className={
                synchronized
                    ? "container"
                    : "container desynchronized"
            }
        >
            <div
                data-testid="main-tab"
                className="main-tab"
                style={{ width: splitScreen ? "50%" : "100%" }}
            >
                <MultiTab
                    splitScreen={splitScreen}
                    setSplitScreen={setSplitScreen}
                    tabs={tabs}
                    setTabs={setTabs}
                    addTabToSplitScreen={addTabToSplitScreen}
                    setActiveReturn={setActiveReturn}
                />
            </div>
            {splitScreen && (
                <>
                    <div className="split-tab">
                        <MultiTab
                            splitScreen={splitScreen}
                            setSplitScreen={setSplitScreen}
                            tabs={tabsSplitScreen}
                            setTabs={setTabsSplitScreen}
                            addTab={false}
                            setActiveReturn={setActiveReturnSplitScreen}
                        />
                    </div>
                    {activeReturn === activeReturnSplitScreen && (
                        <Tooltip
                            title={synchronized ? "desyncronize" : "syncronize"}
                        >
                            <button
                                data-testid="sync-button"
                                onClick={() => setSynchronized(!synchronized)}
                                className="sync-button"
                            >
                                {synchronized ? (
                                    <SyncDisabled className="sync-icon" />
                                ) : (
                                    <Sync className="sync-icon" />
                                )}
                            </button>
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

export default MultiTabContainer;

// Define the Tab type
interface Tab {
    key: string;
    tabName: string;
    taxYear: string;
    companyName: string;
    tooltip: string;
    scheduleType: string;
    isLocked: boolean;
    component: React.ReactElement;
}
