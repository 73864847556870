export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export enum MimeType {
    JSON = "application/json",
    XML = "application/xml",
    PDF = "application/pdf",
    APP_TEXT = "application/text",
    TEXT = "text/plain",
    HTML = "text/html",
    CSV = "text/csv",
    FORM_URLENCODED = "application/x-www-form-urlencoded",
    MULTIPART_FORM_DATA = "multipart/form-data",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // Add other MIME types as needed
}