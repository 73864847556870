import React, { FC } from "react";
import { Typography } from "@mui/material";
import "./NoJobsOverlay.scss";

const NoJobsOverlay: FC = () => {
    return (
        <div className="no-jobs-overlay-container">
            <Typography>No results found.</Typography>
        </div>
    );
};

export default NoJobsOverlay;
