import { GraphQLClient } from "graphql-request";

const client = new GraphQLClient(
    `${process.env.REACT_APP_STRATUS_API_BASE_URL}/graphql`
);

const fetchGraphQLData = async (query, variables, accessToken) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin":
            process.env.REACT_APP_STRATUS_API_BASE_URL || "",
        "Cache-Control": "no-cache,no-store",
    };

    client.setHeaders(headers);

    const data = await client.request(query, variables);
    return data;
};

const GQLService = {
    fetchGraphQLData,
};

export default GQLService;
