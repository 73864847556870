import React, { PropsWithChildren } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.scss";
import { LoadingButton } from "@mui/lab";

export interface ModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    actions?: Array<{
        icon?: React.ReactNode;
        label: string;
        onClick: () => void;
        isLoading?: boolean;
    }>;
    dialogProps?: DialogProps;
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
    open,
    onClose,
    title,
    actions,
    children,
    dialogProps,
}) => {
    return (
        <Dialog
            {...dialogProps}
            scroll="paper"
            open={open}
            onClose={onClose}
            className="tts-modal"
        >
            <DialogTitle className="header">
                <span className="header__title">{title}</span>
                <IconButton className="header__close-icon" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="content">{children}</DialogContent>
            <DialogActions className="actions">
                {actions &&
                    actions?.map((action) => {
                        return (
                            <LoadingButton
                                key={action.label}
                                loading={action.isLoading}
                                variant="text"
                                startIcon={action.icon}
                                onClick={action.onClick}
                            >
                                {action.label}
                            </LoadingButton>
                        );
                    })}
            </DialogActions>
        </Dialog>
    );
};
