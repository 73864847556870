import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { FC } from "react";
import { Account } from "src/types";
import "./AccountListItemMenu.scss";

interface AccountListItemMenuProps {
    account: Account | null;
    anchorEl: HTMLElement | null;
    handleCloseAccountListItemMenu: () => void;
    updateAccountActive: (account: Account, activate: boolean) => void;
}

const AccountListItemMenu: FC<AccountListItemMenuProps> = ({
    account,
    anchorEl,
    handleCloseAccountListItemMenu,
    updateAccountActive,
}) => {
    const onActivate = () => {
        if (account) {
            updateAccountActive(account, true);
            handleCloseAccountListItemMenu();
        }
    };

    const onDeactivate = () => {
        if (account) {
            updateAccountActive(account, false);
            handleCloseAccountListItemMenu();
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseAccountListItemMenu}
            TransitionProps={{
                timeout: 0,
            }}
        >
            {account?.active ? (
                <MenuItem onClick={onDeactivate}>Deactivate</MenuItem>
            ) : (
                <MenuItem onClick={onActivate}>Activate</MenuItem>
            )}
        </Menu>
    );
};

export default AccountListItemMenu;
