import * as React from "react";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Divider } from "@mui/material";

export type CustomMenuProps = {
    onClose?: () => void;
    anchorEl?: null | HTMLElement;
    open: boolean;
    items: Array<{
        icon?: React.ReactNode;
        text: string;
        selected?: boolean;
        cb: () => void;
    }>;
};

export const CustomMenu: React.FC<React.PropsWithChildren<CustomMenuProps>> = ({
    items,
    onClose,
    anchorEl,
    open,
}) => {
    return (

            <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
                {items.map((item, index) => (
                    <>
                    <MenuItem
                        key={index}
                        selected={item.selected}
                        onClick={item.cb}
                    >
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <ListItemText>{item.text}</ListItemText>
                    </MenuItem>
                    {
                        index === items.length - 2 && <Divider />
                    }
                    </>
                    
                ))}
            </Menu>
    );
};
