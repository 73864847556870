import { BaseAPIService } from "src/services/BaseApiService";
import { FixMeLater } from "src/types";
import { Attachment } from "src/types/Attachments.types";

export class AttachmentsService {
    private static _instance: AttachmentsService;
    private readonly BASE_PATH: string = "/api";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public async getAttachment(fileId: string, fileExt: string): Promise<Response> {
        return this.api.get<any>(this.getPath(`/attachment-download?fileId=${fileId}&fileExt=${fileExt}`), {}, true);
    }

    public async uploadAttachment(formData: FormData): Promise<Attachment> {
        return this.api.post<Attachment>(this.getPath("/attachment"), formData, {headers: { "Content-Type": "multipart/form-data" }});
    }

    public async deleteAttachment(payload: FixMeLater): Promise<Response> {
        return this.api.delete(this.getPath("/attachment"), payload);
    }

    public static getInstance(): AttachmentsService {
        if (!AttachmentsService._instance) {
            AttachmentsService._instance = new AttachmentsService();
        }
        return AttachmentsService._instance;
    }
}
