import {
    ActiveReturnDocument,
    ReturnDocumentAndValidationsDTO,
    PushReturnDocument,
    QueryParams,
    ReturnDocument,
    ReturnDocumentId
} from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { getParamsString } from "src/utils";

export class ReturnDocumentService {
    private static _instance: ReturnDocumentService;
    private readonly BASE_PATH: string = "/api/return-document";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getReturnDocument(params: QueryParams): Promise<ReturnDocumentAndValidationsDTO> {
        return this.api.get<ReturnDocumentAndValidationsDTO>(
            this.getPath(`/validations?${getParamsString(params)}`)
        );
    }

    getOnlyReturnDocument(params: QueryParams): Promise<ReturnDocument> {
        return this.api.get<ReturnDocument>(
            this.getPath(`?${getParamsString(params)}`)
        );
    }

    getActiveReturns(params: QueryParams): Promise<ActiveReturnDocument[]> {
        return this.api.get<ActiveReturnDocument[]>(
            this.getPath(`/active?${getParamsString(params)}`)
        );
    }

    pushReturnDocument(returnDocument: ReturnDocument): Promise<PushReturnDocument> {
        return this.api.post<PushReturnDocument>(
            this.getPath(`/push`),
            returnDocument
        );
    }

    activateReturnNode(params: QueryParams): Promise<ReturnDocumentId> {
        return this.api.post<ReturnDocumentId>(
            this.getPath(`/activate?${getParamsString(params)}`)
        );
    }

    activateReturnNodes(params: QueryParams[]): Promise<ReturnDocumentId[]> {
        return this.api.post<ReturnDocumentId[]>(
            this.getPath(`/activate-returns`),
            params
        );
    }

    deactivateReturnNode(params: QueryParams): Promise<ReturnDocumentId> {
        return this.api.delete<ReturnDocumentId>(
            this.getPath(`?${getParamsString(params)}`)
        );
    }

    deactivateReturnNodes(params: QueryParams[]): Promise<ReturnDocumentId[]> {
        return this.api.delete<ReturnDocumentId[]>(
            this.getPath(`/deactivate-returns`),
            params
        );
    }

    lockReturnNode(params: QueryParams): Promise<ReturnDocumentId> {
        return this.api.post<ReturnDocumentId>(
            this.getPath(`/lock?${getParamsString(params)}`)
        );
    }

    lockReturnNodes(params: QueryParams[]): Promise<ReturnDocumentId[]> {
        return this.api.post<ReturnDocumentId[]>(
            this.getPath(`/lock-returns`), params
        );
    }

    unlockReturnNode(params: QueryParams): Promise<ReturnDocumentId> {
        return this.api.post<ReturnDocumentId>(
            this.getPath(`/unlock?${getParamsString(params)}`)
        );
    }

    unlockReturnNodes(params: QueryParams[]): Promise<ReturnDocumentId[]> {
        return this.api.post<ReturnDocumentId[]>(
            this.getPath(`/unlock-returns`), params
        );
    }

    public static getInstance(): ReturnDocumentService {
        if (!ReturnDocumentService._instance) {
            ReturnDocumentService._instance = new ReturnDocumentService();
        }
        return ReturnDocumentService._instance;
    }
}
