import { Box, Tooltip, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import {
    ALLOCATOR_REPORT_TYPES,
    ALLOCATOR_SEARCH_PARAMS,
    AllocatorJob,
    AllocatorReportTab,
    CorrectedAddresses,
    FixMeLater,
    JOB_STATUS_TYPES,
} from "src/types";
import { DEFAULT_SEARCH_PARAMS } from "../../constant";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import "./ReportTabs.scss";
import { SelectedStyledChipTab, StyledChipTab } from "./ReportTabs.styled";
import { useSnackbar } from "notistack";
import ReportExportButton from "./components/ReportExportButton/ReportExportButton/ReportExportButton";

const ReportTabs: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [open, setOpen] = useState<boolean>(false);
    const [tabRedirect, setTabRedirect] = useState<AllocatorReportTab | null>(
        null
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const reportTabs: AllocatorReportTab[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportTabs
    );
    const reportTabsLoading: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportTabsLoading
    );
    const selectedReportTab: AllocatorReportTab = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedReportTab
    );
    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );
    const selectedFileName: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedFileName
    );
    const correctedAddresses: CorrectedAddresses = useAppSelector(
        (state) => state?.[product?.productName]?.value?.correctedAddresses
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const reportId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.REPORT);
    const currentJobStatus = jobList?.find(job => job?.id === selectedJob?.id)?.status?.name;
    const isJobFailed = currentJobStatus === JOB_STATUS_TYPES.FAILED 
        && "Report is not available because the source file contains errors";
    const isJobProcessing = currentJobStatus === JOB_STATUS_TYPES.PROCESSING
        && "Report is not available";

    useEffect(() => {
        const fetchReportsList = async () => {
            dispatch(
                GlobalStateActions[product?.productName].setReportTabsLoading(
                    true
                )
            );

            try {
                const response = await allocatorService.getReportsList(String(jobId));
                const reportTabsWithSummary = [
                    {
                        id: 0,
                        name: ALLOCATOR_REPORT_TYPES.JOB_SUMMARY,
                        defaultReport: false,
                    },
                    ...response,
                ];

                dispatch(
                    GlobalStateActions[product?.productName].setReportTabs(
                        reportTabsWithSummary
                    )
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedReportTab(
                        reportTabsWithSummary?.find(
                            (tab: AllocatorReportTab) =>
                                tab?.id === Number(reportId)
                        )
                    )
                );
            } catch (error) {
                enqueueSnackbar("Error fetching report tabs", { variant: "error" });
            } finally {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setReportTabsLoading(false)
                );
            }
        };

        if (reportId && Number(jobId)) {
            fetchReportsList();
        }
    }, [jobId]);

    useEffect(() => {
        dispatch(
            GlobalStateActions[product?.productName].setSelectedReportTab(
                reportTabs?.find(
                    (tab: AllocatorReportTab) => tab?.id === Number(reportId)
                )
            )
        );
        dispatch(
            GlobalStateActions[product?.productName].setReportTabsLoading(
                true
            )
        );
    }, [reportId]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleTabClick = (tab: AllocatorReportTab) => {
        if (Object.keys(correctedAddresses).length) {
            setOpen(true);
            setTabRedirect(tab);
        } else {
            updateSelectedTab(tab);
        }
    };

    const updateSelectedTab = (tab: AllocatorReportTab | null) => {
        handleClose();
        setTabRedirect(null);
        setSearchParams(
            `?${new URLSearchParams({
                jobId: String(jobId),
                report: tab?.id?.toString() ?? DEFAULT_SEARCH_PARAMS.report,
                page: DEFAULT_SEARCH_PARAMS.page,
                size: DEFAULT_SEARCH_PARAMS.size,
            })}`
        );
        dispatch(
            GlobalStateActions[
                product?.productName
            ].setEmptyCorrectedAddresses()
        );
        dispatch(
            GlobalStateActions[product?.productName].setSelectedReportTab(tab)
        );
        dispatch(
            GlobalStateActions[product?.productName].setReportTabsLoading(
                true
            )
        );
    };

    const actionStay = {
        text: "Stay on this page",
        action: handleClose,
    };

    const actionLeave = {
        text: "Leave",
        action: () => updateSelectedTab(tabRedirect),
    };

    if (reportTabsLoading) return null;

    return (
        <>
            <Box className="title-container">
                <Typography variant="h2" sx={{ fontSize: 34 }}>
                    {selectedFileName}
                </Typography>
                <ReportExportButton />
            </Box>
            <div className="report-tabs-container">
                {reportTabs?.map((tab: AllocatorReportTab) => {
                    return selectedReportTab?.name !== tab?.name ? (
                        <Tooltip
                            key={tab?.id}
                            slotProps={{
                                tooltip: { sx: { maxWidth: "none" } },
                            }}
                            title={isJobFailed || isJobProcessing}
                            arrow
                        >
                            <span>
                                <StyledChipTab
                                    label={tab?.name}
                                    disabled={!!(isJobFailed || isJobProcessing)}
                                    onClick={() => handleTabClick(tab)}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <SelectedStyledChipTab
                            key={tab?.id}
                            label={tab?.name}
                        />
                    );
                })}
            </div>
            <ConfirmationDialog
                open={open}
                title={"You have unsaved changes. Leave the page?"}
                content={"If you leave the page all changes will be discarded."}
                actionPrimary={actionStay}
                actionSecondary={actionLeave}
                handleClose={handleClose}
            />
        </>
    );
};

export default ReportTabs;
