import React from "react";
import EmailIcon from "../../assets/images/toolbar-icons/messages.png";
import QuestionMarkIcon from "../../assets/images/toolbar-icons/question-mark.png";
import styled from "styled-components";
import { Product, User } from "src/types";
import { useAppSelector } from "src/hooks";
import { CustomMenu } from "src/uikit";
import { Avatar } from "@mui/material";
import SCSS_VARIABLES from "src/styles/component.scss";
import { Logout, Person, Email, Settings, School } from "@mui/icons-material";

interface RightContentProps {}

const RightContentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    & .section {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
    }

    .menu {
        max-width: 50px;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const RightContent: React.FC<RightContentProps> = (props) => {
    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value,
    );

    const user: User | undefined = useAppSelector((state) => state?.Self.value);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const showMenu = Boolean(anchorEl);

    const handleEmailClick = () => {
        if (product?.productName === "Agent")
            window.location.href = process.env.REACT_APP_AGENT_EMAIL!;
        else window.location.href = process.env.REACT_APP_STRATUS_EMAIL!;
    };

    const handleSupportClick = () => {
        if (product?.productName === "Agent")
            window.open(process.env.REACT_APP_AGENT_HELP!, "_blank");
        else window.open(process.env.REACT_APP_STRATUS_HELP!, "_blank");
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <RightContentWrapper>
                <div
                    className="section"
                    onClick={handleEmailClick}
                    data-testid="email"
                >
                    <img
                        className="icon"
                        src={EmailIcon}
                        alt="Email Icon"
                        data-testid="EmailIcon"
                        height={24}
                    />
                </div>
                <div className="section" onClick={handleSupportClick}>
                    <img
                        className="icon"
                        src={QuestionMarkIcon}
                        alt="Question Mark Icon"
                        data-testid="HelpIcon"
                        height={24}
                    />
                </div>
                <div className="section menu" onClick={handleOpenUserMenu}>
                    <Avatar
                        alt={user?.firstName}
                        sx={{
                            backgroundColor: SCSS_VARIABLES.primary,
                            color: "white",
                        }}
                    ></Avatar>
                    {user?.firstName}
                </div>
            </RightContentWrapper>
            <CustomMenu
                onClose={() => {
                    setAnchorEl(null);
                }}
                open={showMenu}
                anchorEl={anchorEl}
                items={[
                    {
                        icon: <Person />,
                        text: "Profile",
                        cb: () => {
                            console.log("Navigate to Profile Page");
                        },
                    },
                    {
                        icon: <Settings />,
                        text: "Settings",
                        cb: () => {
                            console.log("Navigate to Settings Page");
                        },
                    },
                    {
                        icon: <School />,
                        text: "Documentation",
                        cb: () => {
                            console.log("Navigate to Documentation Page");
                        },
                    },
                    {
                        icon: <Email />,
                        text: "Contact Support",
                        cb: () => {
                            console.log("Open Email Link to Contact Support");
                        },
                    },
                    {
                        icon: <Logout />,
                        text: "Logout",
                        cb: () => {
                            console.log("Logout");
                        },
                    },
                ]}
            />
        </>
    );
};

export default RightContent;
