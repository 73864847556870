import { BaseAPIService } from "./BaseApiService";
import { GraphQLClient } from "graphql-request";

export class GraphqlService {
    private static _instance: GraphqlService;
    private readonly BASE_PATH: string = "/graphql";
    private api: BaseAPIService = BaseAPIService.getInstance();
    private client: GraphQLClient;
    private initialHeaders: Record<string, string> = {
        "Access-Control-Allow-Origin": this.api.getBaseUrl(),
        "Cache-Control": "no-cache,no-store",
    }

    constructor(){
        this.client = new GraphQLClient(
            this.api.getBaseUrl() + this.BASE_PATH
        );
    }

    public async fetchData<T>(query:any, variables: any): Promise<T> {
        const headers = await this.api.getHeadersWithAuthorization(this.initialHeaders);
        this.client.setHeaders(headers);
        
        return this.client.request<T>(query, variables);
    }


    public static getInstance(): GraphqlService {
        if (!GraphqlService._instance) {
            GraphqlService._instance = new GraphqlService();
        }
        return GraphqlService._instance;
    }
}