import { Button, MenuItem, Modal, Select, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import "./AccountCsrModal.scss";

interface AccountCsrModalProps {
    open: boolean;
    onClose: () => void;
}

const AccountCsrModal: FC<AccountCsrModalProps> = ({ open, onClose }) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useAppDispatch();

    const initialFormData: FixMeLater = {
        selectedCsr: null,
    };

    const [formData, setFormData] = useState(initialFormData);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const usersWithCsrRoleId = useAppSelector(
        (state) => state?.[product?.productName].value?.usersWithCsrRoleId
    );

    const handleUserChange = (event: FixMeLater) => {
        setFormData({
            ...formData,
            selectedCsr: event.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const updatedAccount = {
            ...selectedAccount,
            customerSince: selectedAccount.customerSince,
            csrId: formData.selectedCsr?.id,
            sinceStratusDate: selectedAccount.sinceStratusDate,
        };

        try {
            setIsLoading(true);
            const response = await accountService.updateAccount(updatedAccount);
            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccount(
                    response
                )
            );

            const updatedAccounts = [...accounts];

            updatedAccounts.forEach((account, index) => {
                if (account.id === updatedAccount.id) {
                    updatedAccounts[index] = {
                        ...updatedAccount,
                    };
                }
            });

            dispatch(
                GlobalStateActions[product?.productName].setAccounts(
                    updatedAccounts
                )
            );

            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Select a CSR</Typography>

                            {usersWithCsrRoleId?.length === 0 ? (
                                <StyledNoDataFound>
                                    No CSRs found.
                                </StyledNoDataFound>
                            ) : (
                                <>
                                    <StyledForm onSubmit={handleSubmit}>
                                        <Select
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="selectedCsr"
                                            name="selectedCsr"
                                            value={formData.selectedCsr || ""}
                                            onChange={handleUserChange}
                                        >
                                            {usersWithCsrRoleId?.map(
                                                (user: FixMeLater) => (
                                                    <MenuItem
                                                        key={user.id}
                                                        value={user}
                                                    >
                                                        {user.firstName}{" "}
                                                        {user.lastName}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>

                                        <StyledFlexContainer justify="center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </StyledFlexContainer>
                                    </StyledForm>
                                </>
                            )}
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Account CSR updated successfully!"
                severity="success"
            />
        </>
    );
};

export default AccountCsrModal;
