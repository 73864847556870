import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TRITECH_ROLE } from "src/constants";
import { useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater, User } from "src/types";
import ContainerWithResizableSideBar from "../../components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import "./AccountAdmin.scss";
import AccountList from "./components/AccountList/AccountList";
import Companies from "./components/Companies/Companies";
import Groups from "./components/Groups/Groups";
import Overview from "./components/Overview/Overview";
import UserRoles from "./components/UserRoles/UserRoles";
import Users from "./components/Users/Users";
import NewAccountModal from "./modals/NewAccountModal/NewAccountModal";

interface AccountAdminProps {}

const AccountAdmin: FC<AccountAdminProps> = () => {
    const components: string[] = [
        "Account",
        "Companies",
        "Users",
        "Groups",
        "User Roles",
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const self: User | undefined = useAppSelector(
        (state) => state?.Self?.value,
    );

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );

    const selectedComponent = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedComponent,
    );

    const [newAccountModalOpen, setNewAccountModalOpen] =
        useState<boolean>(false);

    const sidebarRef = useRef<FixMeLater>(null);

    const [collapsed, setCollapsed] = useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(
            GlobalStateActions[product?.productName].setSelectedComponent(
                newValue,
            ),
        );
    };

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            if (entries && entries.length > 0) {
                const componentWidth = entries[0].contentRect.width;
                setCollapsed(componentWidth < 140);
            }
        });
        if (sidebarRef.current) {
            observer.observe(sidebarRef.current.parentElement);
        }
        return () => {
            observer.disconnect();
        };
    }, [sidebarRef]);

    const SideBarContent = () => {
        return (
            <div
                className={`account-admin-sidebar ${collapsed ? "account-admin-sidebar--collapsed" : ""}`}
                ref={sidebarRef}
            >
                <div className="title">ACCOUNTS</div>
                <Button
                    className="button"
                    onClick={() => setNewAccountModalOpen(true)}
                    variant="contained"
                >
                    New Account
                </Button>
                <AccountList />
            </div>
        );
    };

    const content = (
        <div className="account-admin-content">
            <TabContext value={selectedComponent}>
                <TabList onChange={handleChange} className="tab-list">
                    {components.map((component, idx) => {
                        return (
                            <Tab
                                className="tab"
                                key={`${idx}-${component}`}
                                label={component}
                                value={component}
                            />
                        );
                    })}
                </TabList>
                <TabPanel value="Account" className="tab-panel">
                    <Overview />
                </TabPanel>
                <TabPanel value="Companies">
                    <Companies />
                </TabPanel>
                <TabPanel value="Users">
                    <Users />
                </TabPanel>
                <TabPanel value="Groups">
                    <Groups />
                </TabPanel>
                <TabPanel value="User Roles">
                    <UserRoles />
                </TabPanel>
            </TabContext>
        </div>
    );

    if (
        !self ||
        !(
            self.roles?.includes(TRITECH_ROLE.TritechAdmin) ||
            self.roles?.includes(TRITECH_ROLE.ClientAccountAdmin)
        )
    )
        navigate("/not-authorized");

    return (
        <>
            <div className="account-admin-container">
                <ContainerWithResizableSideBar
                    sideBarContent={<SideBarContent />}
                    content={content}
                    collapsedWidth={15}
                    // resizeEnabled={false}
                />
            </div>
            <NewAccountModal
                open={newAccountModalOpen}
                onClose={() => {
                    setNewAccountModalOpen(false);
                }}
            />
        </>
    );
};

export default AccountAdmin;
