// SelectFields.tsx
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import DualList from "src/components/DualList/DualList";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./SelectFields.scss";

interface Item {
    id: string;
    name: string;
}

const SelectFields: FC = () => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );
    const fieldsToExport = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport?.payload
                ?.fieldsToExport
    );

    const [leftItems, setLeftItems] = useState<readonly Item[]>([]);
    const [rightItems, setRightItems] = useState<readonly Item[]>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fieldsToExportSet = new Set(fieldsToExport);

        const right: Item[] = [];
        const left: Item[] = [];

        paymentRequestExport?.scheduleTemplateDTO?.columnTemplateDTOs?.forEach(
            (column) => {
                if (fieldsToExportSet.has(column?.index)) {
                    right.push({
                        id: column?.index,
                        name: column?.columnTemplate?.name,
                    });
                } else
                    left.push({
                        id: column?.index,
                        name: column?.columnTemplate?.name,
                    });
            }
        ) || [];
        setRightItems(right);
        setLeftItems(left);
    }, []);

    const handleLeftChange = (newLeft: readonly Item[]) => {
        setLeftItems(newLeft);
    };

    const handleRightChange = (newRight: readonly Item[]) => {
        setRightItems(newRight);

        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    fieldsToExport: newRight?.map((item) => item?.id),
                },
            })
        );
    };

    const handleCheckboxChange = () => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    includeHeader:
                        !paymentRequestExport?.payload?.includeHeader,
                },
            })
        );
    };

    return (
        <div className="select-fields-container">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={
                            paymentRequestExport?.payload?.includeHeader ||
                            false
                        }
                        onChange={handleCheckboxChange}
                    />
                }
                label="Include field names in the first line of the export file"
            />

            <div className="dual-list-container">
                <DualList
                    leftItems={leftItems}
                    rightItems={rightItems}
                    onLeftChange={handleLeftChange}
                    onRightChange={handleRightChange}
                    leftSearchLabel="Search available fields"
                    rightSearchLabel="Search fields to export"
                />
            </div>
        </div>
    );
};

export default SelectFields;
