import { Menu, Typography } from "@mui/material";
import React, { FC } from "react";
import { formatDateTime } from "src/services/Utility";
import { AllocatorJob, ContextMenuPosition } from "src/types";
import "./JobInfoMenu.scss";

interface JobInfoMenuProps {
    job: AllocatorJob;
    position: ContextMenuPosition | null;
    handleCloseJobInfoMenu: () => void;
}

const JobInfoMenu: FC<JobInfoMenuProps> = ({
    job,
    position,
    handleCloseJobInfoMenu,
}) => {
    const getDateOrDash = (date: string) => {
        return date ? formatDateTime(date) : "-";
    };

    return (
        <Menu
            open={position !== null}
            onClose={handleCloseJobInfoMenu}
            anchorReference="anchorPosition"
            anchorPosition={
                position !== null
                    ? { top: position?.mouseY, left: position?.mouseX }
                    : undefined
            }
        >
            <div className="job-info-container">
                <Typography>
                    Completion Date: {getDateOrDash(job?.completionDate)}
                </Typography>
                <Typography>Created by: {job?.createdBy}</Typography>
                <Typography>
                    Approved: {getDateOrDash(job?.approvedDate)}
                </Typography>
            </div>
        </Menu>
    );
};

export default JobInfoMenu;
