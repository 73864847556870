import { Card } from "@mui/material";
import styled from "styled-components";

export const StyledFormContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

export const StyledResultGroup = styled.div`
    margin-top: 32px;
    width: 100%;
`;

export const StyledResultSubheader = styled.div`
    font-weight: bold;
`;

export const StyledResult = styled.div`
    font-style: italic;
    margin-top: 12px;
    width: 100%;
`;

export const StyledCard = styled(Card)`
    flex: 1;
    margin: 10px 10px;
    padding: 16px;
    overflow: hidden;
`;
