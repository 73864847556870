import { FixMeLater } from "src/types";

export const processCompanyProducts = (data: FixMeLater[]) => {
    const groupedData: { [key: string]: FixMeLater } = {};

    data.forEach((item) => {
        const key = `${item.companyId}-${item.productId}-${item.year}`;

        if (!groupedData[key]) {
            groupedData[key] = {
                productId: item.productId,
                year: item.year,
                jurisdictionDTOs: [],
            };
        }

        groupedData[key].jurisdictionDTOs.push({
            id: item.jurisdictionDTO.id,
            longName: item.jurisdictionDTO.longName,
            abbrev: item.jurisdictionDTO.abbrev,
        });
    });

    return Object.values(groupedData);
};
