import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OptinsService } from "src/services/OptinsService";
import { FixMeLater } from "src/types";
import { OptinsCompareResponse } from "src/types/OptinsService.types";
import { downloadFile } from "src/utils";
import ContainerWithResizableSideBar from "../ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import Loader from "../Loader/Loader";
import "./OptinsExport.scss";
import {
    StyledExportButton,
    StyledExportButtonText,
    StyledModalContainer,
} from "./OptinsExport.styled";
import Compare from "./components/Compare/Compare";
import Instructions from "./components/Instructions/Instructions";
import Menu from "./components/Menu/Menu";

const OptinsExport = ({ open, onClose, payload }) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const [optinsExport, setOptinsExport] = useState<FixMeLater>({
        selectedTab: "Instructions",
        compare: null,
    });

    const optinsService = OptinsService.getInstance();

    useEffect(() => {
        setIsFetching(true);

        const fetchData = async () => {
            try {
                const compare: OptinsCompareResponse[] =
                    await optinsService.compare(payload);

                setOptinsExport({ ...optinsExport, compare });
            } catch (error: FixMeLater) {
                handleSnackbar(
                    `There has been an error fetching data. ${error?.message}`,
                    "error"
                );
            } finally {
                setIsFetching(false);
            }
        };

        if (open) {
            fetchData();
        }
    }, [open]);

    const handleExport = async () => {
        setIsLoading(true);
        try {
            const response = await optinsService.export(payload);

            let fileName = "OPTinsExport.xlsx";
            const contentDispositionHeader = response.headers.get(
                "Content-Disposition"
            );

            if (contentDispositionHeader) {
                const match =
                    contentDispositionHeader.match(/filename=([^;]+)/);
                fileName = match?.[1] ?? fileName;
            }

            const blob = new Blob([await response.blob()], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            downloadFile(blob, fileName);
            onClose();
            handleSnackbar(
                `Optins: ${fileName} downloaded successfully`,
                "success"
            );
        } catch (error: FixMeLater) {
            console.error("Error exporting data:", error);
            handleSnackbar(
                `There has been an error exporting the data. ${
                    error?.message || error
                }`,
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const getContent = () => {
        const selectedTab = optinsExport?.selectedTab;
        if (selectedTab === "Instructions") {
            return <Instructions />;
        } else if (selectedTab === "Compare") {
            return <Compare />;
        } else {
            return <div>Please select a tab.</div>;
        }
    };

    const content = (
        <>
            <div className="content-container">{getContent()}</div>
            <div>
                <StyledExportButton>
                    <StyledExportButtonText
                        variant="contained"
                        onClick={handleExport}
                    >
                        Export
                    </StyledExportButtonText>
                </StyledExportButton>
            </div>
        </>
    );

    return (
        <>
            <div className="optins-export-container">
                <StyledModalContainer>
                    {isFetching || isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <ContainerWithResizableSideBar
                                sideBarContent={
                                    <Menu
                                        optinsExport={optinsExport}
                                        setOptinsExport={setOptinsExport}
                                    />
                                }
                                content={content}
                                initialWidth={160}
                                resizeEnabled={false}
                                toolbar={undefined}
                            />
                        </>
                    )}
                </StyledModalContainer>
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );
};

export default OptinsExport;
