import { BaseAPIService } from "src/services/BaseApiService";
import {
    ImportStatusResponse,
    MigrationFile,
    MigrationFileKey,
    MigrationFileResponse,
} from "src/types/Migration.types";

export class MigrationService {
    private static _instance: MigrationService;
    private readonly BASE_PATH: string = "/api";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public async getImportMetadata(accountId?: string): Promise<Response> {
        return this.api.get<MigrationFile[]>(
            this.getPath(`/return-document/migration-metadata${accountId?"?accountId="+accountId:""}`),
            {},
            false
        );
    }

    public async uploadMigrationFile(
        formData: FormData
    ): Promise<MigrationFileResponse> {
        return this.api.post<MigrationFileResponse>(
            this.getPath("/return-document/migration-upload"),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
    }

    public async confirmMigrationFile(key: MigrationFileKey): Promise<boolean> {
        const formData = new FormData();
        formData.append("companyId", key.companyId.toString());
        formData.append("productId", key.productId.toString());
        formData.append("taxYear", key.taxYear.toString());
        formData.append("estimateAnnual", key.estimateAnnual.toString());
        formData.append("isConfirmed", "true");
        return this.api.post<boolean>(
            this.getPath("/return-document/migration-upload-confirm"),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
    }

    public async importMigrationFile(key: MigrationFileKey): Promise<ImportStatusResponse> {
        const formData = new FormData();
        formData.append("fileIdentifier", key.fileIdentifier!!);
        formData.append("companyId", key.companyId.toString());
        formData.append("productId", key.productId.toString());
        formData.append("taxYear", key.taxYear.toString());
        formData.append("estimateAnnual", key.estimateAnnual.toString());
        return this.api.post<ImportStatusResponse>(
            this.getPath("/return-document/migration-import"),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
    }

    public async cancelMigrationFile(key: MigrationFileKey): Promise<boolean> {
        const formData = new FormData();
        formData.append("companyId", key.companyId.toString());
        formData.append("productId", key.productId.toString());
        formData.append("taxYear", key.taxYear.toString());
        formData.append("estimateAnnual", key.estimateAnnual.toString());
        formData.append("isConfirmed", "false");
        return this.api.post<boolean>(
            this.getPath("/return-document/migration-upload-confirm"),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
    }

    public async downloadMigrationFile(
        key: MigrationFileKey
    ): Promise<Response> {
        return this.api.get<any>(
            this.getPath(
                `/return-document/migration-download?fileIdentifier=${key.fileIdentifier}&companyId=${key.companyId}&productId=${key.productId}&taxYear=${key.taxYear}`
            ),
            {},
            true
        );
    }

    public static getInstance(): MigrationService {
        if (!MigrationService._instance) {
            MigrationService._instance = new MigrationService();
        }
        return MigrationService._instance;
    }
}
