import { ExportSchedulePayload, MimeType, ScheduleRows } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class ScheduleService {
    private static _instance: ScheduleService;
    private readonly BASE_PATH: string = "/api/schedule";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public static getInstance(): ScheduleService {
        if (!ScheduleService._instance) {
            ScheduleService._instance = new ScheduleService();
        }
        return ScheduleService._instance;
    }

    rowsUpdate (schedule: ScheduleRows): Promise<ScheduleRows>{
        return this.api.post<ScheduleRows>(this.getPath("/update/rows"), schedule);
    }

    async getExportPaymentRequestCSV(payload: ExportSchedulePayload): Promise<Blob> {
        const response = await this.api.post(
            this.getPath("/export/payment-request-schedule"),
            payload,
            {
                headers: {
                    Accept: MimeType.APP_TEXT,
                },
            },
            true
        );

        const responseBlob = await response.blob();
        const csvBlob = new Blob([responseBlob], {
            type: MimeType.CSV,
        });
        return csvBlob;
    }

    uploadPremiumData(data: FormData): Promise<string> {
        return this.api.post<string>(this.getPath("/import"), data, {
            headers: {
                "Content-Type": MimeType.MULTIPART_FORM_DATA
            }
        });
    }
}
