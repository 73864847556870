import { Table, TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyService } from "src/services";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater, User } from "src/types";
import {
    StyledNoDataFound,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import "./Users.scss";
import { FrameCard } from "src/uikit";

const Users: React.FC = () => {
    const companyService = CompanyService.getInstance();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountCompany,
    );
    const selectedAccountCompanyUsers = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.selectedAccountCompanyUsers,
    );

    const usersMap = new Map<string, User>(
        selectedAccountCompanyUsers.map((user) => [user.id, user]),
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountCompanyUsers = async () => {
            if (selectedAccountCompany) {
                setIsLoading(true);
                try {
                    const selectedAccountCompanyUsers =
                        await companyService.getCompanyUsers(
                            selectedAccountCompany.id,
                        );
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompanyUsers(
                            selectedAccountCompanyUsers,
                        ),
                    );
                } catch (error) {
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountCompanyUsers([]),
                );
            }
        };

        fetchSelectedAccountCompanyUsers();
    }, [selectedAccountCompany]);

    const noDataFoundMessage = selectedAccountCompany
        ? "This company is not currently associated with any users."
        : "Please select a company.";

    if (error)
        return (
            <ErrorMessage error={`Error fetching users: ${error.toString()}`} />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="companies-users-container">
            <FrameCard title="COMPANY USERS" actions={[]}>
                {usersMap.size === 0 ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <StyledTableContainer $maxHeight={"400px"}>
                        <Table>
                            <TableBody>
                                {Array.from(usersMap.values()).map((user) => (
                                    <StyledTableRow
                                        key={user.id}
                                        $isSelected={false}
                                    >
                                        <StyledTableCell align="left">
                                            {renderField(
                                                "Name",
                                                getStringValueOrDefault(
                                                    user?.firstName,
                                                ),
                                            )}
                                            {renderField(
                                                "Email",
                                                getStringValueOrDefault(
                                                    user?.email,
                                                ),
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                )}
            </FrameCard>
        </div>
    );
};

export default Users;
