import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company } from "src/types";

interface CompaniesState {
    value: Company[] 
}

const initialState: CompaniesState = {
    value: [],
};

export const CompaniesSlice = createSlice({
    name: "Companies",
    initialState,
    reducers: {
        setCompanies: (state, action: PayloadAction<Company[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setCompanies } = CompaniesSlice.actions;
export default CompaniesSlice.reducer;
