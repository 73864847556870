import React, { FC } from "react";
import "./TabPanel.scss";
import { StyledTabPanel } from "./TabPanel.styled";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <StyledTabPanel
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {children}
        </StyledTabPanel>
    );
};

export default TabPanel;
