import { Paper } from "@mui/material";
import { FixMeLater } from "src/types";
import styled from "styled-components";

export const StyledPaper: FixMeLater = styled(Paper)`
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
    height: 400px;
    margin: 8px;
    overflow: scroll;
    padding: 32px;
`;
