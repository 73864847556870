import React, { FC } from "react";
import "./Municipal.scss";
import ContainerWithResizableSideBar from "src/components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import ReturnTreeViewer from "src/components/ReturnTreeViewer/ReturnTreeViewer";
import MultiTabContainer from "src/components/MultiTabContainer/MultiTabContainer";

const Municipal: FC = () => {
    const getDocument = () => {
        return <MultiTabContainer />;
    };

    const getContent = () => (
        <div className="municipal-document-container">
            <div className="municipal-document">{getDocument()}</div>
        </div>
    );

    return (
        <div className="municipal-container">
            <ContainerWithResizableSideBar
                sideBarContent={<ReturnTreeViewer />}
                content={getContent()}
            />
        </div>
    );
};

export default Municipal;
