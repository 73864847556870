import React, { FC, MouseEvent, PropsWithChildren, useRef } from "react";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box, ClickAwayListener, IconButton, Slide } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";
import { StyledToolbarQuickFilter } from "src/products/Allocator/Allocator.styled";
import { StyledJobListToolbar } from "./JobListToolbar.styled";
import colors from "src/styles/colors.scss";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface JobListToolbar {
    isInputSearchFilled?: boolean;
    isDragActive?: boolean;
}

const JobListToolbar: FC<PropsWithChildren<JobListToolbar>> = ({ isInputSearchFilled, isDragActive }) => {
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const isJobSearchActive: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.isJobSearchActive
    );

    const containerRef = useRef<HTMLElement>(null);
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const handleSearchActive = (e: MouseEvent) => {
        e.stopPropagation();
        dispatch(
            GlobalStateActions[product?.productName].setIsJobSearchActive(true)
        );
    };

    const handleClickAway = () => {
        if (!isInputSearchFilled) {
            dispatch(
                GlobalStateActions[product?.productName].setIsJobSearchActive(false)
            );
        }
    };

    return (
        <StyledJobListToolbar $isDragActive={isDragActive} $isSearchActive={isJobSearchActive}>
            <Box ref={containerRef}>
                <GridToolbarFilterButton slotProps={{ button: { sx: {marginRight: "10px"} } }}/>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Slide in={isJobSearchActive} direction="left" container={containerRef.current}>
                        {/* Wrapping element with div to avoid ref error */}
                        <div ref={ref}>
                            <StyledToolbarQuickFilter sx={{ width: isJobSearchActive? "100%" : "0px"}}/>
                        </div>
                    </Slide>
                </ClickAwayListener>
                {
                    !isJobSearchActive && (
                        <IconButton sx={{ padding: "6px" }} onClick={handleSearchActive}>
                            <Search sx={{ color: colors.primaryColor }}></Search>
                        </IconButton>
                    )
                }
            </Box>
        </StyledJobListToolbar>
    );
};

export default JobListToolbar;
