export const MunicipalStates = [
    "Alabama",
    "Florida",
    "Georgia",
    "Illinois",
    "Kentucky",
    "Louisiana",
    "North Dakota",
    "New Jersey",
    "New York",
    "South Carolina",
];

export const MunicipalStatesAbbr = [
    "AL",
    "FL",
    "GA",
    "IL",
    "KY",
    "LA",
    "ND",
    "NJ",
    "NY",
    "SC",
];