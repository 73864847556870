import React, { FC, MouseEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Download } from "@mui/icons-material";
import colors from "src/styles/colors.scss";
import { useSnackbar } from "notistack";
import { AllocatorService } from "src/services";
import { ALLOCATOR_SEARCH_PARAMS, AllocatorReportExportOption, AllocatorReportTab, FixMeLater } from "src/types";
import { useAppSelector } from "src/hooks";
import { downloadFile } from "src/utils";

const ReportDownloadButton: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const selectedReportTab: AllocatorReportTab = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedReportTab
    );
    const downloadOptions: AllocatorReportExportOption[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.downloadOptions
    );
    const reportColumns: string[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportColumns
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (downloadOptions?.length) {
            setAnchorEl(event?.currentTarget);
        } else downloadReport();
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleDownloadReport = (event: MouseEvent<HTMLElement>) => {
        handleCloseMenu(event);
        downloadReport(event?.currentTarget?.dataset?.excludeColumn);
    };

    const downloadReport = async (excludeColumn?: string) => {
        const filteredColumns = reportColumns?.filter((column: string) => column !== excludeColumn);

        try {
            const reportDownloadable = await allocatorService.generateCsvReport(
                selectedReportTab?.id,
                {
                    jobId: Number(jobId),
                    columns: filteredColumns,
                },
            );
            const { blob, contentDispositionHeader } = reportDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Downloaded successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download report", { variant: "error" });
        }
    };

    return (
        <>
            <IconButton sx={{ padding: "7px" }} onClick={handleClickOpen}>
                <Download sx={{ color: colors.primaryColor }}></Download>
            </IconButton>
            {(!!downloadOptions?.length) && (
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleCloseMenu}
                    onContextMenu={handleCloseMenu}
                >
                    {downloadOptions?.map(
                        (option: AllocatorReportExportOption, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={handleDownloadReport}
                                    data-exclude-column={option.excludeColumn}
                                >
                                    {option.displayName}
                                </MenuItem>
                            );
                        }
                    )}
                </Menu>
            )}
        </>
    );
};

export default ReportDownloadButton;
