import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { emailRegex, phoneRegex } from "src/constants/Regex";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./AccountContactModal.scss";

interface AccountContactModalProps {
    open: boolean;
    onClose: () => void;
}

const AccountContactModal: FC<AccountContactModalProps> = ({
    open,
    onClose,
}) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);

    const selectedAccount = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccount
    );

    const initialFormData = {
        name: selectedAccount?.clientContactDTO?.clientContactName || "",
        phoneNumber:
            selectedAccount?.clientContactDTO?.clientContactPhone || "",
        email: selectedAccount?.clientContactDTO?.clientContactEmail || "",
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
    }, [selectedAccount]);

    const [validationErrors, setValidationErrors] = useState({
        name: "",
        phoneNumber: "",
        email: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === "name" && value.length > 65) {
            setValidationErrors({
                ...validationErrors,
                name: "Name must be at most 65 characters.",
            });
        } else if (name === "phoneNumber" && !phoneRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                phoneNumber:
                    "Phone Number must be 10 digits and contain no special characters",
            });
        } else if (name === "email" && !emailRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                email: "Email address is invalid.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedAccount = {
            ...selectedAccount,
            sinceStratusDate: getDate(selectedAccount.sinceStratusDate),
            customerSince: getDate(selectedAccount.customerSince),
        };

        updatedAccount.clientContactDTO = {
            ...updatedAccount.clientContactDTO,
            clientContactName: formData.name,
            clientContactPhone: formData.phoneNumber,
            clientContactEmail: formData.email,
        };

        try {
            setIsLoading(true);
            const account = await accountService.updateAccount(updatedAccount);

            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccount(
                    account
                )
            );
            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            console.error(error);
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    const getDate = (date: string | null | undefined) =>
        date !== null && date !== undefined && date !== "" ? date : "";

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">
                                Edit Account Contact
                            </Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.name}
                                    helperText={validationErrors.name}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.phoneNumber}
                                    helperText={validationErrors.phoneNumber}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.email}
                                    helperText={validationErrors.email}
                                />

                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.name ||
                                            !!validationErrors.phoneNumber ||
                                            !!validationErrors.email
                                        }
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Account contact updated successfully!"
                severity="success"
            />
        </>
    );
};

export default AccountContactModal;
