import React, { useEffect, useState } from "react";
import Migration from "./Migration";
import { Account, Company, Product } from "src/types";
import { useAppSelector } from "src/hooks";
import { AccountService } from "src/services";
import { MIGRATION_FILE_STATUS, MigrationFile } from "src/types/Migration.types";
import { MigrationService } from "src/services/MigrationService";
import { FrameCard } from "src/uikit";

const MigrationWidget = () => {
    const[isOpen, setIsOpen] = useState(false);
    const selectedAccountProducts: Product[] = useAppSelector(
        (state) => state["Account Admin"]?.value?.selectedAccountProducts
    );
    const selectedAccount: Account | undefined = useAppSelector(
        (state) => state["Account Admin"]?.value?.selectedAccount
    );
    const accountService = AccountService.getInstance();
    const [filesImportedSize, setfilesImportedSize] = useState<number>(0);
    const [filesImportedErrorsSize, setfilesImportedErrorsSize] = useState<number>(0);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<MigrationFile[]>([]);
    const migrationService = MigrationService.getInstance();

    const loadFileMigrationList = async (accountId: string) => {
        const uploadedFiles: MigrationFile[] =
        (await migrationService.getImportMetadata(accountId)) as unknown as MigrationFile[];
        setUploadedFiles(uploadedFiles);
        setfilesImportedSize(
            uploadedFiles.filter(file => file.importStatus === MIGRATION_FILE_STATUS.DONE).length
        );
        setfilesImportedErrorsSize(
            uploadedFiles.filter(file => file.importStatus === MIGRATION_FILE_STATUS.ERROR).length
        );
    }

    useEffect(()=>{
        const fetchCompanies = async ()=> {
            if(selectedAccount){
                const companies: Company[] = await accountService.getAccountCompanies(
                    selectedAccount.id!!
                );
                setCompanies(companies);
                loadFileMigrationList(selectedAccount.id!!);
            }
        }
        fetchCompanies();
    }, [selectedAccount]);
    return (
        <FrameCard title="ACCOUNT MIGRATION" actions={[
            {
                text: "Migration Files",
                onClick: () => {
                    setIsOpen(true);
                }
            }
        ]}>
            <div>
                Migration status:
                <br/><br/>
                Companies: <b>{companies.length}</b>
                <br/>
                Files Uploaded: <b>{uploadedFiles.length}</b>
                <br/>
                Files Imported: <b>{filesImportedSize}</b>
                <br/>
                Import Errors: <b>{filesImportedErrorsSize}</b>
            </div>
            <Migration 
                onClose={()=>setIsOpen(false)} 
                open={isOpen} 
                adminView = {true}
                companies = { companies }
                products={selectedAccountProducts}
                accountId={selectedAccount?.id}
                accountName={selectedAccount?.name}
                uploadedFiles={uploadedFiles}
                refreshUploadedFiles={loadFileMigrationList}
                />
        </FrameCard>
    );
};

export default MigrationWidget;
