import styled from "styled-components";
import { Accordion, AccordionDetails } from "@mui/material";

export const StyledAccordion = styled(Accordion)`
    .MuiButtonBase-root{
        min-height: 40px
    }

    .MuiAccordionSummary-root{
        border-bottom: 1px solid rgba(255, 255, 255, .08);
        &:hover {
            background-color: #556785;
        }    
    }

    .MuiCollapse-entered {
        background-color: #354560;
        border-bottom: 1px solid rgba(255, 255, 255, .08);
    }

    &.MuiAccordion-root {
        border-radius: 4px;
        cursor: pointer;
        box-shadow: none;
    }

    &.Mui-expanded {
        margin: 0px !important;
    }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    &.MuiAccordionDetails-root {
        font-size: 14px;
        overflow: hidden;
        padding: 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
