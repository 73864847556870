import {
    Autocomplete,
    Button,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { CompanySubTypes } from "src/constants/CompanySubTypes";
import {
    federalIdRegex,
    naicGroupRegex,
    naicRegex,
    zipRegex,
} from "src/constants/Regex";
import { USStateAbbreviations } from "src/constants/States";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyService } from "src/services";
import { Company, FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./AccountCompaniesModal.scss";

interface AccountCompaniesModalProps {
    open: boolean;
    onClose: () => void;
}

const AccountCompaniesModal: FC<AccountCompaniesModalProps> = ({
    open,
    onClose,
}) => {
    const companyService = CompanyService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountCompanies,
    );
    const selectedAccountSubWithCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value
                ?.selectedAccountSubWithCompanies,
    );
    const [subAccountListWithEmpty, setSubAccountListWithEmpty] =
        useState<FixMeLater>([]);

    useEffect(() => {
        setSubAccountListWithEmpty([
            {
                id: "",
                name: "N/A",
                parentId: "",
            },
            ...selectedAccountSubWithCompanies,
        ]);
    }, [selectedAccountSubWithCompanies]);

    const dispatch = useAppDispatch();

    const initialFormData = {
        active: true,
        address1: "",
        address2: "",
        addressCity: "",
        addressState: "",
        addressZip: "",
        coabbr: "",
        federalId: "",
        naic: "",
        naicGroup: "",
        name: "",
        stateOfDomicile: "",
        companySubType: "",
        subAccountId: "",
    };

    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({
        naic: "",
        naicGroup: "",
        federalId: "",
        addressZip: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "naic" && !naicRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                naic: "NAIC should be 5 digits.",
            });
        } else if (name === "naicGroup" && !naicGroupRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                naicGroup: "NAIC Group should be 4 digits.",
            });
        } else if (name === "federalId" && !federalIdRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                federalId: "FEIN should be 9 digits.",
            });
        } else if (name === "addressZip" && !zipRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                addressZip: "Zip Code should be a 5-digit number.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            const newCompany = {
                // This object should be Company type when migrated to TypeScript
                accountId: selectedAccount?.id,
                ...formData,
                federalId: formData.federalId.replace("-", ""), // Remove hyphen from FEIN for consistency
            };

            const newCompanyResponse = await companyService.createCompany(
                newCompany as unknown as Company,
            );

            if (newCompanyResponse) {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ]?.setSelectedAccountCompany(newCompanyResponse),
                );

                const updatedSelectedAccountCompanies = [
                    ...selectedAccountCompanies,
                    newCompanyResponse,
                ];

                updatedSelectedAccountCompanies.sort((a, b) =>
                    a?.name?.localeCompare(b?.name),
                );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ]?.setSelectedAccountCompanies(
                        updatedSelectedAccountCompanies,
                    ),
                );

                onClose();
                setFormData(initialFormData);
                setSnackbarOpen(true);
            }
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Add Company</Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Company Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="coabbr"
                                        label="Abbreviation"
                                        name="coabbr"
                                        value={formData.coabbr}
                                        onChange={handleInputChange}
                                    />
                                </StyledFlexContainer>

                                <Autocomplete
                                    id="subAccountId"
                                    options={subAccountListWithEmpty}
                                    getOptionLabel={(option: FixMeLater) =>
                                        option.name
                                    }
                                    value={
                                        subAccountListWithEmpty.find(
                                            (option) =>
                                                option.id ===
                                                formData.subAccountId,
                                        ) || ""
                                    }
                                    onChange={(
                                        event: any,
                                        newValue: FixMeLater | null,
                                    ) => {
                                        handleInputChange({
                                            target: {
                                                name: "subAccountId",
                                                value: newValue?.id || "",
                                            },
                                        } as React.ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="SubAccount"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    fullWidth
                                />

                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="federalId"
                                        label="Federal ID"
                                        name="federalId"
                                        value={formData.federalId}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.federalId}
                                        helperText={validationErrors.federalId}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="naic"
                                        label="NAIC"
                                        name="naic"
                                        value={formData.naic}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.naic}
                                        helperText={validationErrors.naic}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="naicGroup"
                                        label="NAIC Group"
                                        name="naicGroup"
                                        value={formData.naicGroup}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.naicGroup}
                                        helperText={validationErrors.naicGroup}
                                    />
                                </StyledFlexContainer>

                                <StyledFlexContainer>
                                    <Autocomplete
                                        id="stateOfDomicile"
                                        options={USStateAbbreviations}
                                        value={formData.stateOfDomicile}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "stateOfDomicile",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State of Domicile"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                    />
                                    <Autocomplete
                                        id="companySubType"
                                        options={CompanySubTypes}
                                        value={formData.companySubType}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "companySubType",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Company Sub-Type"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                    />
                                </StyledFlexContainer>

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="address1"
                                    label="Address1"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="address2"
                                    label="Address2"
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleInputChange}
                                />
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="addressCity"
                                        label="City"
                                        name="addressCity"
                                        value={formData.addressCity}
                                        onChange={handleInputChange}
                                    />
                                    <Autocomplete
                                        id="addressState"
                                        options={USStateAbbreviations}
                                        value={formData.addressState}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "addressState",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                    />

                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="addressZip"
                                        label="Zip Code"
                                        name="addressZip"
                                        value={formData.addressZip}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.addressZip}
                                        helperText={validationErrors.addressZip}
                                    />
                                </StyledFlexContainer>
                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.naic ||
                                            !!validationErrors.naicGroup ||
                                            !!validationErrors.federalId ||
                                            !!validationErrors.addressZip
                                        }
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Company created successfully!"
                severity="success"
            />
        </>
    );
};

export default AccountCompaniesModal;
