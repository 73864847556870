import styled from "styled-components";
import { StyledDataGrid } from "../../Allocator.styled";
import colors from "src/styles/colors.scss";

export const StyledJobList = styled(StyledDataGrid)`
    .MuiDataGrid-columnHeaders {
        visibility: hidden;
        border: none;
    }

    .MuiDataGrid-row {
        background-color: ${colors.white} !important;
    }

    .MuiDataGrid-row:hover {
        background-color: transparent;
    }

    .MuiDataGrid-row:hover {
        background-color: transparent;
    }

    .MuiDataGrid-cell {
        padding: 0;
    }
`;
