export const validateAttachmentFile = (file: File) => {

    const MB_SIZE = 1048576 // Bytes
    const MAX_MB = 100 // MB
    const ALLOWED_EXTENSIONS = ['csv', 'txt', 'pdf', 'doc', 'docx', 'jpg', 'jpeg', 'xls', 'xlsx']

    if (!file) {
      return false
    }

    if (file.size > MAX_MB * MB_SIZE) {
      return false
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase()

    if (!fileExtension || !ALLOWED_EXTENSIONS.includes(fileExtension)) {
      return false
    }

    return true
  }