// General.tsx
import { Button, Checkbox, FormControlLabel, Radio } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./General.scss";

const General: FC = () => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );

    const dispatch = useAppDispatch();

    const handlePaymentTypeChange = (name: string) => {
        const updatedPaymentTypes =
            paymentRequestExport?.payload?.paymentTypes?.includes(name)
                ? paymentRequestExport?.payload?.paymentTypes?.filter(
                      (type) => type !== name
                  )
                : [
                      ...(paymentRequestExport?.payload?.paymentTypes || []),
                      name,
                  ];

        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    paymentTypes: updatedPaymentTypes,
                },
            })
        );
    };

    const handleDateChange = (date: FixMeLater, dateType: string) => {
        const startDate = date?.[0]?.toISOString()?.split("T")?.[0] || "";
        const endDate = date?.[1]?.toISOString()?.split("T")?.[0] || "";

        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    [dateType]: startDate,
                    [dateType === "minDueDate"
                        ? "maxDueDate"
                        : "maxApproveDate"]: endDate,
                },
            })
        );
    };

    const handleRadioChange = (isDueDateRange: boolean) => {
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    dueDateRange: isDueDateRange,
                },
            })
        );
    };

    return (
        <div className="general-container">
            <div className="header">Export Options</div>
            <hr />
            <div>
                <span className="subheader">Payment Type:</span>
                {["Check", "ACH Credit", "ACH Debit", "Credit Card"].map(
                    (paymentType) => (
                        <FormControlLabel
                            key={paymentType}
                            control={
                                <Checkbox
                                    onChange={() =>
                                        handlePaymentTypeChange(paymentType)
                                    }
                                    checked={paymentRequestExport?.payload.paymentTypes?.includes(
                                        paymentType
                                    )}
                                />
                            }
                            label={paymentType}
                        />
                    )
                )}
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => {
                            console.log(
                                "Append exported record to an existing file."
                            );
                        }}
                    />
                }
                label="Append exported record to an existing file."
            />

            <hr />

            <div className="header">File Options</div>
            <div className="file-uploader">
                <Button variant="contained" component="label">
                    Upload File
                    <input type="file" hidden />
                </Button>
            </div>

            <div>
                <div>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={
                                    paymentRequestExport?.payload
                                        ?.dueDateRange || false
                                }
                                onChange={() => handleRadioChange(true)}
                            />
                        }
                        label="Due Date Range"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            defaultValue={[
                                dayjs(
                                    paymentRequestExport?.payload?.minDueDate ||
                                        undefined
                                ),
                                dayjs(
                                    paymentRequestExport?.payload?.maxDueDate ||
                                        undefined
                                ),
                            ]}
                            localeText={{ start: "From", end: "To" }}
                            onChange={(date) =>
                                handleDateChange(date, "minDueDate")
                            }
                        />
                    </LocalizationProvider>
                </div>

                <div>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={
                                    !paymentRequestExport?.payload?.dueDateRange
                                }
                                onChange={() => handleRadioChange(false)}
                            />
                        }
                        label="Approval Date Range"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            defaultValue={[
                                dayjs(
                                    paymentRequestExport?.payload
                                        ?.minApproveDate || undefined
                                ),
                                dayjs(
                                    paymentRequestExport?.payload
                                        ?.maxApproveDate || undefined
                                ),
                            ]}
                            localeText={{ start: "From", end: "To" }}
                            onChange={(date) =>
                                handleDateChange(date, "minApproveDate")
                            }
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    );
};

export default General;
