import { JurisdictionStatus, ReturnDocumentId } from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { getParamsString, getQueryParams } from "src/utils";

export class JurisdictionVersionService {
    private static _instance: JurisdictionVersionService;
    private readonly BASE_PATH: string = "/api/jurisdiction";
    private api: BaseAPIService = BaseAPIService.getInstance();

    private constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getJurisdictionUpdateStatus(
        id: Partial<ReturnDocumentId>
    ): Promise<Array<JurisdictionStatus>> {
        return this.api.get<Array<JurisdictionStatus>>(
            this.getPath(
                `/status?${getParamsString(
                    getQueryParams({
                        companyId: id.companyId,
                        productId: id.productId,
                        taxYear: id.taxYearId,
                        moduleId: id.moduleId,
                    })
                )}`
            )
        );
    }

    jurisdictionCalc(id: Partial<ReturnDocumentId>): Promise<void> {
        return this.api.post<void>(
            this.getPath(
                `/calc?${getParamsString(
                    getQueryParams({
                        companyId: id.companyId,
                        productId: id.productId,
                        taxYear: id.taxYearId,
                        moduleId: id.moduleId,
                    })
                )}`
            )
        );
    }

    /**
     * Fetches and updates jurisdictions.
     *
     * @param id - The id.
     * @param callbackFn - The callback function to be called after fetching and updating jurisdictions.
     */
    fetchAndUpdateJurisdictions(
        id: Partial<ReturnDocumentId>,
        callbackFn?: () => void
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.getJurisdictionUpdateStatus(id)
                .then((jurisdictions: JurisdictionStatus[]) => {
                    if (jurisdictions.length > 0) {
                        this.jurisdictionCalc(id);
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    if (callbackFn) callbackFn();
                });
        });
    }

    public static getInstance(): JurisdictionVersionService {
        if (!JurisdictionVersionService._instance) {
            JurisdictionVersionService._instance =
                new JurisdictionVersionService();
        }
        return JurisdictionVersionService._instance;
    }
}
