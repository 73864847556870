import React from "react";
import { StyledCard } from "../../Agent.styled";
import "./SingleAddressLookup.scss";
import AddressLookup from "./components/AddressLookup/AddressLookup";
import AllocationResults from "./components/AllocationResults/AllocationResults";
import AllocationResultsCont from "./components/AllocationResultsCont/AllocationResultsCont";

const SingleAddressLookup = () => {
    return (
        <div className="single-address-lookup-container">
            <StyledCard>
                <AddressLookup />
            </StyledCard>
            <StyledCard>
                <AllocationResults />
            </StyledCard>
            <StyledCard>
                <AllocationResultsCont />
            </StyledCard>
        </div>
    );
};

export default SingleAddressLookup;
