import {
    Button,
    CardContent,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import { premiumTypes } from "src/constants/PremiumTypes";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    StyledForm,
    StyledFormContainer,
} from "src/products/Agent/Agent.styled";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AgentService } from "src/services/AgentService";
import { FixMeLater } from "src/types";
import { AddressLookupMatchDescriptions } from "./AddressLookup.constant";
import "./AddressLookup.scss";

const AddressLookup: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const effectiveDate = useAppSelector(
        (state) => state?.[product?.productName]?.value?.effectiveDate,
    );

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const agentService = AgentService.getInstance();

    const dispatch = useAppDispatch();

    const [savePremiumType, setSavePremiumType] = useState(false);

    const [formData, setFormData] = useState({
        sourceStreet: "",
        sourceCity: "",
        sourceState: "Kentucky",
        sourceZipCode: "",
        lineOfBusiness: "",
        effectiveDate: "",
    });

    const [errors, setErrors] = useState({
        sourceStreet: false,
        sourceCity: false,
        sourceZipCode: false,
        lineOfBusiness: false,
        effectiveDate: false,
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === "effectiveDate" ? convertDateFormat(value) : value,
        }));

        if (value.trim() !== "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: false,
            }));
        }
    };

    const convertDateFormat = (inputDate) => {
        const dateArray = inputDate.split("-");
        const formattedDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
        return formattedDate;
    };

    const handleSavePremiumTypeCheckboxChange = (event) => {
        const { checked } = event.target;
        setSavePremiumType(checked);
        // make api call to update options
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newErrors = {
            sourceStreet: formData.sourceStreet === "",
            sourceCity: formData.sourceCity === "",
            sourceZipCode: formData.sourceZipCode === "",
            lineOfBusiness: formData.lineOfBusiness === "",
            effectiveDate: formData.effectiveDate === "",
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        if (formData.effectiveDate === "") {
            formData.effectiveDate = convertDateFormat(
                new Date().toISOString().split("T")[0],
            );
        }

        try {
            const allocateAddressResult = await agentService.allocate(formData);
            dispatch(
                GlobalStateActions[
                    product?.productName
                ]?.setAllocateAddressResult(allocateAddressResult),
            );

            const matchDescription =
                allocateAddressResult?.allocateAddressResult?.matchQuality
                    ?.matchDescription;

            if (
                matchDescription === AddressLookupMatchDescriptions.goodMatch ||
                matchDescription === AddressLookupMatchDescriptions.singleMatch
            ) {
                handleSnackbar("A good match is found", "success");
            } else if (
                matchDescription === AddressLookupMatchDescriptions.badMatch
            ) {
                handleSnackbar(
                    "No match found! Please enter the correct address information.",
                    "error",
                );
            } else if (
                matchDescription === AddressLookupMatchDescriptions.noClicks
            ) {
                handleSnackbar(
                    "No clicks were purchased!  Please purchase clicks for this account.",
                    "error",
                );
            } else if (
                matchDescription === AddressLookupMatchDescriptions.matchFour
            ) {
                handleSnackbar(
                    "PO Boxes and Rural Routes are invalid. Please enter the address of risk.",
                    "error",
                );
            } else {
                handleSnackbar(
                    "No match found! Please enter correct address information",
                    "error",
                );
            }
        } catch (error) {
            console.log(error);
            handleSnackbar(
                "There was an error processing your request. Please try again.",
                "error",
            );
        }
    };

    const handleClear = () => {
        setFormData({
            sourceStreet: "",
            sourceCity: "",
            sourceState: "Kentucky",
            sourceZipCode: "",
            lineOfBusiness: "",
            effectiveDate: "",
        });

        setErrors({
            sourceStreet: false,
            sourceCity: false,
            sourceZipCode: false,
            lineOfBusiness: false,
            effectiveDate: false,
        });

        dispatch(
            GlobalStateActions[product?.productName]?.setAllocateAddressResult(
                null,
            ),
        );
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    return (
        <CardContent>
            <div className="address-lookup-container">
                <Typography variant="h5">Address Lookup</Typography>
                <div>
                    Please enter the physical address and select a line of
                    business. Enter a date between 7/1/
                    {effectiveDate?.startYear} and 6/30/
                    {effectiveDate?.endYear}. Today&apos;s date is the default.
                </div>
                <StyledFormContainer>
                    <StyledForm>
                        <TextField
                            variant="outlined"
                            label="Street"
                            fullWidth
                            name="sourceStreet"
                            value={formData.sourceStreet}
                            onChange={handleFormChange}
                            required
                            error={errors.sourceStreet}
                            helperText={
                                errors.sourceStreet ? "Street is required" : ""
                            }
                        />
                        <TextField
                            variant="outlined"
                            label="City"
                            fullWidth
                            name="sourceCity"
                            value={formData.sourceCity}
                            onChange={handleFormChange}
                            required
                            error={errors.sourceCity}
                            helperText={
                                errors.sourceCity ? "City is required" : ""
                            }
                        />
                        <TextField
                            variant="outlined"
                            label="State"
                            fullWidth
                            name="sourceState"
                            value={formData.sourceState}
                        ></TextField>
                        <TextField
                            variant="outlined"
                            label="ZipCode"
                            fullWidth
                            name="sourceZipCode"
                            value={formData.sourceZipCode}
                            onChange={handleFormChange}
                            required
                            error={errors.sourceZipCode}
                            helperText={
                                errors.sourceZipCode
                                    ? "Zip Code is required"
                                    : ""
                            }
                            type="number"
                        />
                        <TextField
                            variant="outlined"
                            label="Premium Type"
                            fullWidth
                            name="lineOfBusiness"
                            value={formData.lineOfBusiness}
                            onChange={handleFormChange}
                            select
                            required
                            error={errors.lineOfBusiness}
                            helperText={
                                errors.lineOfBusiness
                                    ? "Premium Type is required"
                                    : ""
                            }
                        >
                            {premiumTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={savePremiumType}
                                    onChange={
                                        handleSavePremiumTypeCheckboxChange
                                    }
                                    color="primary"
                                />
                            }
                            label="Save Premium Type"
                        />
                        <TextField
                            variant="outlined"
                            label="Effective Date"
                            type="date"
                            defaultValue={formData.effectiveDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="effectiveDate"
                            onChange={handleFormChange}
                            error={errors.effectiveDate}
                            helperText={
                                errors.effectiveDate
                                    ? "Effective Date is required"
                                    : ""
                            }
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Geocode Address
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClear}
                        >
                            Clear Fields
                        </Button>
                    </StyledForm>
                </StyledFormContainer>
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </CardContent>
    );
};

export default AddressLookup;
