import React, { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import "./QuarterSelector.scss";
import { StyledEventIcon } from "./QuarterSelector.styled";

export type QuarterSelectorProps = {
    quarterChangeFn: (changes: { selectedQuarter: string }) => void;
};

const QuarterSelector: FC<QuarterSelectorProps> = ({ quarterChangeFn }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const product = useAppSelector(
        (state: FixMeLater) => state?.Product?.value
    );

    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );

    // Options for quarters
    const quarterOptions = [
        "Qtr 1",
        "Qtr 2",
        "Qtr 3",
        "Qtr 4",
        "Reconciliation",
    ].map((quarter) => ({
        value: quarter,
        label: quarter,
    }));

    // Check if quarter param exists in url
    useEffect(() => {
        const quarterParam = searchParams.get("quarter");
        if (
            quarterParam &&
            quarterOptions.some((option) => option.value === quarterParam)
        ) {
            dispatch(
                GlobalStateActions[product?.productName]?.setSelectedQuarter(
                    quarterParam
                )
            );
        }
    }, []);

    const handleChange = (selectedQuarter: string) => {
        quarterChangeFn({
            selectedQuarter,
        });
        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedQuarter(
                selectedQuarter
            )
        );
    };

    return (
        <div className="tts-quarter-selector">
            <SimpleAutoComplete
                icon={<StyledEventIcon />}
                size="small"
                label="Quarter"
                data={quarterOptions}
                onSelect={handleChange}
                selected={selectedQuarter || ""}
                data-testid="tts-quarter-selector"
            />
        </div>
    );
};

export default QuarterSelector;
