import React, { FC, useEffect, useRef, useState } from "react";
import CompanySelector from "../CompanySelector/CompanySelector";
import ReturnTreeAccordion from "../ReturnTreeAccordion/ReturnTreeAccordion";
import YearSelector from "../YearSelector/YearSelector";
import "./ReturnTreeViewer.scss";
import { JurisdictionVersionService } from "src/services/JurisdictionVersionService";
import {
    Company,
    FixMeLater,
    Product,
    ReturnDocumentId,
    State,
} from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { getModuleId } from "src/services/Utility";
import {
    Backdrop,
    CircularProgress,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import MunicipalStateSelector from "../MunicipalStateSelector/MunicipalStateSelector";
import QuarterSelector from "../QuarterSelector/QuarterSelector";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import StateSelector from "../StatesSelector/StateSelector";
import { ProductService } from "src/services";

const ReturnTreeViewer: FC = () => {
    let [updatingJurisdictions, setUpdatingJurisdictions] = useState(false);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const returnTreeViewerRef = useRef<FixMeLater>(null);
    let [snackbarMessage, setSnackbarMessage] = useState("");
    let [snackbarSeverity, setSnackbarSeverity] = useState("success");
    let [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useAppDispatch();

    const jurisdictionService = JurisdictionVersionService.getInstance();

    const productService = ProductService.getInstance();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product.value,
    );

    if (!product) return null;

    const company: Company | undefined = useAppSelector(
        (state) => state[product!!.productName]?.value?.company,
    );

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );

    const state: State | undefined = useAppSelector(
        (state) => state[product!.productName]?.value.selectedState,
    );

    const dropDownState: boolean = useAppSelector(
        (state) => state?.States?.dropDownState,
    );

    useEffect(() => {
        const fetchStates = async () => {
            if (product?.productId) {
                dispatch(GlobalStateActions.setDropDownState(false));
                try {
                    const states: State[] =
                        await productService.getProductJurisdictions(
                            product.productId,
                        );
                    dispatch(GlobalStateActions.setStates(states));
                } catch (error) {
                    console.error("Error fetching states:", error);
                    dispatch(GlobalStateActions.setStates([]));
                }
            } else {
                dispatch(GlobalStateActions.setStates([]));
            }
        };

        fetchStates();
    }, [product]);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            if (entries && entries.length > 0) {
                const componentWidth = entries[0].contentRect.width;
                setCollapsed(componentWidth < 140);
            }
        });
        if (returnTreeViewerRef.current) {
            observer.observe(returnTreeViewerRef.current.parentElement);
        }
        return () => {
            observer.disconnect();
        };
    }, [returnTreeViewerRef]);

    const createSnackbarData = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const getId = (
        newCompany: Company | undefined,
        newProduct: Product | undefined,
        newState: string | undefined,
    ): Partial<ReturnDocumentId> => {
        return {
            companyId: newCompany?.id,
            moduleId: Number(getModuleId(newProduct, newCompany, newState)),
            productId: newProduct?.productId,
            taxYearId: newProduct?.taxYear,
        };
    };

    const onChangeFn = async (changes?: {
        selectedCompany?: Company | undefined;
        selectedProduct?: Product | undefined;
        selectedMunicipal?: string | undefined;
        selectedState?: State | undefined;
    }) => {
        let id: Partial<ReturnDocumentId> = {};

        const prod = changes?.selectedProduct ?? product;
        const comp = changes?.selectedCompany ?? company;
        const municipal = changes?.selectedMunicipal ?? municipalState;
        const selectedState = changes?.selectedState ?? state;

        if (prod?.productName === "Municipal") {
            if (!comp || !prod || !state) return;
        }

        if (!comp || !prod || (!selectedState && dropDownState)) return;

        id = getId(comp, prod, municipal);

        if (id) {
            try {
                setUpdatingJurisdictions(true);
                await jurisdictionService.fetchAndUpdateJurisdictions(
                    id,
                    () => {
                        setUpdatingJurisdictions(false);
                    },
                );

                dispatch(
                    GlobalStateActions.UserOptions.setFilter({
                        product: prod,
                        filters: {
                            year: prod.taxYear.toString(),
                            company: comp.id.toString(),
                            state: selectedState?.id?.toString() ?? "",
                        },
                    }),
                );

                createSnackbarData(
                    "Jurisdictions updated successfully",
                    "success",
                );
            } catch (error) {
                console.error(error);
                setUpdatingJurisdictions(false);
                createSnackbarData("Failed to update jurisdictions", "error");
            }
        }
    };

    return (
        <div className="tts-return-tree-viewer" ref={returnTreeViewerRef}>
            {product?.productId !== 2 && (
                <div>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography style={{ color: "white" }}>
                            Companies
                        </Typography>
                        <Switch
                            defaultChecked={false}
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={() => {
                                dispatch(
                                    GlobalStateActions[
                                        product?.productName
                                    ].setTree([]),
                                );
                                dispatch(
                                    GlobalStateActions.setDropDownState(
                                        !dropDownState,
                                    ),
                                );
                            }}
                            value={dropDownState}
                        />
                        <Typography style={{ color: "white" }}>
                            States
                        </Typography>
                    </Stack>
                </div>
            )}
            <div className="filters">
                <div
                    className={`filters__section ${collapsed ? "filters__section--collapsed" : ""}`}
                >
                    <div className="selector selector--small">
                        <YearSelector yearChangeFn={onChangeFn} />
                    </div>
                    <div className="selector">
                        {dropDownState && product?.productId !== 2 ? (
                            <StateSelector stateChangeFn={onChangeFn} />
                        ) : (
                            <CompanySelector companyChangeFn={onChangeFn} />
                        )}
                    </div>
                </div>

                <div
                    className={`filters__section ${
                        collapsed ? "filters__section--collapsed" : ""
                    }`}
                >
                    {
                        // For Municipal, show the state selector and quarter selector
                        product?.productName === "Municipal" && (
                            <>
                                <div className="selector selector--small">
                                    <MunicipalStateSelector
                                        stateChangeFn={onChangeFn}
                                    />
                                </div>
                                {municipalState === "KY" && (
                                    <div className="selector">
                                        <QuarterSelector
                                            quarterChangeFn={() => {}}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    }
                </div>
            </div>
            <div
                className={`tree ${collapsed ? "tree--collapsed" : ""}`}
                data-testid="tts-return-tree-accordion"
            >
                {<ReturnTreeAccordion show={!collapsed} />}
                {updatingJurisdictions && (
                    <Backdrop sx={{ zIndex: 30 }} open={updatingJurisdictions}>
                        <div className="loader-section">
                            <span className="loader-text">
                                Updating Jurisdictions...
                            </span>
                            <CircularProgress sx={{ color: "white" }} />
                        </div>
                    </Backdrop>
                )}
            </div>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default ReturnTreeViewer;
