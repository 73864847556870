import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./MenuItem.scss";

interface MenuItemProps {
    children: React.ReactNode;
}

const MenuItem: FC<MenuItemProps> = ({ children }) => {
    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );

    const selectedTab = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport
                ?.selectedTab
    );

    const handleClick = () => {
        dispatch(
            GlobalStateActions[product?.productName].setPaymentRequestExport({
                ...paymentRequestExport,
                selectedTab: children,
            })
        );
    };

    return (
        <div
            className={`menu-item-container ${
                selectedTab === children ? "selected" : ""
            }`}
            onClick={handleClick}
        >
            {children}
        </div>
    );
};

export default MenuItem;
