import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "src/types";

interface ProductState {
    value?: Product;
}

const initialState: ProductState = {
    value: undefined,
}

export const ProductSlice = createSlice({
    name: "Product",
    initialState,
    reducers: {
        setProduct: (state, action: PayloadAction<Product | undefined>) => {
            state.value = action.payload;
        }
    },
});

export const { setProduct } = ProductSlice.actions;
export default ProductSlice.reducer;
