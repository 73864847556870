
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import DashboardCard from '../../DashboardCard/DashboardCard';
import { PieChart } from '@mui/x-charts/PieChart';

export const PreparerStatusPieChart: React.FC = () => {

    const pieChartData = [
        { preparer: "Chris", amount: 0 },
        { preparer: "Faith", amount: 15 },
        { preparer: "Lisa", amount: 10 },
        { preparer: "Robby", amount: 5 },
        { preparer: "Mike", amount: 8 },
        { preparer: "Selena", amount: 9 },
    ];


    const getPieChartTable = () => {
        const rows = [...pieChartData];

        return (
            <Table sx={{ minWidth: "200px", maxWidth: "300px" }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Preparer</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.preparer}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.preparer}
                            </TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };


    const getPieChart = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box>
                <PieChart
                    series={[
                        {
                            data: [
                                ...pieChartData.map((data) => ({
                                    id: data.preparer,
                                    value: data.amount,
                                    label: data.preparer,
                                })),
                            ],
                        },
                    ]}
                    width={400}
                    height={300}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignSelf: "flex-start",
                }}
            >
                {getPieChartTable()}
            </Box>
        </Box>
    );

    return (
        // Your JSX code here
        <DashboardCard
                                title={"Preparer Status"}
                                subheader={"by Amount"}
                                actions={[]}
                            >
                                {getPieChart()}
                            </DashboardCard>
    );
};
