import React from "react";
import { FixMeLater } from "src/types";
import "./LabelField.scss";

const LabelField: React.FC<{ scale: number; field: FixMeLater }> = ({
    scale,
    field,
}) => {
    const { style, textValue, isVisible, tabIndex } = field;
    const { position, size } = field.screenBox;

    const labelStyle: FixMeLater = {
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        textAlign: style?.alignment,
        fontSize: `${style?.fontSize * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        display: isVisible ? "block" : "none",
        tabIndex: tabIndex,
    };

    return (
        <div
            className="label-field-container"
            style={labelStyle}
            onClick={() => console.log("Clicked on label field")}
        >
            {textValue}
        </div>
    );
};

export default LabelField;
