import { Grid } from "@mui/material";
import React, { FC } from "react";
import "./UserRoles.scss";
import UserRole from "./components/UserRole/UserRole";
import UserRoleList from "./components/UserRoleList/UserRoleList";

const UserRoles: FC = () => {
    return (
        <div className="user-roles-container">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <UserRoleList />
                </Grid>
                <Grid item xs={4}>
                    <UserRole />
                </Grid>
            </Grid>
        </div>
    );
};

export default UserRoles;
