import { Button, Checkbox, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { onlyLettersRegex } from "src/constants/Regex";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./AccountInformationModal.scss";

interface AccountInformationModalProps {
    open: boolean;
    onClose: () => void;
}

const AccountInformationModal: FC<AccountInformationModalProps> = ({
    open,
    onClose,
}) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccount
    );
    const accounts = useAppSelector(
        (state) => state[product?.productName]?.value?.accounts
    );

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    const initialFormData = {
        accountName: selectedAccount?.name || "",
        accountNumber: selectedAccount?.accountNumber || "",
        federated: selectedAccount?.isFederated || false,
        sinceStratusDate: formatDate(
            new Date(selectedAccount?.sinceStratusDate)
        ),
        customerSince: formatDate(new Date(selectedAccount?.customerSince)),
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
    }, [selectedAccount]);

    const [validationErrors, setValidationErrors] = useState({
        accountName: "",
        accountNumber: "",
    });

    const handleInputChange = (e: FixMeLater) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));

        if (name === "accountName" && value.length > 65) {
            setValidationErrors({
                ...validationErrors,
                accountName: "Account Name must be at most 65 characters.",
            });
        } else if (
            name === "accountNumber" &&
            (value.length < 5 ||
                value.length > 7 ||
                !onlyLettersRegex.test(value.charAt(0)))
        ) {
            setValidationErrors({
                ...validationErrors,
                accountNumber:
                    "Account Number must be at least 5 at most 7 characters and should start with a letter.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const updatedAccount = {
            ...selectedAccount,
            name: formData.accountName,
            accountNumber: formData.accountNumber,
            isFederated: formData.federated,
            sinceStratusDate: formData.sinceStratusDate,
            customerSince: formData.customerSince,
        };

        try {
            setIsLoading(true);
            const response = await accountService.updateAccount(updatedAccount);

            const updatedAccounts = [...accounts];

            updatedAccounts.forEach((account, index) => {
                if (account.id === updatedAccount.id) {
                    updatedAccounts[index] = {
                        ...updatedAccount,
                    };
                }
            });

            dispatch(
                GlobalStateActions[product?.productName].setAccounts(
                    updatedAccounts
                )
            );

            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccount(
                    response
                )
            );

            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            console.error(error);
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">
                                Edit Account Info
                            </Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="accountName"
                                    label="Account Name"
                                    name="accountName"
                                    value={formData.accountName}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.accountName}
                                    helperText={validationErrors.accountName}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="accountNumber"
                                    label="Account Number"
                                    name="accountNumber"
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.accountNumber}
                                    helperText={validationErrors.accountNumber}
                                />
                                <div>
                                    <Checkbox
                                        id="federated"
                                        name="federated"
                                        checked={formData.federated}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="federated">Federated</label>
                                </div>
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="sinceStratusDate"
                                    InputLabelProps={{ shrink: true }}
                                    label="Stratus Since"
                                    name="sinceStratusDate"
                                    value={formData.sinceStratusDate}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="customerSince"
                                    InputLabelProps={{ shrink: true }}
                                    label="Customer Since"
                                    name="customerSince"
                                    value={formData.customerSince}
                                    onChange={handleInputChange}
                                />

                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.accountName ||
                                            !!validationErrors.accountNumber
                                        }
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Account information updated successfully!"
                severity="success"
            />
        </>
    );
};

export default AccountInformationModal;
