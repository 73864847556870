import { CardContent, Grid } from "@mui/material";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";

import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/hooks";
import { FixMeLater, YearData } from "src/types";
import { StyledCard } from "../../Agent.styled";
import {
    calculateMonthlyWithIp,
    calculateMonthlyWithoutIp,
    calculateQuarterlyWithIp,
    calculateQuarterlyWithoutIp,
} from "../../Agent.util";
import "./UsageReport.scss";
import { StyledPaper } from "./UsageReport.styled";

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
            csvOptions={{
                fileName: "UsageReport",
            }}
            excelOptions={{
                fileName: "UsageReport",
            }}
        />
    </GridToolbarContainer>
);

const UsageReport: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const usageReport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.usageReport,
    );

    const [isMonthly, setIsMonthly] = useState<boolean>(true);
    const [isShowIP, setIsShowIP] = useState<boolean>(true);

    const [mostRecentYearData, setMostRecentYearData] = useState<YearData>();

    const [monthlyWithIP, setMonthlyWithIP] = useState<FixMeLater>();
    const [monthlyWithoutIP, setMonthlyWithoutIP] = useState<FixMeLater>();
    const [quarterlyWithIP, setQuarterlyWithIP] = useState<FixMeLater>();
    const [quarterlyWithoutIP, setQuarterlyWithoutIP] = useState<FixMeLater>();

    useEffect(() => {
        setMostRecentYearData(usageReport?.yearData?.[0]);
        setMonthlyWithIP(calculateMonthlyWithIp(usageReport?.yearData));
        setMonthlyWithoutIP(calculateMonthlyWithoutIp(usageReport?.yearData));
        setQuarterlyWithIP(calculateQuarterlyWithIp(usageReport?.yearData));
        setQuarterlyWithoutIP(
            calculateQuarterlyWithoutIp(usageReport?.yearData),
        );
    }, [usageReport]);

    const getColumns = () => {
        if (isMonthly && isShowIP) return monthlyWithIP?.columns || [];
        if (isMonthly && !isShowIP) return monthlyWithoutIP?.columns || [];
        if (!isMonthly && isShowIP) return quarterlyWithIP?.columns || [];
        if (!isMonthly && !isShowIP) return quarterlyWithoutIP?.columns || [];
    };

    const getRows = () => {
        if (isMonthly && isShowIP) return monthlyWithIP?.rows || [];
        if (isMonthly && !isShowIP) return monthlyWithoutIP?.rows || [];
        if (!isMonthly && isShowIP) return quarterlyWithIP?.rows || [];
        if (!isMonthly && !isShowIP) return quarterlyWithoutIP?.rows || [];
    };

    return (
        <div className="usage-report-container">
            <div className="top-container">
                <div className="report-options-container">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <StyledPaper
                                isSelected={isMonthly}
                                onClick={() => {
                                    if (!isMonthly) setIsMonthly(true);
                                }}
                            >
                                Monthly
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledPaper
                                isSelected={!isMonthly}
                                onClick={() => {
                                    if (isMonthly) setIsMonthly(false);
                                }}
                            >
                                Quarterly
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledPaper
                                isSelected={isShowIP}
                                onClick={() => {
                                    if (!isShowIP) setIsShowIP(true);
                                }}
                            >
                                Show IP
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledPaper
                                isSelected={!isShowIP}
                                onClick={() => {
                                    if (isShowIP) setIsShowIP(false);
                                }}
                            >
                                Don&apos;t show IP
                            </StyledPaper>
                        </Grid>
                    </Grid>
                </div>

                <div className="clicks-container">
                    <StyledCard>
                        <Grid container>
                            <Grid item xs={4}>
                                <CardContent>
                                    <div>
                                        <b>
                                            Total Clicks Purchased for{" "}
                                            {mostRecentYearData?.year}
                                        </b>
                                    </div>
                                    <div>
                                        {mostRecentYearData?.purchasedClicks}
                                    </div>
                                </CardContent>
                            </Grid>

                            <Grid item xs={4}>
                                <CardContent>
                                    <div>
                                        <b>
                                            Total Clicks Used during{" "}
                                            {mostRecentYearData?.year}
                                        </b>
                                    </div>
                                    <div>
                                        {mostRecentYearData?.totalUsedClicks}
                                    </div>
                                </CardContent>
                            </Grid>

                            <Grid item xs={4}>
                                <CardContent>
                                    <div>
                                        <b>
                                            Total Clicks Remaining for{" "}
                                            {mostRecentYearData?.year}
                                        </b>
                                    </div>
                                    <div>
                                        {mostRecentYearData?.purchasedClicks !==
                                            undefined &&
                                        mostRecentYearData?.totalUsedClicks !==
                                            undefined
                                            ? mostRecentYearData?.purchasedClicks -
                                              mostRecentYearData?.totalUsedClicks
                                            : "N/A"}
                                    </div>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </div>
            </div>

            <div className="data-grid-container">
                <DataGridPremium
                    columns={getColumns()}
                    rows={getRows()}
                    slots={{ toolbar: CustomToolbar }}
                />
            </div>
        </div>
    );
};

export default UsageReport;
