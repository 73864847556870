import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { State } from "src/types";


interface StatesState {
    dropDownState: boolean,
    value: State[]
}

const initialState: StatesState = {
    dropDownState: false,
    value: []
}

export const StatesSlice = createSlice({
    name: "States",
    initialState,
    reducers: {
        setStates: (state, action: PayloadAction<State[]>) => {
            state.value = action.payload
        },
        setDropDownState: (state, action: PayloadAction<boolean>) => {
            state.dropDownState = action.payload
        }
    }
});

export const { setStates, setDropDownState } = StatesSlice.actions;
export default StatesSlice.reducer;