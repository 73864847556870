import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TabProperties, TabsPropertiesMap } from "src/types";

interface Tabs {
    tabsProperties: TabsPropertiesMap;
}

const initialState: Tabs = {
    tabsProperties: {},
};

export const TabSlice = createSlice({
    name: "Tabs",
    initialState,
    reducers: {
        setTabs: (state, action: PayloadAction<TabsPropertiesMap>) => {
            state.tabsProperties = action.payload;
        },
        setTab: (state, action: PayloadAction<TabProperties>) => {
            const tab = action.payload;
            state.tabsProperties[tab.id] = tab;
        },
        removeTab: (state, action: PayloadAction<string>) => {
            delete state.tabsProperties[action.payload];
        },
    },
});

export const { setTabs , setTab, removeTab } = TabSlice.actions;
export default TabSlice.reducer;
