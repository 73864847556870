import { Button, Modal, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService, UserService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledDataGrid,
    StyledFlexContainer,
    StyledFormContainer,
    StyledModalContainer,
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import "./UsersModal.scss";

const UsersModal: FC<{
    open: boolean;
    onClose: () => void;
    handleSuccess: () => void;
}> = ({ open, onClose, handleSuccess }) => {
    const userService = UserService.getInstance();
    const accountService = AccountService.getInstance();
    const dispatch = useAppDispatch();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup
    );
    const selectedAccountGroups = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroups
    );
    const selectedAccountUsers = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountUsers
    );
    const selectedAccountGroupUsers = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.selectedAccountGroupUsers
    );

    const [selectedAccountGroupUserIds, setSelectedAccountGroupUserIds] =
        useState(selectedAccountGroupUsers?.map((user) => user.id));

    useEffect(() => {
        const fetchSelectedAccountUsers = async () => {
            setIsLoading(true);
            try {
                const selectedAccountUsers = await userService.getAccountUsers(
                    selectedAccount.id
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUsers(selectedAccountUsers)
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUser(selectedAccountUsers?.[0])
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountUsers();
    }, [selectedAccount]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const updatedSelectedAccountGroup = {
                ...selectedAccountGroup,
                users: selectedAccountGroupUserIds,
            };

            const updatedSelectedAccountGroupResponse =
                await accountService.updateAccountGroupContent(
                    updatedSelectedAccountGroup,
                    "user"
                );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroup(updatedSelectedAccountGroupResponse)
            );

            const updatedSelectedAccountGroups = selectedAccountGroups?.map(
                (group) =>
                    group.id === updatedSelectedAccountGroupResponse.id
                        ? { ...updatedSelectedAccountGroupResponse }
                        : group
            );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroups(updatedSelectedAccountGroups)
            );

            onClose();
            handleSuccess();
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const columns = [
        { field: "firstName", headerName: "User Name", width: 200 },
        { field: "email", headerName: "User Email", width: 200 },
    ];

    return (
        <Modal open={open} onClose={onClose}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">Edit Group Users</Typography>

                        {selectedAccountUsers?.length === 0 ? (
                            <StyledNoDataFound>
                                No users found for this account.
                            </StyledNoDataFound>
                        ) : (
                            <div className="users-table-container">
                                <StyledDataGrid
                                    columns={columns}
                                    checkboxSelection
                                    onRowSelectionModelChange={(params) => {
                                        setSelectedAccountGroupUserIds(params);
                                    }}
                                    rows={selectedAccountUsers}
                                    rowSelectionModel={
                                        selectedAccountGroupUserIds
                                    }
                                />
                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </div>
                        )}
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default UsersModal;
