import { Button, Modal, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyProductService, ProductService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledDataGrid,
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import { processCompanyProducts } from "../../components/Products/Products.util";
import "./EditProductModal.scss";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";

interface EditProductModalProps {
    open: boolean;
    onClose: () => void;
    productToEdit: FixMeLater;
}

const EditProductModal: FC<EditProductModalProps> = ({
    open,
    onClose,
    productToEdit,
}) => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const [productToEditJurisdictions, setProductToEditJurisdictions] =
        useState<FixMeLater[]>([]);

    const [productToEditJurisdictionIds, setProductToEditJurisdictionIds] =
        useState<number[]>([]);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountCompany
    );

    const dispatch = useAppDispatch();
    const productService = ProductService.getInstance();
    const companyProductService = CompanyProductService.getInstance();

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        const fetchSelectedAccountGroupCompanies = async () => {
            setIsLoading(true);
            try {
                const productJurisdictions =
                    await productService.getProductJurisdictions(
                        ProductNameToIdMap.get(productToEdit?.productName) || ""
                    );
                setProductToEditJurisdictions(productJurisdictions);

                setProductToEditJurisdictionIds(
                    productToEdit?.jurisdictionDTOs?.map(
                        (jurisdiction) => jurisdiction?.id
                    ) || []
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountGroupCompanies();
    }, [productToEdit?.productName, productToEdit?.jurisdictionDTOs]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const jurisdictionIdSet = new Set(productToEditJurisdictionIds);

            const updatedProductToEditJurisdictions = productToEditJurisdictions
                .map((jurisdiction) => {
                    return {
                        companyId: selectedAccountCompany?.id,
                        productId: ProductNameToIdMap.get(
                            productToEdit?.productName
                        ),
                        year: productToEdit?.year,
                        jurisdictionDTO: jurisdiction,
                        isSelected: jurisdictionIdSet.has(jurisdiction.id)
                    };
                });
         
            await companyProductService.createCompanyProducts(
                selectedAccountCompany?.id,
                {
                    companyProductDTOs: updatedProductToEditJurisdictions,
                }
            );

            const updatedSelectedAccountCompanyProducts =
                await companyProductService.getCompanyProducts(
                    selectedAccountCompany?.id
                );

            const processedUpdatedSelectedAccountCompanyProducts =
                processCompanyProducts(updatedSelectedAccountCompanyProducts);

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountCompanyProducts(
                    processedUpdatedSelectedAccountCompanyProducts
                )
            );
            onClose();
        } catch (error) {
            setError(error as Error);
            handleSnackbar(
                `Error cannot assign product to company : ` + error,
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    const columns = [
        { field: "id", headerName: "Jurisdiction Id", width: 100 },
        { field: "longName", headerName: "Jurisdiction Name", width: 200 },
    ];

    const rows = productToEditJurisdictions?.map((jurisdiction) => {
        return {
            id: jurisdiction?.id,
            longName: jurisdiction?.longName,
        };
    });

    return (
        <Modal open={open} onClose={handleCancel}>
            <div>
                <StyledModalContainer>
                    <StyledFormContainer>
                        <Typography variant="h5">
                            Update Company Product Jurisdictions
                        </Typography>
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <StyledDataGrid
                                    columns={columns}
                                    checkboxSelection
                                    onRowSelectionModelChange={(params) => {
                                        setProductToEditJurisdictionIds(
                                            params.map((id) => Number(id))
                                        );
                                    }}
                                    rows={rows}
                                    rowSelectionModel={productToEditJurisdictionIds}
                                />
                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </div>
                        </StyledForm>
                    </StyledFormContainer>
                    {isLoading && <Loader />}
                    {error && <ModalError error={error} />}
                </StyledModalContainer>
                <CustomSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </div>
        </Modal>
    );
};

export default EditProductModal;
