import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { FC } from "react";
import { FixMeLater } from "src/types";
import "./CustomSnackbar.scss";

interface CustomSnackbarProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    severity: FixMeLater;
}

const CustomSnackbar: FC<CustomSnackbarProps> = ({
    open,
    setOpen,
    message,
    severity,
}) => {
    const handleClose = (event: FixMeLater, reason: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={() => setOpen(false)}
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default CustomSnackbar;
