import { EmailKey, EmailMessage, EmailResponse } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class EFileEmailService {
    private static _instance: EFileEmailService;
    private readonly BASE_PATH: string = "/api/efile-via-email";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public static getInstance(): EFileEmailService {
        if (!EFileEmailService._instance) {
            EFileEmailService._instance = new EFileEmailService();
        }
        return EFileEmailService._instance;
    }

    async getEmailMessageInformation(emailKey: EmailKey): Promise<EmailMessage> {
        const queryString = Object.entries(emailKey)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
        return this.api.get<EmailMessage>(this.getPath(`?${queryString}`));
    }

    async sendEfileEmail(emailKey: EmailKey): Promise<EmailResponse> {
        return this.api.post<EmailResponse>(this.getPath(""), emailKey);
    }
}
