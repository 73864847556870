import { Jurisdiction } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class ProductService {
    private static _instance: ProductService;
    private readonly BASE_PATH: string = "/api/product";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getProductJurisdictions(productId: string | number): Promise<Jurisdiction[]> {
        return this.api.get<Jurisdiction[]>(this.getPath(`/${productId}/jurisdictions`));
    }

    public static getInstance(): ProductService {
        if (!ProductService._instance) {
            ProductService._instance = new ProductService();
        }
        return ProductService._instance;
    }
}