export enum PAYMENT_REQUEST_EXPORT_TAB {
    "GENERAL" = "General",
    "OPTIONS" = "Options",
    "SELECT_FIELDS" = "Select Fields",
    "FIXED_WIDTH" = "Fixed Width",
}

export enum PAYMENT_TYPE {
    "ACH_DEBIT" = "ACH Debit",
    "ACH_CREDIT" = "ACH Credit",
    "CREDIT_CARD" = "Credit Card",
    "CHECK" = "Check",
}

export enum EXPORT_FORMAT {
    "DELIMITED" = "Delimited",
    "FIXED_WIDTH" = "Fixed",
}

export const PaymentRequestExportInitialState = {
    open: false,
    selectedTab: PAYMENT_REQUEST_EXPORT_TAB.GENERAL,
    scheduleTemplateDTO: null,
    payload: {
        paymentTypes: [],
        minApproveDate: "",
        maxApproveDate: "",
        minDueDate: "",
        maxDueDate: "",
        dueDateRange: true,
        includeHeader: false,
        delimiter: ",",
        fixedWidth: [],
        minPaymentAmount: 0,
        exportInUpperCase: false,
        markAsPaid: false,
        fillDataPaidWithToday: false,
        useLeadingZero: false,
        fieldsToExport: [],
        exportFormat: EXPORT_FORMAT.DELIMITED,
    },
}
