import React, { FC, useState } from "react";
import "./FloatingWindow.scss";

interface FloatingWindowProps {
    children: React.ReactNode;
    visible?: boolean;
    setVisible: (visible: boolean) => void;
    title: string | undefined;
}

const FloatingWindow: FC<FloatingWindowProps> = ({
    children,
    visible = true,
    setVisible,
    title,
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.currentTarget.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.currentTarget.getBoundingClientRect().top);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.currentTarget.style.left = e.clientX - offsetX + "px";
            e.currentTarget.style.top = e.clientY - offsetY + "px";
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    return (
        <>
            {visible && (
                <div
                    className={`floating-window ${visible ? "visible" : ""}`}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                >
                    <div className="header">
                        <div className="title-container">
                            <div className="window-title">{title}</div>
                        </div>
                        <div
                            className="close-button-window"
                            onClick={() => setVisible(false)}
                        >
                            X
                        </div>
                    </div>
                    {children}
                </div>
            )}
        </>
    );
};

export default FloatingWindow;
