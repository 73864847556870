import React, { FC } from "react";


export type UndrawProps = {
    fileName: string;
    width?: number;
    height?: number;
};

export const Undraw: FC<UndrawProps> = ({ fileName, width, height = 100 }) => {
    return (
        <img
            src={require(`../../assets/undraw/${fileName}`)}
            alt={`${fileName} undraw image`}
            width={width}
            height={height}
        />
    );
};
