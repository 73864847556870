export const ValidPaths = [
    "",
    "account-admin",
    "agent",
    "allocator-app",
    "formsplus",
    "municipal",
    "premium",
    "not-authorized",
];
