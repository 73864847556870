import React, { FC } from "react";
import { FixMeLater } from "src/types";
import "./ErrorMessage.scss";
import { StyledPaper } from "./ErrorMessage.styled";

interface ErrorMessageProps {
    error: string | FixMeLater;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
    return (
        <StyledPaper className="error-message-container" data-testid="error-message-element">{error}</StyledPaper>
    );
};

export default ErrorMessage;
