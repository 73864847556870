import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccountOptions, AllocateResponse, EffectiveDate, UsageReport, UserOptions } from "src/types";

interface AgentState {
    value: {
        document: string;
        usageReport?: UsageReport;
        effectiveDate?: EffectiveDate;
        accountOptions?: AccountOptions[];
        userOptions?: UserOptions[];
        allocateAddressResult?: AllocateResponse;
    };
}

const initialState: AgentState = {
    value: {
        document: "Agent Home",
        usageReport: undefined,
        effectiveDate: undefined,
        accountOptions: undefined,
        userOptions: undefined,
        allocateAddressResult: undefined,
    },
};

export const AgentSlice = createSlice({
    name: "Agent",
    initialState,
    reducers: {
        setDocument: (state, action: PayloadAction<string>) => {
            state.value.document = action.payload;
        },
        setUsageReport: (state, action: PayloadAction<UsageReport>) => {
            state.value.usageReport = action.payload;
        },
        setEffectiveDate: (state, action: PayloadAction<EffectiveDate>) => {
            state.value.effectiveDate = action.payload;
        },
        setAccountOptions: (state, action: PayloadAction<AccountOptions[]>) => {
            state.value.accountOptions = action.payload;
        },
        setUserOptions: (state, action: PayloadAction<UserOptions[]>) => {
            state.value.userOptions = action.payload;
        },
        setAllocateAddressResult: (state, action: PayloadAction<AllocateResponse>) => {
            state.value.allocateAddressResult = action.payload;
        },
    },
});

export const {
    setDocument,
    setUsageReport,
    setEffectiveDate,
    setAccountOptions,
    setUserOptions,
    setAllocateAddressResult,
} = AgentSlice.actions;
export default AgentSlice.reducer;
