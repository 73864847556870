import React, { FC, useEffect } from "react";
import "./MunicipalStateSelector.scss";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";
import { MunicipalStatesAbbr } from "src/constants/MunicipalStates";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import { StyledCottageIcon } from "./MunicipalStateSelector.styled";

export type StateSelectorProps = {
    stateChangeFn: (changes: { selectedMunicipal: string }) => void;
};

const MunicipalStateSelector: FC<StateSelectorProps> = ({ stateChangeFn }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const product = useAppSelector(
        (state: FixMeLater) => state?.Product?.value
    );

    const selectedState = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedState
    );

    let stateSet = new Set<String>(MunicipalStatesAbbr);

    const stateOptions = Array.from(stateSet)?.map((state: FixMeLater) => ({
        value: state,
        label: state,
    }));

    // Check if state param exists in url
    useEffect(() => {
        const stateParam = searchParams.get("state");
        if (stateParam) {
            if (stateSet.has(stateParam)) {
                dispatch(
                    GlobalStateActions[product?.productName]?.setSelectedState(
                        stateParam
                    )
                );
            }
        }
    }, []);

    const handleChange = (selectedState: string) => {
        stateChangeFn({
            selectedMunicipal: selectedState,
        });
        dispatch(
            GlobalStateActions[product?.productName].setSelectedState(
                selectedState
            )
        );
    };

    return (
        <div className="tts-state-selector">
            <SimpleAutoComplete
                icon={<StyledCottageIcon />}
                size="small"
                label="State"
                data={stateOptions}
                onSelect={handleChange}
                selected={selectedState || ""}
                data-testid="tts-state-selector"
                disableClearButton={true}
            />
        </div>
    );
};

export default MunicipalStateSelector;
