import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./MenuItem.scss";

const MenuItem: FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    return (
        <div
            className="menu-item-container"
            onClick={() => {
                dispatch(
                    GlobalStateActions[product?.productName].setDocument(
                        children
                    )
                );
            }}
        >
            {children}
        </div>
    );
};

export default MenuItem;
