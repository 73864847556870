import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { Select } from "@mui/material";

export const StyledPdfController = styled.div`
    align-items: center;
    background-color: ${colors.navbarBackgroundColor};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    padding: 4px;

    & > svg {
        cursor: pointer;
        height: 24px;
        margin: 0 8px;
        width: 24px;

        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const StyledSelect = styled(Select)`
    .MuiSelect-select {
        border: 1px solid ${colors.white};
        color: ${colors.textColor};
        padding: 6px 8px;
    }

    .MuiInputLabel-root {
        color: ${colors.textColor};

        &.Mui-focused {
            color: ${colors.textColor};
        }
    }

    .MuiSelect-icon {
        color: ${colors.iconColor};
    }
`;
