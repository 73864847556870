import styled from "styled-components";
import colors from "src/styles/colors.scss";
import Toolbar from "@mui/material/Toolbar";
import { Button, Grid, Paper } from "@mui/material";

interface StyledNavButtonProps {
    $isActive: boolean;
}

export const StyledToolbar = styled(Toolbar)`
    &.MuiToolbar-root {
        background-color: ${colors.navbarBackgroundColor};
        color: ${colors.textColor};
        display: flex;
        justify-content: space-between;
    }
`;

export const StyledGridContainer = styled(Grid)`
    &.MuiGrid-root {
        overflow: hidden;
    }
`;

export const StyledGridItem = styled(Grid)`
    &.MuiGrid-root {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 120px;
        justify-content: center;
    }
`;

export const StyledPaper = styled(Paper)<{$disabled: boolean}>`
    &.MuiPaper-root {
        align-items: center;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        overflow: auto;
        width: 100%;
        transition: transform 0.2s;

        &:hover {
            transform: ${ props => props.$disabled ? "none" : "scale(1.05)" } ;
            cursor: ${ props => props.$disabled ? "not-allowed" : "pointer" }
        }

        img {
            display: flex;
            flex-direction: column;
            height: 50px;
        }

    }
`;

export const StyledNavButton = styled(Button)<StyledNavButtonProps>`
    background-color: transparent;
    color: ${colors.white};
    opacity: ${(props) => (props.$isActive ? "1" : "0.85")};
    text-transform: none;
    border-radius: 0;
    border-bottom: 4px solid
        ${(props) => (props.$isActive ? colors.white : "transparent")};
    min-width: 0;
    padding: 16px 0 12px;

    &:hover {
        background-color: transparent;
        border-bottom: 4px solid ${colors.white};
    }
`;
