import React from "react";
import { ApplicationFooterWrapper } from "./ApplicationFooter.styled";

const ApplicationFooter: React.FC = () => {
    return (
        <ApplicationFooterWrapper>
            <span className="copyright">
                Copyright © 2023 TriTech Software. All rights reserved. This firm is not a CPA firm.
            </span>
            <span className="version">
                {`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`}
            </span>
        </ApplicationFooterWrapper>
    );
};

export default ApplicationFooter;
