import React, { FC, useState } from "react";
import { DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { Upload } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import { ScheduleService } from "src/services";
import colors from "src/styles/colors.scss";
import { FixMeLater } from "src/types";
import "./ImportPremiumData.scss";
import { ACCEPTED_FILE_TYPES, getErrorMessageByCode } from "./ImportPremiumData.util";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface ScheduleInput {
    companyId: string;
    moduleId: string;
    productId: string;
    taxYearId: string;
    returnId: string;
    folderId: string;
    pageNumber: string;
}

interface ImportPremiumDataProps {
    scheduleInput: ScheduleInput;
    onClose: (e: DropEvent | Event) => void;
    handleSnackbar: (message: string, severity: string) => void;
}

const ImportPremiumData: FC<ImportPremiumDataProps> = ({scheduleInput, onClose, handleSnackbar}) => {
    const scheduleService = ScheduleService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const dispatch = useAppDispatch();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent | Event) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code, event ?? new Event("importPremiumData"));
            } else {
                handleUpload(acceptedFiles, event ?? new Event("importPremiumData"));
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (errorCode: ErrorCode | string, event: DropEvent | Event) => {
        onClose(event);
        handleSnackbar(getErrorMessageByCode(errorCode), "error");
    };

    const handleUpload = async (files: File[], event: DropEvent | Event) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append(`file`, files[0], files[0].name)
        Object.entries(scheduleInput)?.forEach(
            ([key, value]) => formData.append(key, value)
        );

        try {
            await scheduleService.uploadPremiumData(formData);
            handleSnackbar("Premium Data Imported", "success");
            dispatch(GlobalStateActions[product?.productName].setToogleFetch());
        } catch (error: FixMeLater) {
            handleSnackbar("Error uploading file", "error");
        } finally {
            setIsLoading(false);
            onClose(event);
        }
    };

    return (
        <div
            {...getRootProps({
                className: "import-premium-data-container",
                style: isDragActive ? acceptStyle: {},
            })}
        >
            {isLoading ? (
                <Loader color={colors.primaryColor}/>
            ) : (
                <div className="import-premium-data-content">
                    <input {...getInputProps()} />
                    <Upload
                        fontSize="medium"
                        style={{ color: colors.primaryColor }}
                    />
                    {isDragActive ? (
                        <p>Drop a document here</p>
                    ) : (
                        <p>Click or drag ASCII (.imp or .txt) file</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImportPremiumData;
