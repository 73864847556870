import { CreateSubAccountPayload, SubAccount } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class SubAccountService {
    private static _instance: SubAccountService;
    private readonly BASE_PATH: string = "/api/subaccount";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    createSubAccount(subAccount: CreateSubAccountPayload): Promise<SubAccount> {
        return this.api.post<SubAccount>(this.getPath(""), subAccount);
    }

    public static getInstance(): SubAccountService {
        if (!SubAccountService._instance) {
            SubAccountService._instance = new SubAccountService();
        }
        return SubAccountService._instance;
    }
}