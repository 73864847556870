import React, { FC } from "react";
import "./MenuItem.scss";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";

interface MenuItemProps {
    children: React.ReactNode;
    optinsExport;
    setOptinsExport;
}

const MenuItem: FC<MenuItemProps> = ({
    children,
    optinsExport,
    setOptinsExport,
}) => {
    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const selectedTab = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport
                ?.selectedTab
    );

    const handleClick = () => {
        dispatch(
            GlobalStateActions[product?.productName].setOptinsExport({
                ...optinsExport,
                selectedTab: children,
            })
        );
        setOptinsExport({
            ...optinsExport,
            selectedTab: children,
        });
    };

    return (
        <div
            className={`menu-item-container ${
                selectedTab === children && "selected"
            }`}
            onClick={handleClick}
        >
            {children}
        </div>
    );
};

export default MenuItem;
