import { TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./FixedWidth.scss";
import { StyledPaper } from "./FixedWidth.styled";

interface FieldToExportWidth {
    fieldId: number;
    fieldName: string | undefined;
    fieldWidth: number;
}

const FixedWidth: FC = () => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );
    const columnTemplateDTOs = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport
                ?.scheduleTemplateDTO?.columnTemplateDTOs
    );
    const fixedWidth = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport?.payload
                ?.fixedWidth
    );
    const fieldsToExport = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.paymentRequestExport?.payload
                ?.fieldsToExport
    );

    const [fieldsToExportWidths, setFieldsToExportWidths] = useState<
        FieldToExportWidth[]
    >([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const savedFixedWidthMap = new Map(
            fixedWidth?.map((field) => [field?.fieldId, field?.fieldWidth])
        );
        const fieldIdToFieldNameMap = new Map<number, string>(
            columnTemplateDTOs?.map((field) => [
                field?.index,
                field?.columnTemplate?.name,
            ])
        );

        const prepateFieldsToExportWidths: FieldToExportWidth[] =
            fieldsToExport.map((fieldId) => {
                return {
                    fieldId: fieldId,
                    fieldName: fieldIdToFieldNameMap.get(fieldId),
                    fieldWidth: savedFixedWidthMap.has(fieldId)
                        ? savedFixedWidthMap.get(fieldId)
                        : 0,
                };
            });

        setFieldsToExportWidths(prepateFieldsToExportWidths.filter(Boolean));
    }, [fixedWidth, columnTemplateDTOs, fieldsToExport]);

    const handleFieldWidthChange = (fieldId: number, fieldWidth: number) => {
        const updatedFieldsToExportWidths = fieldsToExportWidths?.map((field) =>
            field?.fieldId === fieldId ? { ...field, fieldWidth } : field
        );

        setFieldsToExportWidths(updatedFieldsToExportWidths);

        const updatedFixedWidth = updatedFieldsToExportWidths?.map((field) => ({
            fieldId: field?.fieldId,
            fieldWidth: field?.fieldWidth,
        }));

        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    fixedWidth: updatedFixedWidth,
                },
            })
        );
    };

    return (
        <div className="fixed-width-container">
            <StyledPaper>
                {fieldsToExportWidths?.length === 0 ? (
                    <div className="error-message">
                        Please select fields to export.
                    </div>
                ) : (
                    <>
                        <div className="item">
                            <div className="subheader">Field</div>
                            <div className="subheader">Width</div>
                        </div>
                        {fieldsToExportWidths?.map((field, index) => (
                            <div className="item" key={index}>
                                <div className="field">{field?.fieldName}:</div>
                                <div className="text-input">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={field?.fieldWidth}
                                        onChange={(e) =>
                                            handleFieldWidthChange(
                                                field?.fieldId,
                                                parseInt(e.target.value)
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </StyledPaper>
        </div>
    );
};

export default FixedWidth;
