import { Grid } from "@mui/material";
import React, { FC } from "react";
import { useAppSelector } from "src/hooks";
import { FixMeLater } from "src/types";
import { StyledLabel } from "../../AccountAdmin.styled";
import "./Users.scss";
import User from "./components/User/User";
import UserList from "./components/UserList/UserList";
import { FrameCard } from "src/uikit";
import { BarChart } from "@mui/x-charts/BarChart";
import  SCSS_VARIABLES from "src/styles/component.scss"

const Users: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountUsers = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountUsers
    );

    let allUsers = selectedAccountUsers?.length;
    let activeUsers = selectedAccountUsers?.filter((user) => user?.active)?.length;
    let inactiveUsers = selectedAccountUsers?.filter((user) => !user?.active)?.length;

    return (
        <div className="users-container">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <UserList />
                </Grid>
                <Grid item xs={4}>
                    <User />
                </Grid>

                <Grid item xs={4}>
                    <FrameCard title="Summary">
                        <BarChart
                            xAxis={[
                                {
                                    scaleType: "band",
                                    label: "# Users",
                                    data: [
                                        "All",
                                        "Active",
                                        "Inactive",
                                    ],
                                },
                            ]}
                            series={[
                                { data: [allUsers,activeUsers,inactiveUsers], color: SCSS_VARIABLES.secondary },
                            ]}
                            height={200}
                        />
                    </FrameCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default Users;
