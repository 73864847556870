import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
} from "@mui/material";
import React, { FC } from "react";
import { Carousel } from "src/uikit";
import DashboardCard from "../../DashboardCard/DashboardCard";
import { DoubleArrow } from "@mui/icons-material";

import AdvisoryMomentumImg from "src/assets/images/NewsCarousel/tritech_advisory_momentum.png";
import TritechExperienceImg from "src/assets/images/NewsCarousel/tritech_experience.png";
import TaxSeasonFeedbackImg from "src/assets/images/NewsCarousel/tritech_tax_season_feedback.png";
import styled from "styled-components";

export const NewsCarousel: FC = () => {

    const StyledImageContainer = styled.div`
        display: flex;
        flex-direction: column;
    `;

    return (
        <DashboardCard title="Latest News" subheader="" actions={[]}>
            <Carousel
                items={[
                    {
                        title: "Tax News",
                        content: "New tax regulations announced by the government.",
                        image: AdvisoryMomentumImg
                    },
                    {
                        title: "Tax Breaks",
                        content: "Learn about the latest tax breaks for small businesses.",
                        image: TritechExperienceImg
                    },
                    {
                        title: "Tax Filing Deadline",
                        content: "Important reminder: Tax filing deadline is approaching.",
                        image: TaxSeasonFeedbackImg
                    },
                ].map((item, index) => {
                    return (
                        <StyledImageContainer key={index}>
                            <img src={item.image as any} alt={item.title} height={400}/>
                        </StyledImageContainer>
                    );
                })}
            />
        </DashboardCard>
    );
};
