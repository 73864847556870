import { Grid } from "@mui/material";
import React, { FC } from "react";
import "./Groups.scss";
import Companies from "./components/Companies/Companies";
import GroupList from "./components/GroupList/GroupList";
import Products from "./components/Products/Products";
import UserRoles from "./components/UserRoles/UserRoles";
import Users from "./components/Users/Users";

const Groups: FC = () => {
    return (
        <div className="groups-container">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <GroupList />
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Companies />
                        </Grid>
                        <Grid item xs={12}>
                            <Products />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Users />
                        </Grid>
                        <Grid item xs={12}>
                            <UserRoles />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Groups;
