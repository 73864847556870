import { AccountOptions, AllocateResponse, AllocationPayload, EffectiveDate, UsageReport, UserOptions } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class AgentService {

    private static _instance: AgentService;
    private readonly BASE_PATH: string = "/api";

    constructor() {}

    private api: BaseAPIService = BaseAPIService.getInstance();

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getUsageReport() {
        return this.api.get<UsageReport>(this.getPath("/usagereport"));
    }

    getEffectiveDate() {
        return this.api.get<EffectiveDate>(this.getPath("/effective-date"));
    }

    getAccountOptions() {
        return this.api.get<AccountOptions[]>(this.getPath("/account/options"));
    }

    getUserOptions() {
        return this.api.get<UserOptions[]>(this.getPath("/user/options"));
    }

    allocate(payload: AllocationPayload) {
        return this.api.post<AllocateResponse>(this.getPath("/allocate"), payload);
    }

    public static getInstance(): AgentService {
        if (!AgentService._instance) {
            AgentService._instance = new AgentService();
        }
        return AgentService._instance;
    }

}