import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product } from "src/types";



interface ProductsState {
    value: {
        products: Product[],
        processedProducts: Product[],
    };
}

const initialState: ProductsState = {
    value: {
        products: [],
        processedProducts: [],
    }
}

export const ProductsSlice = createSlice({
    name: "Products",
    initialState,
    reducers: {
        setProcessedProducts: (state, action: PayloadAction<Product[]>) => {
            state.value.processedProducts = action.payload;
        },
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.value.products = action.payload;
        },
    },
});

export const { setProcessedProducts, setProducts } = ProductsSlice.actions;
export default ProductsSlice.reducer;
