import React from "react";
import { StyledFlexContainer } from "./Attachments.styled";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Icon from "@mui/material/Icon";
import EditIcon from "@mui/icons-material/Edit";

export const infoIcons = (
    <>
        <StyledFlexContainer>
            <AttachEmailIcon sx={{ marginRight: "4px" }} /> E-file or E-mail
        </StyledFlexContainer>
        <StyledFlexContainer>
            <AttachFileIcon sx={{ marginRight: "4px" }} /> Attachment
        </StyledFlexContainer>
        <StyledFlexContainer>
            <Icon
                style={{
                    marginRight: "4px",
                }}
            >
                *
            </Icon>{" "}
            Required
        </StyledFlexContainer>
        <StyledFlexContainer>
            <EditIcon sx={{ marginRight: "4px" }} /> Editable
        </StyledFlexContainer>
    </>
);
