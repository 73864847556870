import React, { FC } from "react";
import { StyledLandingPage, StyledLandingPageContent } from "./LandingPage.styled";
import Dashboard from "src/components/Dashboard/Dashboard";
import ContainerWithResizableSideBar from "src/components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import LandingPageSidebar from "./LandingPageSidebar/LandingPageSidebar";

const LandingPage: FC = () => {
    return (
        <StyledLandingPage>
            <StyledLandingPageContent>
                <Dashboard />
            </StyledLandingPageContent>
        </StyledLandingPage>
        // <ContainerWithResizableSideBar
        //     sideBarContent={<LandingPageSidebar/>}
        //     content={
        //         <StyledLandingPage>
        //             <Dashboard />
        //         </StyledLandingPage>
        //     }
        // />
    );
};

export default LandingPage;
