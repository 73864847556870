import styled from "styled-components";
import CottageIcon from "@mui/icons-material/Cottage";

export const StyledCottageIcon = styled(CottageIcon)`
    && {
        height: 25px;
        width: 25px;
        color: white;
    }
`;
