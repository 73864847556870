import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GridRowModel } from "@mui/x-data-grid-premium";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { StyledOutlinedButton } from "src/products/Allocator/Allocator.styled";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { ALLOCATOR_SEARCH_PARAMS, CorrectedAddresses, FixMeLater, REPORT_EDIT_FIELDS } from "src/types";
import { AllocatorService } from "src/services";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";
import { HttpError } from "src/utils/HttpError";
import { useSnackbar } from "notistack";

const EditMode: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const correctedAddresses: CorrectedAddresses = useAppSelector(
        (state) => state?.[product?.productName]?.value?.correctedAddresses
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleSave = async () => {
        handleClose();

        const corrections = Object.values(correctedAddresses).map((address: GridRowModel) => {
            return {
                sourceRowId: address?.source_row_id?.value,
                correctedStreet: address?.[REPORT_EDIT_FIELDS.NORMAL_STREET]?.value,
                correctedCity: address?.[REPORT_EDIT_FIELDS.NORMAL_CITY]?.value,
                correctedState: address?.[REPORT_EDIT_FIELDS.NORMAL_STATE]?.value,
                correctedZipCode: address?.[REPORT_EDIT_FIELDS.NORMAL_ZIP]?.value,
            };
        });

        setIsLoading(true);

        try {
            await allocatorService.applyAddressCorrections(
                Number(jobId),
                { corrections }
            );
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setEmptyCorrectedAddresses()
            );
            dispatch(
                GlobalStateActions[product?.productName].setToogleFetchReport()
            );
        } catch (error) {
            if(error instanceof HttpError) {
                const errorBody = JSON.parse(error?.body);
                if (errorBody?.errors) {
                    errorBody?.errors?.forEach(err => {
                        enqueueSnackbar(err?.messages, { variant: "error" });
                    })
                } else {
                    enqueueSnackbar(errorBody?.errorDetails, { variant: "error" });
                }
            } else {
                enqueueSnackbar("Error applying addresses corrections", { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        dispatch(
            GlobalStateActions[
                product?.productName
            ].setEmptyCorrectedAddresses()
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const actionUpdate = {
        text: "Update",
        action: handleSave
    }

    const actionCancel = {
        text: "Cancel",
        action: handleClose
    }
    

    return (
        <div>
            <StyledOutlinedButton
                variant="outlined"
                disabled={!Object.keys(correctedAddresses).length}
                loading={isLoading}
                onClick={handleClickOpen}
            >
                Save
            </StyledOutlinedButton>
            <StyledOutlinedButton
                variant="outlined"
                sx={{ marginLeft: "12px" }}
                disabled={isLoading || !Object.keys(correctedAddresses).length}
                onClick={handleCancel}
            >
                Cancel
            </StyledOutlinedButton>
            <ConfirmationDialog 
                open={open}
                title={"This action will change the normalized address and reallocate it"}
                content={"This cannot be undone. The corrected addresses will be saved and processed. Do you want to continue?"}
                actionPrimary={actionUpdate}
                actionSecondary={actionCancel}
                handleClose={handleClose}
            />
        </div>
    );
};

export default EditMode;
