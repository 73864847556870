import { BaseAPIService } from "src/services/BaseApiService";
import { AccessControlGroupWithoutRelations, Company, User } from "src/types";

export class CompanyService {
    private static _instance: CompanyService;
    private readonly BASE_PATH: string = "/api/company";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getCompanyUsers(companyId: string): Promise<User[]> {
        return this.api.get<User[]>(this.getPath(`/${companyId}/users`));
    }

    getCompanyGroups(
        companyId: string
    ): Promise<AccessControlGroupWithoutRelations[]> {
        return this.api.get<AccessControlGroupWithoutRelations[]>(
            this.getPath(`/${companyId}/groups`)
        );
    }

    updateCompany(company: Company): Promise<Company> {
        return this.api.put<Company>(this.getPath("/update"), company);
    }

    createCompany(company: Company): Promise<Company> {
        return this.api.post<Company>(this.getPath(""), company);
    }

    public static getInstance(): CompanyService {
        if (!CompanyService._instance) {
            CompanyService._instance = new CompanyService();
        }
        return CompanyService._instance;
    }
}
