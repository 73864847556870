import { ErrorCode } from "react-dropzone";

export const getErrorMessageByCode = (errorCode: ErrorCode | string): string => {
    switch (errorCode) {
        case ErrorCode.FileInvalidType:
            return "Unable to parse uploaded file due to invalid format. Please ensure it is in valid CSV format.";
        case ErrorCode.TooManyFiles:
            return "Maximum of 15 files is allowed";
        default:
            return "Can't upload file";
    }
};
