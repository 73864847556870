import React, {
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import collapseIcon from "src/assets/images/Collapse.svg";
import expandIcon from "src/assets/images/Expand.svg";
import { FixMeLater } from "src/types";
import "./ContainerWithResizableSideBar.scss";

interface ContainerWithResizableSideBarProps {
    sideBarContent: FixMeLater;
    content: FixMeLater;
    toolbar?: FixMeLater;
    initialWidth?: number;
    resizeEnabled?: boolean;
    collapsedWidth?: number;
}

const DEFAULT_WIDTH = 360;
const MIN_WIDTH = 300;
const MAX_WIDTH = 600;
const COLLAPSED_WIDTH = 50;

const ContainerWithResizableSideBar: React.FC<
    ContainerWithResizableSideBarProps
> = ({
    sideBarContent,
    content,
    toolbar,
    initialWidth = DEFAULT_WIDTH,
    resizeEnabled = true,
    collapsedWidth = COLLAPSED_WIDTH,
}) => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [sidebarWidth, setSidebarWidth] = useState<number>(initialWidth);
    const [resizedWidth, setResizedWidth] = useState<number>(initialWidth);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const handleExpand = () => {
        setIsCollapsed(false);
        setSidebarWidth(resizedWidth);
    };
    const handleCollapse = () => {
        setIsCollapsed(true);
        setSidebarWidth(collapsedWidth);
    };

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback(
        (mouseMoveEvent: MouseEvent) => {
            if (isResizing && sidebarRef.current) {
                let newWidth =
                    mouseMoveEvent.clientX -
                    sidebarRef.current.getBoundingClientRect().left;
                if (newWidth >= MIN_WIDTH && newWidth <= MAX_WIDTH) {
                    setSidebarWidth(newWidth);
                    setResizedWidth(newWidth);
                }
            }
        },
        [isResizing]
    );

    useEffect(() => {
        if (resizeEnabled) {
            window.addEventListener(
                "mousemove",
                resize as unknown as EventListener
            );
            window.addEventListener("mouseup", stopResizing);
        }
        return () => {
            window.removeEventListener(
                "mousemove",
                resize as unknown as EventListener
            );
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    return (
        <div className="tts-container-with-resizable-sidebar">
            <div
                ref={sidebarRef}
                className={`sidebar ${isCollapsed ? "sidebar--collapsed" : ""}`}
                style={{ minWidth: sidebarWidth, width: sidebarWidth }}
            >
                <div
                    className={`sidebar__content ${
                        sidebarWidth < MIN_WIDTH
                            ? "sidebar__content--collapsed"
                            : ""
                    }`}
                >
                    {sideBarContent}
                </div>
                {resizeEnabled && (
                    <div
                        className={`sidebar__resizer ${
                            isCollapsed ? "sidebar__resizer--collapsed" : ""
                        }`}
                        data-testid="resizer"
                        onMouseDown={!isCollapsed ? startResizing : undefined}
                        onClick={isCollapsed ? handleExpand : undefined}
                    >
                        <div
                            className="toggle-collapse-button"
                            onClick={
                                isCollapsed ? handleExpand : handleCollapse
                            }
                        >
                            <img
                                src={!isCollapsed ? collapseIcon : expandIcon}
                                alt={!isCollapsed ? "Collapse" : "Expand"}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="content">{content}</div>
            {toolbar && <div className="toolbar">{toolbar}</div>}
        </div>
    );
};

export default ContainerWithResizableSideBar;
