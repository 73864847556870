import { Role } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class RoleService {
    private static _instance: RoleService;
    private readonly BASE_PATH: string = "/api/role";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    updateAccountUserRole(role: Role): Promise<Role> {
        return this.api.put<Role>(this.getPath("/update"), role);
    }

    public static getInstance(): RoleService {
        if (!RoleService._instance) {
            RoleService._instance = new RoleService();
        }
        return RoleService._instance;
    }
}