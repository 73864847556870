import React, { FC, useState } from "react";
import { useAppSelector } from "src/hooks";
import {
    getDateOrDefault,
    getStringValueOrDefault,
} from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import AccountInformationModal from "../../modals/AccountInformationModal/AccountInformationModal";
import "./AccountInformation.scss";
import { FrameCard } from "src/uikit";
import { text } from "stream/consumers";

const AccountInformation: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );

    const [accountInformationModalOpen, setAccountInformationModalOpen] =
        useState<boolean>(false);

    return (
        <div className="account-information-container">
            <FrameCard
                title="ACCOUNT INFORMATION"
                actions={[
                    {
                        text: "Edit",
                        onClick: () => setAccountInformationModalOpen(true),
                    },
                ]}
            >
                {!selectedAccount ? (
                    <StyledNoDataFound>
                        Please select an account.
                    </StyledNoDataFound>
                ) : (
                    <>
                        {renderField(
                            "Account Name",
                            getStringValueOrDefault(selectedAccount?.name)
                        )}
                        {renderField(
                            "Account Number",
                            getStringValueOrDefault(
                                selectedAccount?.accountNumber
                            )
                        )}
                        {renderField(
                            "Federated",
                            selectedAccount?.isFederated ? "Yes" : "No"
                        )}
                        {renderField(
                            "Stratus Since",
                            getDateOrDefault(selectedAccount?.sinceStratusDate)
                        )}
                        {renderField(
                            "Customer Since",
                            getDateOrDefault(selectedAccount?.customerSince)
                        )}
                    </>
                )}
                <AccountInformationModal
                    open={accountInformationModalOpen}
                    onClose={() => {
                        setAccountInformationModalOpen(false);
                    }}
                />
            </FrameCard>
        </div>
    );
};

export default AccountInformation;
