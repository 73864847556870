import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product, UserOptions } from "src/types";

interface UserOptionsState {
    value: UserOptions;
}

const initialState: UserOptionsState = {
    value: {
        premiumType: "",
        schedulePreferences: [],
        widgetsLayout: "",
        productPreferences: {
            Premium: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
            },
            FormsPlus: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
            },
            Municipal: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                    quarter: "",
                },
            },
        },
        globalPreferences: {
            theme: "tritech",
            zoom: 1,
        },
    },
};

export const UserOptionsSlice = createSlice({
    name: "UserOptions",
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                product: Product;
                filters: Record<string, string>;
            }>
        ) => {
            if (
                !state.value.productPreferences[
                    action.payload.product.productName
                ]
            ) {
                state.value.productPreferences[
                    action.payload.product.productName
                ] = {
                    filters: action.payload.filters,
                };
            } else {
                let filters =
                    state.value.productPreferences[
                        action.payload.product.productName
                    ]?.filters;
                state.value.productPreferences[
                    action.payload.product.productName
                ].filters = { ...filters, ...action.payload.filters };
            }
        },
        setZoomScale: (state, action: PayloadAction<number>) => {
            console.log(action.payload);
            state.value.globalPreferences.zoom = action.payload;
        },
        setUserOptions: (state, action: PayloadAction<UserOptions>) => {
            state.value = action.payload;
        },
    },
});

export const { setUserOptions, setZoomScale, setFilter } =
    UserOptionsSlice.actions;
export default UserOptionsSlice.reducer;
