import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./Companies.scss";
import AccountCompanies from "./components/AccountCompanies/AccountCompanies";
import CompanyInformation from "./components/CompanyInformation/CompanyInformation";
import Groups from "./components/Groups/Groups";
import Products from "./components/Products/Products";
import Users from "./components/Users/Users";

const Companies: FC = () => {
    const dispatch = useAppDispatch();
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountCompany
    );
    const selectedAccountCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.selectedAccountCompanies
    );

    useEffect(() => {
        if (!selectedAccountCompany) {
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountCompany(selectedAccountCompanies?.[0])
            );
        }
    }, [
        dispatch,
        product?.productName,
        selectedAccountCompany,
        selectedAccountCompanies,
    ]);

    return (
        <div className="companies-container">
            <Grid container spacing={2}>
                <Grid item xs={6} lg={4}>
                    <AccountCompanies />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <CompanyInformation />
                </Grid>

                <Grid item xs={4} >
                    <Users />
                </Grid>

                <Grid item xs={8}>
                    <Products />
                </Grid>

                <Grid item xs={6} lg={4}>
                    <Groups />
                </Grid>

            </Grid>
        </div>
    );
};

export default Companies;
