import { ExpandLess, ExpandMore, PieChart } from "@mui/icons-material";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    SxProps,
    Theme,
    IconButton,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

interface DashboardCardProps {
    title: React.ReactNode;
    subheader: React.ReactNode;
    actions: Array<{
        text: string;
        cb: () => void;
    }>;
    sx?: SxProps<Theme>;
}

const DashboardCard: React.FC<PropsWithChildren<DashboardCardProps>> = ({
    title,
    subheader,
    children,
    actions,
    sx,
}) => {

    const [expanded, setExpanded] = React.useState(true);

    const toggleExpand = () => {
        setExpanded(!expanded);
    }

    return (
        <Card sx={sx}>
            <CardHeader title={title} subheader={subheader} action={
                <IconButton onClick={toggleExpand}>
                    {
                        expanded ? <ExpandLess /> : <ExpandMore />
                    }
                </IconButton>
            } >
            </CardHeader>
            {
                expanded && (
                    <>
                        <CardContent>{children}</CardContent>
                        <CardActions>
                            {actions.map((action, index) => (
                                <Button key={index} size="small" onClick={action.cb}>
                                    {action.text}
                                </Button>
                            ))}
                        </CardActions>
                    </>
                )
            }
        </Card>
    );
};

export default DashboardCard;
