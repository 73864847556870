import React, { FC, useState } from "react";
import { SnackbarProvider, closeSnackbar } from "notistack";
import "./Tools.scss";
import { IconButton, Typography } from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";
import { ALLOCATOR_TOOLS_TABS } from "src/types";
import { SelectedStyledChipTab, StyledChipTab } from "../ReportTabs/ReportTabs.styled";
import FieldMappingGrid from "./components/FieldMappingGrid/FieldMappingGrid";

const Tools: FC = () => {
    const [selectedTab, setSelectedTab] = useState<ALLOCATOR_TOOLS_TABS>(ALLOCATOR_TOOLS_TABS.FIELD_MAPPING);

    const toolsTabs = [
        ALLOCATOR_TOOLS_TABS.ASCII_FORMAT,
        ALLOCATOR_TOOLS_TABS.PREMIUM_TYPES,
        ALLOCATOR_TOOLS_TABS.PERCENTAGE,
        ALLOCATOR_TOOLS_TABS.FIELD_MAPPING,
    ]

    const handleTabClick = (tab: ALLOCATOR_TOOLS_TABS) => {
        setSelectedTab(tab);
    };

    return (
        <SnackbarProvider
            maxSnack={5}
            disableWindowBlurListener
            autoHideDuration={10000}
            iconVariant={{
                error: <InfoOutlined sx={{ marginRight: 1 }}/>,
            }}
            style={{ flexWrap: "nowrap"}}
            action={(snackbarId) => (
                <IconButton
                    color="inherit"
                    sx={{ p: 0.5, marginLeft: 1 }}
                    onClick={() => closeSnackbar(snackbarId)}
                >
                    <Close />
                </IconButton>
            )}
        >
            <div className="tools-container">
                <div className="tools-landing">
                    <Typography variant="h2" sx={{ fontSize: 34 }}>
                        Tools
                    </Typography>
                    <div className="tools-tabs">
                        {toolsTabs?.map((tab: ALLOCATOR_TOOLS_TABS, index: number) => {
                            return selectedTab !== tab ? (
                                <StyledChipTab
                                    key={index}
                                    label={tab}
                                    disabled={tab !== ALLOCATOR_TOOLS_TABS.FIELD_MAPPING}
                                    onClick={() => handleTabClick(tab)}
                                />
                            ) : (
                                <SelectedStyledChipTab
                                    key={index}
                                    label={tab}
                                />
                            );
                        })}
                    </div>
                    {selectedTab === ALLOCATOR_TOOLS_TABS.FIELD_MAPPING && (<FieldMappingGrid />)}
                </div>
            </div>
        </SnackbarProvider>
    );
};

export default Tools;
