import React, { FC } from "react";
import "./Menu.scss";
import MenuItem from "../MenuItem/MenuItem";

const Menu = ({ optinsExport, setOptinsExport }) => {
    return (
        <div className="menu-container">
            <MenuItem optinsExport={optinsExport} setOptinsExport={setOptinsExport} >Instructions</MenuItem>
            <MenuItem optinsExport={optinsExport} setOptinsExport={setOptinsExport}>Compare</MenuItem>
        </div>
    );
};

export default Menu;
