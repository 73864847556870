import styled from "styled-components";
import SCSS_VARIABLES from "src/styles/component.scss";

export const StyledLandingPage = styled.div`
    align-items: center;
    color: ${ SCSS_VARIABLES.light };
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: ${ SCSS_VARIABLES.light2 };
`;

export const StyledLandingPageContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-width: 1440px;
    background-color: ${ SCSS_VARIABLES.light2 };
`;
