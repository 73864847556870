import { Button, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import "./EmptyState.scss";

// Define the types for the action buttons
type ActionButton = {
    label: string;
    onClick: () => void;
};

// Define the props for the EmptyState component
export type EmptyStateProps = {
    graphic?: ReactNode; // Optional component to display at top, it can be an
    title: string; // Title text to display in the middle
    text: string; // Text to display in the middle
    actions?: ActionButton[]; // Array of action buttons
};

export const EmptyState: FC<EmptyStateProps> = ({
    graphic,
    title,
    text,
    actions,
}) => {
    return (
        <div className="tts-empty-state">
            {graphic && (
                <div className="tts-empty-state-graphic">{graphic}</div>
            )}
            <div className="tts-empty-state-text">
                <Typography variant="h6" fontWeight={"bold"}>
                    {title}
                </Typography>
            </div>
            <div className="tts-empty-state-text">
                <Typography>{text}</Typography>
            </div>
            {actions && (
                <div className="tts-empty-state-actions">
                    {actions.map((action, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            onClick={action.onClick}
                            style={{ margin: "0 5px" }}
                        >
                            {action.label}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};
