import { DataGridPremium } from "@mui/x-data-grid-premium";
import styled from "styled-components";

export const StyledDataGrid = styled(DataGridPremium)`
    margin: 8px;
    max-height: 400px;
    min-height: 240px;
    padding: 16px;
`;

interface StyledFlexContainerProps {
    justify?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | "normal" | "initial" | "inherit";
}

export const StyledFlexContainer = styled.div<StyledFlexContainerProps>`
    align-items: center;
    display: flex;
    justify-content: ${(props) => (props.justify ? props.justify : "normal")};
    gap: 16px;
    width: 100%;
`;

export const StyledModalContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    max-height: 600px;
    max-width: 800px;
    overflow: auto;
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;
