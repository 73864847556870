import React, { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater, Tree } from "src/types";
import ReturnNodeMenu from "../ReturnNodeMenu/ReturnNodeMenu";
import "./ReturnNode.scss";
import { getReturnNodeIcon } from "./ReturnNodeUtil";
import { useSearchParams } from "react-router-dom";

interface ReturnNodeProps {
    returnNode: FixMeLater;
    folderNode: FixMeLater;
}

const ReturnNode: React.FC<ReturnNodeProps> = ({ returnNode, folderNode }) => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const dropDownState: boolean = useAppSelector(
        (state) => state?.States?.dropDownState,
    );

    const setDataFromURL = () => {
        const returnFolderId = searchParams.get("returnFolderId");
        const returnNodeId = searchParams.get("returnNodeId");

        if (returnFolderId && returnNodeId) {
            const folderNode = tree?.folderNodes.find(
                (folder) => folder?.id === returnFolderId,
            );
            const returnNode = folderNode?.returnNodes.find(
                (node) => node?.id === returnNodeId,
            );
            if (folderNode && returnNode) {
                dispatch(
                    GlobalStateActions[product?.productName].setFolderNode(
                        folderNode,
                    ),
                );
                dispatch(
                    GlobalStateActions[product?.productName].setReturnNode(
                        returnNode,
                    ),
                );
            }
        }
    };

    if (
        searchParams.has("returnFolderId") &&
        searchParams.has("returnNodeId")
    ) {
        setDataFromURL();
    }

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );

    const tree: Tree = useAppSelector(
        (state) => state[product?.productName]?.value?.tree,
    );

    const selectedReturnNode = useAppSelector(
        (state) => state?.[product?.productName]?.value?.returnNode,
    );
    const [returnTreeNodeMenuAnchorEl, setReturnTreeNodeMenuAnchorEl] =
        useState<HTMLElement | null>(null);

    const handleReturnNodeClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        dispatch(
            GlobalStateActions[product?.productName].setFolderNode(folderNode),
        );
        dispatch(
            GlobalStateActions[product?.productName].setReturnNode(returnNode),
        );
    };

    const openReturnNodeMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (!dropDownState) {
            setReturnTreeNodeMenuAnchorEl(event.currentTarget);
        }
    };

    const handleCloseReturnNodeMenu = (event) => {
        event.stopPropagation();
        setReturnTreeNodeMenuAnchorEl(null);
    };

    return (
        <div
            className={`return-node-container ${
                selectedReturnNode?.id === returnNode?.id
                    ? "selected-return-node-container"
                    : ""
            }`}
            onClick={(e) => handleReturnNodeClick(e)}
            onContextMenu={(e) => {
                e.preventDefault(); // prevent the default behaviour when right clicked
                openReturnNodeMenu(e);
            }}
            data-testid="return-node-container"
        >
            <div className="return-node-icon-container">
                {getReturnNodeIcon(
                    returnNode?.scheduleType,
                    returnNode?.isLocked,
                )}
            </div>
            <div className={`return-node-name-container`}>
                {returnNode?.displayName}
            </div>

            <ReturnNodeMenu
                anchorEl={returnTreeNodeMenuAnchorEl}
                handleCloseReturnNodeMenu={handleCloseReturnNodeMenu}
                returnNode={returnNode}
                folderNode={folderNode}
            />
        </div>
    );
};

export default ReturnNode;
