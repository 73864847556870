import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableRow from "@mui/material/TableRow";
import React, { FC, useEffect, useState } from "react";
import { StyledTableCell } from "src/products/AccountAdmin/AccountAdmin.styled";
import DeleteProductModal from "src/products/AccountAdmin/components/Overview/modals/DeleteProductModal/DeleteProductModal";
import EditProductModal from "src/products/AccountAdmin/components/Overview/modals/EditProductModal/EditProductModal";
import colors from "src/styles/colors.scss";
import { FixMeLater } from "src/types";

interface ProductsTableRowProps {
    data: FixMeLater[];
}

const ProductsTableRow: FC<ProductsTableRowProps> = ({ data }) => {
    const [editProductModalOpen, setEditProductModalOpen] =
        useState<boolean>(false);
    const [deleteProductModalOpen, setDeleteProductModalOpen] =
        useState<boolean>(false);
    // Transform the data into a map for faster access
    const productsByYear = new Map(data?.map((item) => [item.taxYear, item]));
    const years = [...productsByYear.keys()].sort(
        (a: FixMeLater, b: FixMeLater) => b - a
    );

    const [selectedYear, setSelectedYear] = useState(years[0]);

    useEffect(() => {
        // Check if the selectedYear exists in the years array
        if (!years.includes(selectedYear)) {
            // If not, update selectedYear to the first year in the array
            setSelectedYear(years[0]);
        }
    }, [years]);

    const handleYearChange = (event: FixMeLater) => {
        const targetYear = parseInt(event.target.value, 10);
        // Check if the targetYear exist
        if (years.includes(targetYear)) {
            setSelectedYear(targetYear);
        } else {
            setSelectedYear(years[0]); // Set to the first year in the array
        }
    };

    const selectedProduct: FixMeLater | undefined =
        productsByYear.get(selectedYear) ||
        productsByYear.get(years[0]) ||
        undefined;

    return (
        <>
            <TableRow>
                <StyledTableCell>
                    {selectedProduct?.productName}
                </StyledTableCell>
                <StyledTableCell>
                    <Select value={selectedYear} onChange={handleYearChange}>
                        {years?.map((year: FixMeLater) => (
                            <MenuItem key={year} value={year.toString()}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell>
                    {selectedProduct?.companyOrClickQuantity}
                </StyledTableCell>
                <StyledTableCell>
                    {selectedProduct?.userQuantity}
                </StyledTableCell>
                <StyledTableCell>
                    {selectedProduct?.jurisdictionQuantity}
                </StyledTableCell>
                <StyledTableCell>
                    <EditIcon
                        onClick={() => setEditProductModalOpen(true)}
                        style={{ color: colors.black, cursor: "pointer" }}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <DeleteForeverIcon
                        onClick={() => setDeleteProductModalOpen(true)}
                        style={{ color: colors.black, cursor: "pointer" }}
                    />
                </StyledTableCell>
            </TableRow>
            <EditProductModal
                open={editProductModalOpen}
                onClose={() => setEditProductModalOpen(false)}
                productToEdit={selectedProduct}
            />
            <DeleteProductModal
                open={deleteProductModalOpen}
                onClose={() => setDeleteProductModalOpen(false)}
                productToDelete={selectedProduct}
            />
        </>
    );
};

export default ProductsTableRow;
