import React, { FC, useState } from "react";
import { useAppSelector } from "src/hooks";
import { getStringValueOrDefault } from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledNoDataFound,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import AccountContactModal from "../../modals/AccountContactModal/AccountContactModal";
import "./AccountContact.scss";
import { FrameCard } from "src/uikit";

const AccountContact: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const [accountContactModalOpen, setAccountContactModalOpen] =
        useState<boolean>(false);

    const setError = (error: FixMeLater) => {
        setError(error as Error);
    };

    return (
        <div className="account-contact-container">
            <FrameCard
                title="ACCOUNT CONTACT"
                actions={[
                    {
                        text: "Edit",
                        onClick: () => setAccountContactModalOpen(true),
                    },
                ]}
            >
                {!selectedAccount ? (
                    <StyledNoDataFound>
                        Please select an account.
                    </StyledNoDataFound>
                ) : (
                    <>
                        {renderField(
                            "Name",
                            getStringValueOrDefault(
                                selectedAccount?.clientContactDTO
                                    ?.clientContactName
                            )
                        )}

                        {renderField(
                            "Phone Number",
                            getStringValueOrDefault(
                                selectedAccount?.clientContactDTO
                                    ?.clientContactPhone
                            )
                        )}

                        {renderField(
                            "Email",
                            getStringValueOrDefault(
                                selectedAccount?.clientContactDTO
                                    ?.clientContactEmail
                            )
                        )}
                    </>
                )}
                <AccountContactModal
                    open={accountContactModalOpen}
                    onClose={() => {
                        setAccountContactModalOpen(false);
                    }}
                />
            </FrameCard>
        </div>
    );
};

export default AccountContact;
