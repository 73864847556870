import styled from "styled-components";

export const StyledModalContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    height: 600px;
    width: 800px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

interface StyledFlexContainerProps {
    justify?:
        | "center"
        | "flex-start"
        | "flex-end"
        | "space-between"
        | "space-around"
        | "space-evenly"
        | "normal"
        | "initial"
        | "inherit";
}

export const StyledFlexContainer = styled.div<StyledFlexContainerProps>`
    align-items: center;
    display: flex;
    justify-content: ${(props) => (props.justify ? props.justify : "normal")};
    gap: 16px;
    width: 100%;
`;
