import { CardContent, Typography } from "@mui/material";
import React, { FC } from "react";
import { useAppSelector } from "src/hooks";
import {
    StyledResult,
    StyledResultGroup,
    StyledResultSubheader,
} from "src/products/Agent/Agent.styled";
import { FixMeLater } from "src/types";
import "./AllocationResultsCont.scss";

const AllocationResultsCont: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const { allocateAddressResult } = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.allocateAddressResult || {}
    );

    const getCountyTaxRate = () => {
        const { countyTaxRates, lineOfBusiness } = allocateAddressResult || {};
        switch (lineOfBusiness) {
            case "C":
                return countyTaxRates?.casualty;
            case "F":
                return countyTaxRates?.fire;
            case "H":
                return countyTaxRates?.health;
            case "I":
                return countyTaxRates?.inlandMarine;
            case "L":
                return countyTaxRates?.life;
            case "M":
                return countyTaxRates?.motorVehicle;
            case "O":
                return countyTaxRates?.other;
        }
    };

    return (
        <CardContent>
            <div className="allocation-results-container">
                <Typography variant="h5">Allocation Results Cont</Typography>
                <StyledResultGroup>
                    <StyledResultSubheader>
                        Jurisdiction Tax & Fee Information
                    </StyledResultSubheader>
                    <StyledResult>
                        Tax Rate: {allocateAddressResult?.effectiveCityRate}
                    </StyledResult>
                    <StyledResult>
                        Min Tax: {allocateAddressResult?.cityTaxRates?.minTax}
                    </StyledResult>
                    <StyledResult>
                        Flat Fee: {allocateAddressResult?.cityTaxRates?.flatFee}
                    </StyledResult>
                    <StyledResult>
                        Tax Code: {allocateAddressResult?.taxCode}
                    </StyledResult>
                </StyledResultGroup>

                <StyledResultGroup>
                    <StyledResultSubheader>
                        Additional County Allocation Information
                    </StyledResultSubheader>
                    <StyledResult>
                        County Name: {allocateAddressResult?.countyName}
                    </StyledResult>
                    <StyledResult>
                        County Code: {allocateAddressResult?.countyCode}
                    </StyledResult>
                    <StyledResult>
                        County Credit Applies:{" "}
                        {allocateAddressResult?.countyCreditApplies}
                    </StyledResult>
                    <StyledResult>
                        County Tax Rate: {getCountyTaxRate()}
                    </StyledResult>
                </StyledResultGroup>

                <StyledResultGroup>
                    <StyledResultSubheader>
                        Effective Tax Rate Information
                    </StyledResultSubheader>
                    <StyledResult>
                        Effective Jurisdiction Rate:{" "}
                        {allocateAddressResult?.effectiveCityRate}
                    </StyledResult>
                    <StyledResult>
                        Effective County Rate:{" "}
                        {allocateAddressResult?.effectiveCountyRate}
                    </StyledResult>
                    <StyledResult>
                        Effective Tax Rate:{" "}
                        {allocateAddressResult?.effectiveTaxRate}
                    </StyledResult>
                </StyledResultGroup>
            </div>
        </CardContent>
    );
};

export default AllocationResultsCont;
