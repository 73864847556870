// Function to handle the response and update the document

import { ReturnDocumentService } from "src/services/ReturnDocumentService";
import { ReturnPDFService } from "src/services/ReturnPDFService";
import {
    PushReturnDocument,
    QueryParams,
    ReturnDocument,
    ReturnDocumentAndValidationsDTO,
    ValidationResponse,
} from "src/types";

const returnDocumentService = ReturnDocumentService.getInstance();
const returnPDFService = ReturnPDFService.getInstance();

export const fetchPdf = async (params: QueryParams, setPdfFile: React.Dispatch<React.SetStateAction<Blob | null>>) => {
    try {
        const fetchedPdfFile: Blob = await returnPDFService.getReturnPDF(
            params
        );
        setPdfFile(fetchedPdfFile);
    } catch (error) {
        console.error("Error fetching return PDF:", error);
    }
};

export const fetchDocument = async (
    paramsfetchDocument: QueryParams,
    setReturnDocument: React.Dispatch<React.SetStateAction<ReturnDocument | undefined>>,
    setValidationResponse: React.Dispatch<React.SetStateAction<ValidationResponse | null>>
) => {
    try {
        const returnDocumentAndValidationsDTO: ReturnDocumentAndValidationsDTO =
            await returnDocumentService.getReturnDocument(paramsfetchDocument);
        setReturnDocument(returnDocumentAndValidationsDTO.returnDocument);
        setValidationResponse(returnDocumentAndValidationsDTO.validationResponse);
    } catch (error) {
        console.error("Error fetching return Document:", error);
        setValidationResponse({
            successful: false,
            failureCount: 1,
            failures: [
                {
                    message: "Error fetching return Document validations"
                }
            ],
            updatedAttachments: [],
            filenameWithAppender: "",
            finalAttachments: []
        });
    }
};

export const fetchOnlyDocument = async (
    paramsfetchDocument: QueryParams,
    setReturnDocument: React.Dispatch<React.SetStateAction<ReturnDocument | undefined>>,
) => {
    try {
        const returnDocument: ReturnDocument =
            await returnDocumentService.getOnlyReturnDocument(paramsfetchDocument);
        setReturnDocument(returnDocument);
    } catch (error) {
        console.error("Error fetching return Document:", error);
    }
};

export const handleResponse = (updatedReturnDocument: ReturnDocument, response: PushReturnDocument) => {
    return {
        ...updatedReturnDocument,
        pages: updatedReturnDocument.pages?.map((returnDocumentPage) => {
            const page = response.pages.find(
                (x) => x.pageOrder === returnDocumentPage.pageOrder
            );

            if (!page) return returnDocumentPage;

            return {
                ...returnDocumentPage,
                checkFields: updateFields(
                    returnDocumentPage.checkFields,
                    page.checkFields
                ),
                numFields: updateFields(
                    returnDocumentPage.numFields,
                    page.numFields
                ),
                textFields: updateFields(
                    returnDocumentPage.textFields,
                    page.textFields
                ),
            };
        }),
    };
};

// Function to update fields in a page
export const updateFields = (existingFields, newFields) => {
    return existingFields?.map((field) => {
        const matchingField = newFields.find((x) => x.id === field.id);
        return matchingField ? matchingField : field;
    });
};
