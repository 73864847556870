import { Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccessControlGroupService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import CompaniesModal from "../../modals/CompaniesModal/CompaniesModal";
import "./Companies.scss";
import { FrameCard } from "src/uikit";

const Companies: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const [companiesModalOpen, setCompaniesModalOpen] =
        useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup,
    );
    const selectedAccountGroupCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountGroupCompanies,
    );

    const dispatch = useAppDispatch();

    const accessControlGroupService = AccessControlGroupService.getInstance();

    useEffect(() => {
        const fetchSelectedAccountGroupCompanies = async () => {
            if (selectedAccountGroup) {
                setIsLoading(true);
                try {
                    const selectedAccountGroupCompanies: FixMeLater =
                        await accessControlGroupService.getAccountGroupCompanies(
                            selectedAccountGroup?.id,
                        );
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountGroupCompanies(
                            selectedAccountGroupCompanies,
                        ),
                    );
                } catch (error) {
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountGroupCompanies([]),
                );
            }
        };

        fetchSelectedAccountGroupCompanies();
    }, [selectedAccountGroup]);

    const noDataFoundMessage: string = selectedAccountGroup
        ? "This group is not associated with any companies."
        : "Please select a group.";

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching groups: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="group-companies-container">
                <FrameCard
                    title="GROUP COMPANIES"
                    actions={
                        selectedAccountGroup
                            ? [
                                  {
                                      text: "Edit",
                                      onClick: () =>
                                          setCompaniesModalOpen(true),
                                  },
                              ]
                            : []
                    }
                >
                    {selectedAccountGroupCompanies?.length === 0 ? (
                        <StyledNoDataFound>
                            {noDataFoundMessage}
                        </StyledNoDataFound>
                    ) : (
                        <Table>
                            {selectedAccountGroupCompanies?.map(
                                (company: FixMeLater) => (
                                    <StyledTableRow
                                        key={company.id}
                                        $isSelected={false}
                                    >
                                        <StyledTableCell>
                                            {company.name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ),
                            )}
                        </Table>
                    )}
                </FrameCard>
            </div>
            <CompaniesModal
                open={companiesModalOpen}
                onClose={() => setCompaniesModalOpen(false)}
                handleSuccess={() => setSnackbarOpen(true)}
            />
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Assignment updated successfully!"
                severity="success"
            />
        </>
    );
};

export default Companies;
