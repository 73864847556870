import { CompanyProduct, FixMeLater } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class CompanyProductService {
    private static _instance: CompanyProductService;
    private readonly BASE_PATH: string = "/api/companyproduct";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getCompanyProducts(companyId: number): Promise<CompanyProduct[]> {
        return this.api.get<CompanyProduct[]>(this.getPath(`/${companyId}`));
    }

    createCompanyProducts(
        companyId: number,
        payload: FixMeLater
    ): Promise<any> {
        return this.api.post<any>(
            this.getPath(`/${companyId}`),
            payload
        );
    }

    public static getInstance(): CompanyProductService {
        if (!CompanyProductService._instance) {
            CompanyProductService._instance = new CompanyProductService();
        }
        return CompanyProductService._instance;
    }
}