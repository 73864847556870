import { TRITECH_ROLE } from "src/constants/Tritech";
import {
    Company,
    CreateUserPayload,
    CreateUserResponse,
    Product,
    UpdateUserPayload,
    User,
    UserOptions,
} from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { LocalStorageService } from "./LocalStorageService";

export class UserService {
    private static _instance: UserService;
    private readonly BASE_PATH: string = "/api/user";
    private api: BaseAPIService = BaseAPIService.getInstance();
    private localStorageService = LocalStorageService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getSelf(): Promise<User> {
        return this.api.get<User>(this.getPath("/self"));
    }

    getUserProducts(userId: string): Promise<Product[]> {
        return this.api.get<Product[]>(this.getPath(`/${userId}/products`));
    }

    getUserCompanies(userId: string): Promise<Company[]> {
        return this.api.get<Company[]>(this.getPath(`/${userId}/companies`));
    }

    getAccountUsers(accountId: string): Promise<User[]> {
        return this.api.get<User[]>(this.getPath(`/${accountId}/all`));
    }

    getUsersWithRole(roleId: TRITECH_ROLE): Promise<User[]> {
        return this.api.get<User[]>(this.getPath(`/role/${roleId}`));
    }

    getUserOptions(): Promise<UserOptions> {
        return this.api.get<UserOptions>(this.getPath("/options"));
    }

    createUser(user: CreateUserPayload): Promise<CreateUserResponse> {
        return this.api.post<CreateUserResponse>(this.getPath("?type=UI_DB"), user);
    }

    updateUser(user: UpdateUserPayload): Promise<User> {
        return this.api.put<User>(this.getPath("/update"), user);
    }

    setUserOptions(options: UserOptions): Promise<UserOptions> {
        return this.api.post<UserOptions>(this.getPath("/options"), options);
    }

    public static getInstance(): UserService {
        if (!UserService._instance) {
            UserService._instance = new UserService();
        }
        return UserService._instance;
    }
}
