import { Tooltip } from "@mui/material";
import React, { CSSProperties, FC, useEffect, useState } from "react";
import { FixMeLater, NumField, TextField, CheckField } from "src/types";
import DynamicColors from "src/utils/DynamicColors";
import "./ComboBoxField.scss";

interface ComboBoxFieldProps {
    scale: number;
    templateField: FixMeLater;
    value: {
        value: string | number | boolean;
        isOverride: boolean;
        documentField: NumField | TextField | CheckField;
    };
    updateReturnDocument: (
        fieldType: string,
        documentNode: NumField | TextField | CheckField,
        overrideValue: string | number | boolean,
    ) => void;
    isLocked?: boolean;
}

const ComboBoxField: FC<ComboBoxFieldProps> = ({
    scale,
    templateField,
    value,
    updateReturnDocument,
    isLocked,
}) => {
    const { position, size } = templateField.screenBox;

    const {
        options,
        toolTip,
        tabIndex,
        isEnabled,
        overrideState,
        style,
        fieldType,
        id,
    } = templateField;

    const [selectedOption, setSelectedOption] = useState<string>(
        value?.value.toString() ?? "",
    );

    useEffect(() => {
        // Update inputValue when value.value changes
        setSelectedOption(value?.value.toString() ?? "");
    }, [value]);

    const handleChange = (event) => {
        const newValue = event?.target?.value;
        if (newValue != value.value) {
            updateReturnDocument("textFields", value?.documentField, newValue);
        }
        setSelectedOption(event?.target?.value);
    };

    const dynamicTextFieldColor = DynamicColors.calculateDynamicTextFieldColor(
        templateField?.isEnabled,
        overrideState,
        value?.isOverride,
    );
    const dynamicBackgroundColor =
        DynamicColors.calculateDynamicBackgroundColor(
            templateField?.isEnabled,
            templateField?.overrideState,
        );

    // Dynamic styles
    const dynamicStyles: CSSProperties = {
        backgroundColor: dynamicBackgroundColor,
        color: dynamicTextFieldColor,
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        cursor: isLocked ? "not-allowed" : "",
    };

    return (
        <Tooltip title={toolTip} placement="top">
            <select
                className="combo-box-field-container"
                style={dynamicStyles}
                id={id + fieldType}
                value={selectedOption}
                onChange={handleChange}
                tabIndex={tabIndex}
                disabled={!isEnabled || isLocked}
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </Tooltip>
    );
};

export default ComboBoxField;
