import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React, { FC, useState } from "react";
import "./DualList.scss";
import { intersection, not } from "./DualList.util";

interface Item {
    id: string;
    name: string;
}

interface DualListProps {
    leftItems: readonly Item[];
    rightItems: readonly Item[];
    onLeftChange: (newLeft: readonly Item[]) => void;
    onRightChange: (newRight: readonly Item[]) => void;
    leftSearchLabel?: string;
    rightSearchLabel?: string;
}

const DualList: FC<DualListProps> = ({
    leftItems,
    rightItems,
    onLeftChange,
    onRightChange,
    leftSearchLabel,
    rightSearchLabel,
}) => {
    const [checked, setChecked] = useState<readonly Item[]>([]);
    const [leftSearch, setLeftSearch] = useState<string>("");
    const [rightSearch, setRightSearch] = useState<string>("");

    const filteredLeftItems = leftItems.filter((item) =>
        item?.name?.toLowerCase()?.includes(leftSearch?.toLowerCase())
    );
    const filteredRightItems = rightItems.filter((item) =>
        item?.name?.toLowerCase()?.includes(rightSearch?.toLowerCase())
    );

    const leftChecked = intersection(checked, filteredLeftItems);
    const rightChecked = intersection(checked, filteredRightItems);

    const handleToggle = (value: Item) => () => {
        const currentIndex = checked?.findIndex((item) => item.id === value.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        onLeftChange([]);
        onRightChange(rightItems?.concat(leftItems));
    };

    const handleCheckedRight = () => {
        setChecked(not(checked, leftChecked));
        onLeftChange(not(leftItems, leftChecked));
        onRightChange(rightItems?.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setChecked(not(checked, rightChecked));
        onLeftChange(leftItems?.concat(rightChecked));
        onRightChange(not(rightItems, rightChecked));
    };

    const handleAllLeft = () => {
        onLeftChange(leftItems?.concat(rightItems));
        onRightChange([]);
    };

    const customList = (items: readonly Item[]) => (
        <Paper sx={{ width: 224, height: 230, overflow: "auto" }}>
            <List dense component="div" role="list">
                {items.map((value: Item) => {
                    const labelId = `transfer-list-item-${value.id}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={
                                        checked.findIndex(
                                            (item) => item?.id === value?.id
                                        ) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value?.name} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <TextField
                    label={leftSearchLabel}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={leftSearch}
                    onChange={(e) => setLeftSearch(e.target.value)}
                />
                {customList(filteredLeftItems)}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={leftItems?.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked?.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked?.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={rightItems?.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <TextField
                    label={rightSearchLabel}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={rightSearch}
                    onChange={(e) => setRightSearch(e.target.value)}
                />
                {customList(filteredRightItems)}
            </Grid>
        </Grid>
    );
};

export default DualList;
