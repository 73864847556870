export const ProductNameToIdMap = new Map([
    ["Agent", 6],
    ["Allocator", 5],
    ["FormsPlus", 1],
    ["Municipal", 2],
    ["Premium", 3],
]);

export const ProductIdToNameMap = new Map([
    [6, "Agent"],
    [5, "Allocator"],
    [1, "FormsPlus"],
    [2, "Municipal"],
    [3, "Premium"],
]);
