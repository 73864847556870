import React, { FC, useState } from "react";
import { useAppSelector } from "src/hooks";
import {
    getDateOrDefault,
    getStringValueOrDefault,
} from "src/services/Utility";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
} from "../../../../AccountAdmin.styled";
import { renderField } from "../../../../AccountAdmin.util";
import UserModal from "../../modals/UserModal/UserModal";
import "./User.scss";
import { FrameCard } from "src/uikit";

const User: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccountUser = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountUser
    );
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className="user-container">
                <FrameCard
                    title="USER"
                    actions={[
                        {
                            text: "Edit",
                            onClick: () => setUserModalOpen(true),
                        },
                    ]}
                >
                    {!selectedAccountUser ? (
                        <StyledNoDataFound>
                            Please select a user.
                        </StyledNoDataFound>
                    ) : (
                        <>
                            {renderField(
                                "First Name",
                                getStringValueOrDefault(
                                    selectedAccountUser?.firstName
                                )
                            )}
                            {renderField(
                                "Last Name",
                                getStringValueOrDefault(
                                    selectedAccountUser?.lastName
                                )
                            )}
                            {renderField(
                                "Title",
                                getStringValueOrDefault(
                                    selectedAccountUser?.title
                                )
                            )}
                            {renderField(
                                "Email",
                                getStringValueOrDefault(
                                    selectedAccountUser?.email
                                )
                            )}
                            {renderField(
                                "Last Login",
                                getDateOrDefault(selectedAccountUser?.lastLogin)
                            )}
                        </>
                    )}
                </FrameCard>
                {/* <StyledPaper>
                    <StyledHeader>USER</StyledHeader>
                </StyledPaper> */}
            </div>
            <UserModal
                open={userModalOpen}
                onClose={() => setUserModalOpen(false)}
            />
        </>
    );
};

export default User;
