import { useAuth0 } from "@auth0/auth0-react";
import {
    Save as SaveIcon,
    Sort as SortIcon,
    Upload as UploadIcon,
    MoreVert as MoreVertIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    FilterAltOutlined as FilterAltOutlinedIcon,
    PushPinOutlined as PushPinIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@mui/icons-material";
import {
    Checkbox,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";
import {
    GridCellParams,
    GridCellSelectionModel,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnMenuColumnsItem,
    GridColumnMenuFilterItem,
    GridColumnMenuGroupingItem,
    GridColumnMenuPinningItem,
    GridColumnMenuProps,
    GridColumnMenuSortItem,
    GridPinnedColumnPosition,
    GridRenderCellParams,
    GridRowId,
    GridSortDirection,
    GridSortItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import {
    addressScheduleId,
    scheduleSBPId,
    scheduleSBPIdLifePage1,
    scheduleSBPIdLifePage2,
} from "src/constants/Schedules";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ProductService, ScheduleService } from "src/services";
import { scheduleTemplateQuery } from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId, replaceUrlParam } from "src/services/Utility";
import {
    ColumnTemplateDTO,
    DataGridRow,
    FixMeLater,
    Jurisdiction,
    RowDTO,
    ScheduleRows,
} from "src/types";
import Toolbar from "../Toolbar/Toolbar";
import "./DataTable.scss";
import {
    StyledCustomColumnHeader,
    StyledCustomColumnHeaderIcons,
    StyledDataGrid,
} from "./DataTable.styled";
import { EmptyState, Modal, Undraw } from "src/uikit";
import {
    convertSelectedCellsForExcel,
    convertSelectedCellsForExcelWithHeaders,
    selectAllRows,
    selectColumnCells,
} from "./CopyAndPaste.util";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import ImportPremiumData from "../ImportPremiumData/ImportPremiumData";

const DataTable = ({
    documentKey,
    isActive,
    company,
    product,
    folderNode,
    returnNode,
}) => {
    const dispatch: FixMeLater = useAppDispatch();
    const scheduleService = ScheduleService.getInstance();
    const productService = ProductService.getInstance();
    const apiRef = useGridApiRef();

    const municipalState: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );
    const municipalQuarter: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );
    const toogleFetch: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.toogleFetch,
    );

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const { getAccessTokenSilently } = useAuth0();
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<DataGridRow[]>([]);
    const isSBPSchedule = () =>
        returnNode.id === scheduleSBPIdLifePage1 ||
        returnNode.id === scheduleSBPIdLifePage2 ||
        returnNode.id === scheduleSBPId;

    const isTwoPageSBPSchedule = () =>
        returnNode.id === scheduleSBPIdLifePage1 ||
        returnNode.id === scheduleSBPIdLifePage2;

    const [columnTemplateDTOs, setColumnTemplateDTOs] = useState<
        ColumnTemplateDTO[]
    >([]);
    const [rowDTOs, setRowDTOs] = useState<RowDTO[]>([]);
    const [sortModel, setSortModel] = useState<GridSortItem[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<FixMeLater>(null);
    const [columnMap, setColumnMap] = useState<Map<string, number>>(new Map());
    const [rowMap, setRowMap] = useState<Map<number, number>>(new Map());
    const [updatedRows, setUpdatedRows] = useState<number[]>([]);

    const [selectedJurisdiction, setSelectedJurisdiction] = useState<number>(0);
    const [jurisdictions, setJurisdictions] = useState<
        Map<number, Jurisdiction>
    >(new Map());
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [pendingChanges, setPendingChanges] = useState<boolean>(false);
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);

    const [rowSelected, setRowSelected] = useState<GridRowId[]>([]);
    const [cellSelected, setCellSelected] = useState<GridCellSelectionModel>(
        {},
    );

    const [isPasteAvailable, setIsPasteAvailable] = useState(true);

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    //to manage context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number | null;
        mouseY: number | null;
    }>({ mouseX: null, mouseY: null });

    const isMenuOpen =
        contextMenu.mouseY !== null && contextMenu.mouseX !== null;

    const showMenu =
        rowSelected.length > 0 || Object.keys(cellSelected).length > 0;

    const handleCloseMenu = () =>
        setContextMenu({ mouseX: null, mouseY: null });

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const convertSelectedRowsForExcel = (selectedRows: GridRowId[]): string => {
        // selectedRows.sort((a, b) => Number(a) - Number(b));
        let text = "";
        selectedRows.forEach((rowIndex, index) => {
            const selectedRow = rowDTOs.find((x) => x.rowId == rowIndex)!!;
            const cells =
                selectedRow.cellGroups[
                    returnNode.id !== addressScheduleId
                        ? 0
                        : selectedRow.currentIndex
                ].cells;

            const concatenatedValues: string[] = [];
            cells.slice().forEach((item) => {
                const value = item.ov !== "" ? item.ov : item.v;
                concatenatedValues.push(value);
            });

            const rowText = concatenatedValues.join("\t");
            text += rowText;

            if (index !== selectedRows.length - 1) {
                text += "\n";
            }
        });
        return text;
    };

    const convertSelectedRowsForExcelWithHeaders = (
        selectedRows: GridRowId[],
        columns,
    ): string => {
        let text = "";
        const columnNames = columns
            .map((column) => column.headerName)
            .join("\t");
        text += columnNames + "\n";
        text += convertSelectedRowsForExcel(selectedRows);
        return text;
    };

    const handleCopy = () => {
        let clipboardText = "";
        if (rowSelected.length > 0) {
            clipboardText = convertSelectedRowsForExcel(rowSelected);
        } else {
            clipboardText = convertSelectedCellsForExcel(cellSelected, apiRef);
        }
        navigator.clipboard.writeText(clipboardText).then(() => {
            handleCloseMenu();
        });
    };

    const handleCopyWithHeaders = () => {
        let clipboardText = "";
        if (rowSelected.length > 0) {
            clipboardText = convertSelectedRowsForExcelWithHeaders(
                rowSelected,
                columns,
            );
        } else {
            clipboardText = convertSelectedCellsForExcelWithHeaders(
                cellSelected,
                columns,
                apiRef,
            );
        }
        navigator.clipboard.writeText(clipboardText).then(() => {
            handleCloseMenu();
        });
    };

    const handleCellClick = useCallback(
        (params: GridCellParams) => {
            apiRef.current.setRowSelectionModel([]);
            if (params.field === columns[0].headerName) {
                apiRef.current.setRowSelectionModel([params.id]);
            }
        },
        [apiRef, rows, columns],
    );

    const handlePaste = async () => {
        let clipboardText = "";
        try {
            const clipboardText = await navigator.clipboard.readText();
            setIsPasteAvailable(!!clipboardText);
        } catch (error) {
            setIsPasteAvailable(false);
            console.error(error);
            const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

            // Customize the error message based on the OS
            const errorMessage = `The paste function is not available in the browser. Use ${
                isMac ? "Command+V" : "Control+V"
            } instead`;
            handleSnackbar(errorMessage, "error");
            return;
        } finally {
            handleCloseMenu();
        }

        try {
            clipboardText = await navigator.clipboard.readText();
            const rowsToPaste = clipboardText
                .split("\n")
                .map((row) => row.split("\t"));

            const updatedRows: DataGridRow[] = [...rows];
            const previousRows: DataGridRow[] = [...rows];

            let startRowIdx = 0;
            let startColIdx = 0;

            if (Object.keys(cellSelected).length > 0) {
                const selectedRowId = Object.keys(cellSelected)[0];
                const selectedColumnId = Object.keys(
                    cellSelected[selectedRowId],
                )[0];
                startRowIdx =
                    rows.findIndex((row) => String(row.id) === selectedRowId) ??
                    0;

                startColIdx =
                    columns.findIndex(
                        (col) => col.headerName === selectedColumnId,
                    ) ?? 0;
            }

            rowsToPaste.forEach((row, rowIdx) => {
                const targetRowIdx = startRowIdx + rowIdx;
                if (targetRowIdx < updatedRows.length) {
                    const updatedRow = { ...updatedRows[targetRowIdx] };
                    row.forEach((cellValue, colIdx) => {
                        const targetColIdx = startColIdx + colIdx;
                        if (
                            targetColIdx >= 0 &&
                            targetColIdx < columns.length
                        ) {
                            const targetColField = columns[targetColIdx].field;
                            if (targetColField) {
                                updatedRow[targetColField] = cellValue;
                            }
                        }
                    });

                    updatedRows[targetRowIdx] = updatedRow;
                    processRowUpdate(updatedRow, previousRows[targetRowIdx]);
                }
            });
        } catch (error) {
            console.error(error);
            handleSnackbar(`There has been an error . ${error}`, "error");
        } finally {
            handleCloseMenu();
        }
    };

    const onSaveClick = async () => {
        setIsLoading(true);
        await updateSchedule();
        updatedRows.length = 0;
        setUpdatedRows([]);
        setIsLoading(false);
        setPendingChanges(false);
        dispatch(GlobalStateActions.removePendingChange(documentKey));
    };

    // Toolbar icon data here
    const iconData = [
        {
            position: 1,
            icon: SaveIcon,
            title: "Save",
            onClick: onSaveClick,
            isEnabled: true,
            isActive: pendingChanges,
            isValid: true,
        },
        {
            position: 2,
            icon: UploadIcon,
            title: "Import Premium Data",
            onClick: () => setImportModalOpen(true),
            isEnabled: true,
            isActive: returnNode?.displayName.includes("Input Sch Qtr"),
            isValid: true,
        },
    ];

    useEffect(() => {
        if (isActive) {
            replaceUrlParam("year", product?.taxYear);
            replaceUrlParam("companyId", company?.id);
            replaceUrlParam("folderNodeId", folderNode?.id);
            replaceUrlParam("returnNodeId", returnNode?.id);

            if (product?.productName === "Municipal") {
                replaceUrlParam("selectedState", municipalState);
                replaceUrlParam("selectedQuarter", municipalQuarter);
            }
        }
    }, [isActive]);

    const findUpdatedColumnNames = (
        newRow: DataGridRow,
        oldRow: DataGridRow,
    ): string[] => {
        const updatedKeys: string[] = [];
        for (const key in newRow) {
            if (newRow[key] !== oldRow[key]) {
                updatedKeys.push(key);
            }
        }
        return updatedKeys;
    };

    const handlePageChange = (event: React.SyntheticEvent, page: number) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved.",
            );
            if (!userConfirmed) {
                return;
            }
        }
        setSelectedPage(page);
    };

    const handleSelectJurisdicion = (e: SelectChangeEvent<number>) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved.",
            );
            if (!userConfirmed) {
                return;
            }
        }
        setSelectedJurisdiction(Number(e.target.value));
    };

    const handleSelectChange = (e: SelectChangeEvent, rowId: number) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            selectedRow.currentIndex = Number(e.target.value);
            setRowDTOs([...rowDTOs]);
            updatedRows.push(rowIndex);
            setUpdatedRows(updatedRows);
            setPendingChanges(true);
            dispatch(GlobalStateActions.setPendingChange(documentKey));
        }
    };

    const handleCheckChange = (rowId: number, columnName: string) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[
                        returnNode.id !== addressScheduleId
                            ? 0
                            : selectedRow.currentIndex
                    ].cells[selectedColumnIndex];
                const currentValue =
                    cell.ov !== undefined && cell.ov !== "" ? cell.ov : cell.v;
                cell.ov = (currentValue !== "true").toString();
                setRowDTOs([...rowDTOs]);
                updatedRows.push(rowIndex);
                setUpdatedRows(updatedRows);
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    };

    const processRowUpdate = (newRow: DataGridRow, oldRow: DataGridRow) => {
        const updatedColumnNames: string[] = findUpdatedColumnNames(
            newRow,
            oldRow,
        );
        if (updatedColumnNames.length > 0) {
            updatedColumnNames.forEach((updatedColumnName: string) => {
                const rowIndex = rowMap.get(newRow.id);
                const selectedColumnIndex = columnMap.get(updatedColumnName);
                if (selectedColumnIndex && rowIndex != undefined) {
                    const selectedRow = rowDTOs[rowIndex];
                    const cell =
                        selectedRow.cellGroups[
                            returnNode.id !== addressScheduleId
                                ? 0
                                : selectedRow.currentIndex
                        ].cells[selectedColumnIndex];

                    if (!cell.editable) {
                        return;
                    }

                    cell.ov =
                        cell.v !== newRow[updatedColumnName]
                            ? newRow[updatedColumnName]
                            : "";
                    setRowDTOs([...rowDTOs]);
                    updatedRows.push(rowIndex);
                    setUpdatedRows(updatedRows);
                    setPendingChanges(true);
                    dispatch(GlobalStateActions.setPendingChange(documentKey));
                }
            });
        }
        return {
            ...newRow,
        };
    };

    const updateSchedule = async () => {
        const rowsToBeUpdated: RowDTO[] = updatedRows.map((index) => {
            const row = rowDTOs[index];
            return {
                ...rowDTOs[index],
                currentIndex:
                    returnNode.id !== addressScheduleId ? 0 : row.currentIndex,
            };
        });
        const currentScheduleId =
            returnNode.id === scheduleSBPIdLifePage1 && selectedPage === 1
                ? scheduleSBPIdLifePage2
                : returnNode.id;
        const scheduleInput: ScheduleRows = {
            queryJurisdiction: isSBPSchedule()
                ? Number(selectedJurisdiction)
                : undefined,
            scheduleKey: {
                companyId: company.id,
                moduleId: Number(getModuleId(product, company, municipalState)),
                productId: product?.productId,
                scheduleId: Number(currentScheduleId),
                taxYear: product?.taxYear,
            },
            rowDTOs: rowsToBeUpdated,
        };
        const result = await scheduleService.rowsUpdate(scheduleInput);
        result.rowDTOs.forEach((row) => {
            const rowIndex = rowMap.get(row.rowId);
            if (rowIndex != undefined) {
                rowDTOs[rowIndex] = row;
            }
        });
        setRowDTOs([...rowDTOs]);
    };

    const isCellEditable = (params: GridCellParams): boolean => {
        const columnName = params.colDef.field;
        const editable = params.row[`${params.row.id}_${columnName}_editable`];
        switch (params.row[`${columnName}_cellType`]) {
            case "ADDRESS_SELECT":
            case "DROPDOWN":
            case "CHECK":
                return false;
            default:
                return Boolean(editable);
        }
    };

    const renderCell = (
        params: GridRenderCellParams,
        column: ColumnTemplateDTO,
    ) => {
        if (params.rowNode.type === "group") {
            return params.value;
        }

        const cellIdentifier = `${params.row.id}_${column.columnTemplate.name}`;
        const options = params.row[`${cellIdentifier}_options`];
        const currentIndex = params.row[`${cellIdentifier}_currentIndex`];
        const currentValue = params.row[`${column.columnTemplate.name}`];
        switch (params.row[`${cellIdentifier}_cellType`]) {
            case "ADDRESS_SELECT":
            case "DROPDOWN":
                return options?.length > 1 ? (
                    <Select
                        className="data-table-select"
                        key={`${cellIdentifier}_select`}
                        value={currentIndex}
                        onChange={(e) => handleSelectChange(e, params.row.id)}
                        autoWidth
                    >
                        {options.map((option) => (
                            <MenuItem
                                key={`${cellIdentifier}_${option.value}_select`}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    currentValue
                );
            case "CHECK":
                return (
                    <Checkbox
                        checked={currentValue === "true"}
                        onChange={(e) =>
                            handleCheckChange(
                                params.row.id,
                                column.columnTemplate.name,
                            )
                        }
                    />
                );
            case "TEXT":
                if (column.columnTemplate.name === "Contact Email") {
                    return (
                        <a href={`mailto:${currentValue}`}>{currentValue}</a>
                    );
                }
                if (column.columnTemplate.name === "Web Address") {
                    return (
                        <a href={currentValue} target="_blank" rel="noreferrer">
                            {currentValue}
                        </a>
                    );
                }
                return currentValue;
            default:
                return currentValue;
        }
    };

    const createDataGridColumns = (): GridColDef[] => {
        return columnTemplateDTOs.map((column: ColumnTemplateDTO, index) => {
            return {
                field: column.columnTemplate.name,
                headerName: column.columnTemplate.name,
                width: column.columnTemplate.width + 40,
                editable: true,
                aggregable: false,
                renderCell: (param) => renderCell(param, column),
                cellClassName: (params) => {
                    const cellIdentifier = `${params.row.id}_${column.columnTemplate.name}`;
                    const overrided = params.row[`${cellIdentifier}_overrided`];
                    return index === 0
                        ? "first-column-cell"
                        : overrided
                          ? "overrided-column-cell"
                          : "";
                },
                sortable: false,
                renderHeader(params) {
                    return customColumnHeader(params);
                },
            };
        });
    };

    const createDataGridRows = (): DataGridRow[] => {
        return rowDTOs.map((rowDTO) => {
            const row: DataGridRow = {
                id: rowDTO.rowId,
            };
            rowDTO.cellGroups[
                returnNode.id !== addressScheduleId ? 0 : rowDTO.currentIndex
            ].cells.forEach((cell, index) => {
                const columnTemplate = columnTemplateDTOs[index].columnTemplate;
                const cellIdentifier = `${row.id}_${columnTemplate.name}`;
                const overrided = cell.ov !== undefined && cell.ov !== "";
                const currentValue = overrided ? cell.ov : cell.v;

                switch (columnTemplate.cellType) {
                    case "ADDRESS_SELECT":
                    case "DROPDOWN":
                        row[`${cellIdentifier}_currentIndex`] =
                            rowDTO.currentIndex;
                        row[`${cellIdentifier}_options`] =
                            rowDTO.cellGroups.map((group, cellIndex) => {
                                return {
                                    value: cellIndex,
                                    label: group.cells[index].v,
                                };
                            });
                        row[`${cellIdentifier}_cellType`] =
                            columnTemplate.cellType;
                        break;
                    default:
                        row[`${cellIdentifier}_cellType`] = cell.cellType;
                        break;
                }
                row[columnTemplate.name] = currentValue;
                row[`${cellIdentifier}_editable`] = cell.editable;
                row[`${cellIdentifier}_overrided`] = overrided;
            });

            return row;
        });
    };

    const CustomColumnMenu = (props: GridColumnMenuProps) => {
        const itemProps = {
            colDef: props.colDef,
            onClick: props.hideMenu,
        };

        const pinToLeft = () => {
            apiRef.current.pinColumn(
                props.colDef.field,
                GridPinnedColumnPosition.LEFT,
            );
        };

        const unPin = () => {
            apiRef.current.unpinColumn(props.colDef.field);
        };

        const isPinned: GridPinnedColumnPosition | boolean =
            apiRef.current.isColumnPinned(props.colDef.field);

        return (
            <>
                <Stack px={0.5} py={0.5}>
                    <MenuItem
                        onClick={() => {
                            isPinned ? unPin() : pinToLeft();
                        }}
                    >
                        {isPinned ? (
                            <RemoveCircleOutlineIcon
                                sx={{
                                    marginRight: "12px",
                                    fill: "rgba(0, 0, 0, 0.54)",
                                }}
                            />
                        ) : (
                            <PushPinIcon
                                sx={{
                                    marginRight: "12px",
                                    fill: "rgba(0, 0, 0, 0.54)",
                                }}
                            />
                        )}{" "}
                        {isPinned ? "Unpin" : "Pin"}
                    </MenuItem>
                    <GridColumnMenuFilterItem {...itemProps} />
                    <GridColumnMenuGroupingItem {...itemProps} />
                </Stack>
                <Divider />
                <Stack px={0.5} py={0.5}>
                    <GridColumnMenuColumnsItem {...itemProps} />
                </Stack>
            </>
        );
    };

    const customToolbar = () => {
        return (
            <GridToolbarContainer className="grid-toolbar-container">
                <div className="first-grid-toolbar-row">
                    {isTwoPageSBPSchedule() ? (
                        <Tabs value={selectedPage} onChange={handlePageChange}>
                            <Tab label="Page 1" value={0} />
                            <Tab label="Page 2" value={1} />
                        </Tabs>
                    ) : (
                        <></>
                    )}
                    {isSBPSchedule() &&
                    jurisdictions.get(selectedJurisdiction) ? (
                        <Select
                            className="sbp-jurisdiction-select"
                            key={`sbp-jurisdiction-select`}
                            value={selectedJurisdiction}
                            onChange={(e) => handleSelectJurisdicion(e)}
                            autoWidth={false}
                        >
                            {Array.from(jurisdictions.entries()).map(
                                ([key, jurisdiction]) => (
                                    <MenuItem
                                        key={`jurisdiction${jurisdiction.id}`}
                                        value={key}
                                    >
                                        {jurisdiction.longName}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    ) : (
                        <></>
                    )}
                </div>
            </GridToolbarContainer>
        );
    };

    const customNoData = () => {
        return (
            <div className="data-table-no-data">
                <EmptyState
                    graphic={<Undraw fileName="NoData.svg" height={100} />}
                    title="No Data Found"
                    text="The table currently has no data to display. Please check back later for any new updates or additions."
                    actions={[]}
                />
            </div>
        );
    };

    const onSortIconClicked = (params: GridColumnHeaderParams) => {
        const currentSortValue: GridSortDirection = sortModel.find(
            (sort) => sort.field === params.colDef.field,
        )?.sort;
        setSortModel(
            currentSortValue === "desc"
                ? []
                : [
                      {
                          field: params.colDef.field,
                          sort: currentSortValue === "asc" ? "desc" : "asc",
                      },
                  ],
        );
    };

    const getSortIcon = (field: string) => {
        const currentSortValue: GridSortDirection = sortModel.find(
            (sort) => sort.field === field,
        )?.sort;
        return currentSortValue === "asc" ? (
            <ArrowUpwardIcon sx={{ fontSize: "16px" }} />
        ) : currentSortValue === "desc" ? (
            <ArrowDownwardIcon sx={{ fontSize: "16px" }} />
        ) : (
            <ArrowUpwardIcon sx={{ fontSize: "16px", fill: "#c8c8c8" }} />
        );
    };

    const customColumnHeader = (
        headerParams: GridColumnHeaderParams<any, any, any>,
        isGroup: boolean = false,
    ) => {
        return (
            <>
                <StyledCustomColumnHeader
                    onClick={() =>
                        selectColumnCells(apiRef, headerParams.colDef.field)
                    }
                >
                    {isGroup ? (
                        <span>Group By ({headerParams.colDef.headerName})</span>
                    ) : (
                        <span>{headerParams.colDef.headerName}</span>
                    )}
                </StyledCustomColumnHeader>
                <StyledCustomColumnHeaderIcons>
                    <IconButton
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            onSortIconClicked(headerParams);
                        }}
                    >
                        {getSortIcon(headerParams.colDef.field)}
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() =>
                            apiRef.current.showColumnMenu(headerParams.field)
                        }
                    >
                        <MoreVertIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                </StyledCustomColumnHeaderIcons>
            </>
        );
    };

    const MenuComponent = () => {
        return (
            <>
                {showMenu && (
                    <Menu
                        open={isMenuOpen}
                        onClose={handleCloseMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu.mouseY !== null &&
                            contextMenu.mouseX !== null
                                ? {
                                      top: contextMenu.mouseY,
                                      left: contextMenu.mouseX,
                                  }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleCopy}>Copy</MenuItem>
                        <MenuItem onClick={handleCopyWithHeaders}>
                            Copy with Headers
                        </MenuItem>
                        <MenuItem
                            onClick={handlePaste}
                            disabled={!isPasteAvailable}
                        >
                            Paste
                        </MenuItem>
                    </Menu>
                )}
            </>
        );
    };

    const SelectAllRowsButton = () => {
        return (
            <div
                className="select-all-rows-button-container"
                style={{ top: isSBPSchedule() ? "53px" : "6px" }}
                onClick={() => selectAllRows(apiRef)}
            >
                <div className="select-all-rows-button" />
            </div>
        );
    };

    useEffect(() => {
        const fillDataGrid = async () => {
            try {
                setIsLoading(true);
                const updatedColumns = createDataGridColumns();
                const updatedRows = createDataGridRows();
                setColumns(updatedColumns);
                setRows(updatedRows);
                setError(null);
            } catch (error: FixMeLater) {
                console.error("Error fetching schedule:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (columnTemplateDTOs?.length && rowDTOs?.length) {
            fillDataGrid();
        }
    }, [columnTemplateDTOs, rowDTOs, sortModel]);

    useEffect(() => {
        const loadSBPData = async () => {
            if (isSBPSchedule()) {
                let jurisdictionMap = jurisdictions;
                if (!jurisdictions.size) {
                    const response: Jurisdiction[] =
                        await productService.getProductJurisdictions(
                            product.productId,
                        );
                    response.push({
                        id: 99,
                        longName: "All States",
                        abbrev: "All",
                    });
                    jurisdictionMap = response.reduce(
                        (map, jurisdiction: Jurisdiction) => {
                            map.set(jurisdiction.id, jurisdiction);
                            return map;
                        },
                        new Map<number, Jurisdiction>(),
                    );
                    setJurisdictions(jurisdictionMap);
                }
                if (!jurisdictionMap.get(selectedJurisdiction)) {
                    setSelectedJurisdiction(
                        jurisdictionMap.entries().next().value[0],
                    );
                    setSelectedPage(0);
                }
            } else {
                setSelectedJurisdiction(0);
            }
        };

        const fetchData = async () => {
            try {
                const currentScheduleId =
                    returnNode.id === scheduleSBPIdLifePage1 &&
                    selectedPage === 1
                        ? scheduleSBPIdLifePage2
                        : returnNode.id;
                setIsLoading(true);
                const scheduleInput = {
                    companyId: company.id,
                    folderId: folderNode.id,
                    moduleId: getModuleId(product, company, municipalState),
                    pageNumber: "0",
                    productId: product?.productId.toString(),
                    returnId: currentScheduleId,
                    taxYearId: product?.taxYear.toString(),
                };
                const accessToken = await getAccessTokenSilently();
                const {
                    scheduleTemplateDTO: { columnTemplateDTOs, rowDTOs },
                }: FixMeLater = await GQLService.fetchGraphQLData(
                    scheduleTemplateQuery,
                    { scheduleInput, queryJurisdiction: selectedJurisdiction },
                    accessToken,
                );
                const updatedColumnMap = columnTemplateDTOs.reduce(
                    (map, column, index) => {
                        map.set(column.columnTemplate.name, index);
                        return map;
                    },
                    new Map<string, number>(),
                );
                const updatedRowMap = rowDTOs.reduce(
                    (map, row: RowDTO, index) => {
                        map.set(row.rowId, index);
                        return map;
                    },
                    new Map<string, number>(),
                );
                setRowDTOs([...rowDTOs]);
                setColumnTemplateDTOs(columnTemplateDTOs);
                setRowMap(updatedRowMap);
                setColumnMap(updatedColumnMap);
            } catch (error: FixMeLater) {
                console.error("Error fetching return template:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        updatedRows.length = 0;
        setUpdatedRows(updatedRows);
        setPendingChanges(false);
        dispatch(GlobalStateActions.removePendingChange(documentKey));
        loadSBPData();
        if (!isSBPSchedule()) {
            if (selectedJurisdiction === 0) {
                fetchData();
            }
        } else {
            if (jurisdictions.get(selectedJurisdiction)) {
                fetchData();
            }
        }
    }, [
        folderNode,
        returnNode,
        selectedJurisdiction,
        selectedPage,
        toogleFetch,
    ]);

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching the pdf: ${error.toString()}`}
            />
        );
    if (!error && isLoading)
        return (
            <div>
                <Loader />
                <CustomSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </div>
        );

    return (
        <div className="data-table-container" onContextMenu={handleContextMenu}>
            <div className="grid-container">
                <StyledDataGrid
                    hideFooter
                    density="compact"
                    processRowUpdate={processRowUpdate}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: customToolbar,
                        noRowsOverlay: customNoData,
                        columnMenuIcon: () => <></>,
                        columnMenuFilterIcon: () => <FilterAltOutlinedIcon />,
                        columnMenu: (props) => CustomColumnMenu(props),
                    }}
                    sortModel={sortModel}
                    isCellEditable={isCellEditable}
                    onCellClick={handleCellClick}
                    onCellSelectionModelChange={(newCellSelectionModel) => {
                        setCellSelected(newCellSelectionModel);
                    }}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelected(newRowSelectionModel);
                    }}
                    ignoreValueFormatterDuringExport
                    apiRef={apiRef}
                    groupingColDef={{
                        renderHeader: (params) =>
                            customColumnHeader(params, true),
                    }}
                    cellSelection
                    disableMultipleRowSelection
                    rowSelection={false}
                    onBeforeClipboardPasteStart={async () => {
                        handlePaste();
                        throw new Error("Paste handled"); // This will prevent the default paste behavior from Mui
                    }}
                />
                <SelectAllRowsButton />
            </div>
            <MenuComponent />
            <div className="grid-right-toolbar-container">
                <Toolbar items={iconData} />
            </div>
            <Modal
                open={importModalOpen}
                onClose={() => setImportModalOpen(false)}
                title={`Import Premium Data for ${returnNode?.displayName}`}
            >
                <ImportPremiumData
                    scheduleInput={{
                        companyId: company?.id.toString(),
                        folderId: folderNode?.id,
                        moduleId: getModuleId(product, company, municipalState),
                        pageNumber: "0",
                        productId: product?.productId.toString(),
                        returnId: returnNode?.id,
                        taxYearId: product?.taxYear.toString(),
                    }}
                    onClose={() => setImportModalOpen(false)}
                    handleSnackbar={handleSnackbar}
                />
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default DataTable;
