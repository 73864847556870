import { CircularProgress } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import "./Loader.scss";

export type LoaderProps = {
    color?: string;
};

const Loader: FC<PropsWithChildren<LoaderProps>> = ({ color }) => {
    return (
        <div className="loader-container">
            <CircularProgress sx={{ color: color }} />
        </div>
    );
};

export default Loader;
