import { FixMeLater, QueryParams } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class ReturnStatusService {
    private static _instance: ReturnStatusService;
    private readonly BASE_PATH: string = "/api/return-document/return-status";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    private getParamsString(params: QueryParams): string {
        return new URLSearchParams(params).toString();
    }


    getReturnStatuses(params: QueryParams): Promise<FixMeLater> {
        return this.api.get<FixMeLater>(
            this.getPath(`?${this.getParamsString(params)}`)
        );
    }

    setReturnStatuses(params: QueryParams): Promise<FixMeLater> {
        return this.api.post<FixMeLater>(
            this.getPath(`?${this.getParamsString(params)}`)
        );
    }

    public static getInstance(): ReturnStatusService {
        if (!ReturnStatusService._instance) {
            ReturnStatusService._instance = new ReturnStatusService();
        }
        return ReturnStatusService._instance;
    }
}
