import { BaseAPIService } from "./BaseApiService";

export class SubAccountOptionsService {
    private static _instance: SubAccountOptionsService;
    private readonly BASE_PATH: string = "/api/subaccount";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    saveExportPaymentRequestOptions(companyId: number, payload: any): Promise<any> {
        return this.api.post(this.getPath(`/${companyId}/options`), payload);
    }

    getSubAccountOptions(companyId: number): Promise<any> {
        return this.api.get(this.getPath(`/${companyId}/options`));
    }

    public static getInstance(): SubAccountOptionsService {
        if (!SubAccountOptionsService._instance) {
            SubAccountOptionsService._instance = new SubAccountOptionsService();
        }
        return SubAccountOptionsService._instance;
    }
}