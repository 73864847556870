import styled from "styled-components";
import EventIcon from "@mui/icons-material/Event";

export const StyledEventIcon = styled(EventIcon)`
    && {
        height: 25px;
        width: 25px;
        color: white;
    }
`;
