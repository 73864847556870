import React, { FC } from "react";
import MenuItem from "../MenuItem/MenuItem";
import "./Menu.scss";

const Menu: FC = () => {
    return (
        <div className="menu-container">
            <MenuItem>Agent Home</MenuItem>
            <MenuItem>Single Address Lookup</MenuItem>
            <MenuItem>Usage Report</MenuItem>
        </div>
    );
};

export default Menu;
