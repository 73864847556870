import { Button, Table } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledHeader,
    StyledNoDataFound,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import "./UserRoles.scss";
import { FrameCard } from "src/uikit";

const UserRoles: FC = () => {
    const accountService = AccountService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup
    );
    const selectedAccountGroups = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroups
    );
    const selectedAccountRoles = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountRoles
    );

    const dispatch = useAppDispatch();

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedRoleId, setSelectedRoleId] = useState<FixMeLater | null>(
        null
    );

    const handleOpenDialog = (roleId: FixMeLater) => {
        setSelectedRoleId(roleId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedRoleId(null);
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        handleCloseDialog();
        await handleUserRoleClick(selectedRoleId);
    };

    useEffect(() => {
        const fetchSelectedAccountRoles = async () => {
            setIsLoading(true);
            try {
                const selectedAccountRoles =
                    await accountService.getAccountRoles(selectedAccount.id);
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountRoles(selectedAccountRoles)
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountRoles();
    }, [selectedAccount]);

    const handleUserRoleClick = async (newRoleId: FixMeLater) => {
        try {
            setIsLoading(true);

            const updatedSelectedAccountGroup: FixMeLater = {
                // update type to AccessControlGroup
                ...selectedAccountGroup,
                roleId: newRoleId,
            };

            const updatedSelectedAccountGroupResponse =
                await accountService.updateAccountGroupContent(
                    updatedSelectedAccountGroup,
                    "role"
                );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroup(updatedSelectedAccountGroupResponse)
            );

            const updatedSelectedAccountGroups = selectedAccountGroups.map(
                (group) =>
                    group.id === updatedSelectedAccountGroupResponse.id
                        ? { ...updatedSelectedAccountGroupResponse }
                        : group
            );

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroups(updatedSelectedAccountGroups)
            );

            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const noDataFoundMessage = selectedAccountGroup
        ? "This group is not associated with any user roles."
        : "Please select a group.";

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching user roles: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="group-user-roles-container">
            <FrameCard title="USER ROLES">
                {!selectedAccountGroup?.roleId ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <Table>
                        {selectedAccountRoles?.map((role) => (
                            <StyledTableRow
                                key={role.id}
                                $isSelected={
                                    selectedAccountGroup?.roleId === role?.id
                                }
                                onClick={() => handleOpenDialog(role?.id)}
                            >
                                <StyledTableCell>{role.name}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </Table>
                )}
            </FrameCard>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to perform this action?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledFlexContainer justify={"center"}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmDialog}
                        >
                            Confirm
                        </Button>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </StyledFlexContainer>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="User role updated successfully!"
                severity="success"
            />
        </div>
    );
};

export default UserRoles;
