
import { EfileData, EfileResponseData, ReturnDocumentId } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class EFileService {
    private static _instance: EFileService;
    private readonly BASE_PATH: string = "/api/efile";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public static getInstance(): EFileService {
        if (!EFileService._instance) {
            EFileService._instance = new EFileService();
        }
        return EFileService._instance;
    }

    async fetchEfileData(returnDocId: ReturnDocumentId): Promise<EfileData> {
        const queryString = Object.entries(returnDocId)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
        return this.api.get<EfileData>(this.getPath(`?${queryString}`));
    }

    async sendEfileData(returnDocId: ReturnDocumentId): Promise<EfileResponseData> {
        return this.api.post<EfileResponseData>(this.getPath(""), returnDocId);
    }
}
