import React, { FC } from "react";
import { SearchOff } from "@mui/icons-material";
import { Typography } from "@mui/material";
import "./NoDataOverlay.scss";

const NoDataOverlay: FC = () => {
    return (
        <div className="no-data-overlay-container">
            <SearchOff sx={{ fontSize: 80, color: "#00000061" }}></SearchOff>
            <Typography sx={{ fontSize: 24 }}>No Data</Typography>
        </div>
    );
};

export default NoDataOverlay;
