export const delimiterNameToActualMap = new Map<string, string>([
    ["Colon", ":"],
    ["Comma", ","],
    ["Semi Colon", ";"],
    ["Tab", "\t"],
    ["Vertical Bar (Pipe)", "|"],
]);

export const actualToDelimeterMap = new Map<string, string>([
    [":", "Colon"],
    [",", "Comma"],
    [";", "Semi Colon"],
    ["\t", "Tab"],
    ["|", "Vertical Bar (Pipe)"],
]);

export const checkBoxes = new Map([
    ["Export text in uppercase characters", "exportInUpperCase"],
    ["Mark exported payment request as paid", "markAsPaid"],
    [
        "Fill date paid field on exported records with today's date",
        "fillDataPaidWithToday",
    ],
    ["Use leading zero ( Fixed width format only )", "useLeadingZero"],
]);