import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "src/products/AccountAdmin/AccountAdmin.styled";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import "./DeleteProductModal.scss";

interface DeleteProductModalProps {
    open: boolean;
    onClose: () => void;
    productToDelete: FixMeLater;
}

const DeleteProductModal: FC<DeleteProductModalProps> = ({
    open,
    onClose,
    productToDelete,
}) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector((state) => state.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state[product!.productName!]?.value?.selectedAccount
    );
    const selectedAccountProducts = useAppSelector(
        (state) => state[product.productName]?.value?.selectedAccountProducts
    );

    const handleDeleteProduct = async (e: React.FormEvent) => {
        e.preventDefault();

        const deleteProduct: Partial<FixMeLater> = {
            productName: productToDelete?.productName,
            taxYear: productToDelete?.taxYear,
            accountId: selectedAccount?.id,
            productId: ProductNameToIdMap.get(productToDelete?.productName),
        };

        try {
            setIsLoading(true);

            await accountService.deleteAccountProduct(deleteProduct);

            const updatedSelectedAccountProducts: Array<FixMeLater> = [];
            selectedAccountProducts.forEach((product) => {
                if (
                    product.taxYear !== deleteProduct.taxYear ||
                    product.productName !== deleteProduct.productName
                ) {
                    updatedSelectedAccountProducts.push(product);
                }
            });

            if (product && product.productName) {
                dispatch(
                    GlobalStateActions[
                        product.productName
                    ].setSelectedAccountProducts(updatedSelectedAccountProducts)
                );
            } else {
                console.error("Product or productName is undefined");
            }

            onClose();
            setSnackbarOpen(true);
        } catch (error: FixMeLater) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5" color={"error"}>
                                Confirm Delete Account Product ?
                            </Typography>
                            <StyledForm onSubmit={handleDeleteProduct}>
                                <TextField
                                    variant="filled"
                                    required
                                    fullWidth
                                    id="productName"
                                    label="Product Name"
                                    name="productName"
                                    value={productToDelete?.productName}
                                    disabled
                                />
                                <TextField
                                    variant="filled"
                                    required
                                    fullWidth
                                    id="taxYear"
                                    label="Tax Year"
                                    name="taxYear"
                                    type="number"
                                    value={productToDelete?.taxYear}
                                    disabled
                                />
                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Product deleted successfully!"
                severity="success"
            />
        </>
    );
};

export default DeleteProductModal;
